import { Tag } from 'antd';

const CriterionTag = ({ data, keyName }) => {
    let tag_name = keyName.replace(/_/g, " ");
    tag_name = tag_name.replace(/puv/g, "");

    // Criterios negativos
    if (keyName === "delego" || keyName === "problemas_con_usuario") {

        if (keyName === "delego") tag_name = "No delego";
        if (keyName === "problemas_con_usuario") tag_name = "experiencia de usuario";

        return <Tag color={data[keyName] ? 'red' : 'green'}>{tag_name}</Tag>
    }
    // Criterios positivos
    else {
        return <Tag color={data[keyName] ? 'green' : 'red'}>{tag_name}</Tag>
    }
}

const CriterionTags = ({ data }) => {
    return (
        <>
            <CriterionTag data={data} keyName={'puv_auto_aceptacion'} />
            <CriterionTag data={data} keyName={'radio'} />
            <CriterionTag data={data} keyName={'productos'} />
            <CriterionTag data={data} keyName={'horarios'} />
            <CriterionTag data={data} keyName={'tiene_capacidad'} />
            <CriterionTag data={data} keyName={'delego'} />
            <CriterionTag data={data} keyName={'problemas_con_usuario'} />
        </>
    )
}

export { CriterionTag, CriterionTags }