import React from "react";
import { Modal, Form, Button, Select, Input, Icon } from 'antd';
import { Row, Col } from "shards-react";
import Mispichoshelper from "../../../utils/Mispichoshelper";
import Loadin from "../../../components/componentsUI/Loading";
import { faKaaba } from "@fortawesome/free-solid-svg-icons";
const confirm = Modal.confirm;
const Option = Select.Option;

class Split extends React.Component {
    constructor(props) {
        super(props);
        this.state = { incluyeOtrosHorarios: 0, resultados: [], loading: true,loadingButton: true ,cantRows: {}, allowPerCant: false };
    }

    componentDidMount() {
        Mispichoshelper.getCompraSplitProductos({ compra_id: this.props.compra.compra_id }).then((response) => {
            if (response && response.payload !== undefined) {
                this.setState({ resultados: response.payload, loading: false,loadingButton:false });
            } else {
                console.log('sin datos de getCompraSplitProducto');
            }
        });
        // To disabled submit button at the beginning.
        this.props.form.validateFields();
    }


    informaResponse = (response) => {
        if (response && response.payload !== undefined) {
            this.props.refrescarCompra(this.props.compra.compra_id);
            if (response.payload.status === "success") {
              this.setState({ loading: false,loadingButton: false });
                this.success(response);
            } else {
                this.error(response);
            }

        } else {
            Modal.error({
                title: "No se pudo completar el split"
            });
            this.setState({ loadingButton: false });
        }
    }

    setIncluyeHorariosDistintos = (e) => { this.setState({ incluyeOtrosHorarios: e }) }



    success(response) {
        Modal.success({
            title: response.payload.title,
            content: response.payload.message,
            onOk: () => this.props.hideSplitCompra('splitCompra', false),
            okText: 'Entendido'
        });
    }

    error(response) {
        Modal.error({
            title: response.payload.title,
            content: response.payload.message,
        });
    }

    handleSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let params = { compra_id: this.props.compra.compra_id, 'producto_variaciones': values.producto_variaciones };
                if (values.producto_variaciones_cant != undefined) {
                    params.producto_variaciones_cant = values.producto_variaciones_cant;
                }
                Mispichoshelper.postCompraSplit(params).then(this.informaResponse);
            }
        });
    };

    handleOk = async () => {
        this.setState({ loadingButton: true });
        let validate = await this.handleValidate()
        if (validate.status) {
            confirm({
                title: 'Subdividir compra',
                content: 'Vas a generar compras hermanas a esta, una vez hecho no podras revertir este cambio. Por tanto asegurate de haber agotado todas las demas posibilidades.',
                onOk: () => this.handleSubmit(),
                onCancel: () => this.setState({ loadingButton: false }),
            });
        } else {
            this.setState({ loadingButton: false });
            Modal.error({
                title: 'Error!',
                content: validate.message,
            });
        }

    }

    handleValidate = async () => {
        let values = await this.props.form.validateFields();
        var id = null;
        var pet = [];
        let keys = Object.keys(values.producto_variaciones)
        var validate = true;
        var message = '';
        keys.map(item => {
            let idPet = parseInt(item.split('-key-')[0])
            if (id != idPet) {
                id = idPet
                if (id) {
                    pet = [];
                }
            }
            if (this.state.allowPerCant) {
                if (values.producto_variaciones[item] !== '') {
                    if (values.producto_variaciones_cant[item] === '') {
                        validate = false;
                        message = 'Si selecciono un petshop debe ingresar una cantidad de productos mayor a cero'
                    }
                    if (!pet.includes(values.producto_variaciones[item])) {
                        pet.push(values.producto_variaciones[item])
                    } else {
                        validate = false;
                        message = 'Un producto no puede ser asignado mas de una vez al mismo petshop.'
                    }
                } else if (values.producto_variaciones_cant[item] !== '' && (parseInt(values.producto_variaciones_cant[item]) > 0)) {
                    validate = false;
                    message = 'Si posee un item con cantidad mayor a cero debe seleccionar un petshop.'
                }
                if ((values.producto_variaciones_cant[item] === '' || values.producto_variaciones_cant[item] === '0') && values.producto_variaciones[item] === '') {
                    delete values.producto_variaciones_cant[item];
                    delete values.producto_variaciones[item];
                }
            } else {
                if (values.producto_variaciones[item] === '') {
                    validate = false;
                    message = 'Debe seleccionar un petshop.'
                }
            }
        })
        return { status: validate, message: message, form: values }
    }

    newRowCant = (prv_id, cant) => {
        let nodeId = `prv-${prv_id}`;
        let aux = this.state.cantRows;
        if (aux[nodeId] == undefined) {
            aux[nodeId] = 2;
        } else {
            aux[nodeId] = aux[nodeId] + 1;
        }
        this.setState({ cantRows: aux });
    }

    remove = k => {
        const { form } = this.props;
        console.log("form: ", form)

    };

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Modal
                title="Compra split"
                style={{ minWidth: '55vw' }}
                visible={this.props.showSplitCompra}

                onCancel={() => this.props.hideSplitCompra('splitCompra', false)}
                footer={[
                    <Button className="btn-danger" key="back" onClick={() => this.props.hideSplitCompra('splitCompra', false)}>
                        Volver
                    </Button>,
                    <Button className="btn-outline-primary" loading={this.state.loadingButton} onClick={() => this.handleOk()}>
                        Continuar
                    </Button>,
                ]}
            >
                <input type='checkbox' checked={this.state.allowPerCant ? true : false} onClick={() => this.setState({ allowPerCant: !this.state.allowPerCant })} />Split por cantidad
                {this.state.loading ?
                    <Loadin />
                    :
                    <Form onSubmit={this.handleSubmit} className="login-form">
                        {this.state.resultados.length > 0 && this.state.resultados.map((cada_producto_variacion, i_resultados) =>
                            <>
                                <Row key={i_resultados}  >
                                    <Col xs="3" className="splitModalItem">
                                        <Row>
                                            <Col xs="5">
                                                <img src={cada_producto_variacion.prv_foto_link} alt='imgen producto' />
                                            </Col>
                                            <Col xs="7">
                                                <span style={{ fontSize: 12 }}>
                                                    {cada_producto_variacion.prv_nombre}
                                                    <h5>Cantidad:{cada_producto_variacion.cant}</h5>
                                                </span>
                                            </Col>
                                        </Row>
                                    </Col>
                                    {this.state.allowPerCant
                                        ?
                                        <Col xs="9" >
                                            <div id={`prv-${cada_producto_variacion.prv_id}`}>
                                                {[...Array(cada_producto_variacion.cant).keys()].map((each, k) => <RowItem key={k} k={k} getFieldDecorator={getFieldDecorator} cada_producto_variacion={cada_producto_variacion} remove={this.remove} />)}
                                            </div>
                                        </Col>
                                        :
                                        <Col xs="9" >
                                            <div id={`prv-${cada_producto_variacion.prv_id}`}>
                                                <RowItem getFieldDecorator={getFieldDecorator} k={0} hideCant={true} cada_producto_variacion={cada_producto_variacion} remove={this.remove} />
                                            </div>
                                        </Col>
                                    }

                                </Row>
                                <div style={{ height: 1, width: '100%', marginTop: 10, marginBottom: 10, border: '1px solid lightgray' }}></div>
                            </>
                        )}
                        {/** <FormCheckbox value='1' onClick={this.setIncluyeHorariosDistintos}>Permitir horarios distintos</FormCheckbox>*/}
                    </Form>
                }
            </Modal>
        )
    }
}

function RowItem({ getFieldDecorator, cada_producto_variacion, remove, k, hideCant }) {

    return (
        <Row>
            <Col xs="8">
                <Form.Item>
                    {getFieldDecorator('producto_variaciones[' + cada_producto_variacion.prv_id + '-key-' + k + ']', {
                        rules: [{ required: false, message: 'Por favor elegi el petshop destino!' }],
                        initialValue: "",
                    })(
                        <Select
                            style={{ width: '100%' }}
                            showSearch
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            <Option value=""> Seleccionar </Option>
                            {cada_producto_variacion.petshops.map((cada_petshop, i) =>
                                <Option key={i} value={cada_petshop.id}  >
                                    {cada_petshop.pro_nombre + ' ' + (cada_petshop.entra_radio ? '' : '(R:no)') + ' ' + (cada_petshop.tiene_producto ? '' : '(P:no)') + ' ' + (cada_petshop.tiene_capacidad ? '' : '(C:no)') + (cada_petshop.tiene_horario ? '' : '(no cubre horario)')}
                                </Option>
                            )}
                        </Select>
                    )}
                </Form.Item>
            </Col>
            {!hideCant ?
                <Col xs="3">
                    <Form.Item>
                        {getFieldDecorator('producto_variaciones_cant[' + cada_producto_variacion.prv_id + '-key-' + k + ']', {
                            rules: [{ required: false, message: 'Por favor elegi el petshop destino!' }],
                            initialValue: "1",
                        })(
                            <Input type={'number'} />
                        )}
                    </Form.Item>
                </Col>
                : null}
        </Row>
    )
}

const OrderSplit = Form.create({ name: 'form_split' })(Split);

export default OrderSplit;

