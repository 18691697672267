import React, { useEffect, useState } from "react";
import { Row, Col, Select, Button, Tabs } from "antd";
import { FaSearch } from 'react-icons/fa';
import { Input } from 'antd';
import Mispichoshelper from "../../../utils/Mispichoshelper";
import Loading from "../../../components/componentsUI/Loading";

const { TabPane } = Tabs;

const Search = Input.Search;
const Option = Select.Option;

export default function SearchProducts({ sumarProducto, compra = false, selectCloseModal = false, toggleModal = false, excluir_no_se_vende = null, excluirProductoRepetido = true, get_combinados = false, showTabCombined = false }) {
    const [products, setProd] = useState([])
    const [productCombined, setProductCombined] = useState([])
    const [search, setSearch] = useState("")
    const [loading, setLoading] = useState(false)
    const [marcas, setMarcas] = useState([])
    const [categ, setCateg] = useState([])
    const [animals, setAnimals] = useState([])
    const [noMoreHits, setNoMoreHits] = useState(true)
    const [animalSelected, setSelectedAnimal] = useState('')
    const [categSelected, setSelectedCateg] = useState('')
    const [marcaSelected, setSelectedMarca] = useState('')
    const [loadingAnimal, setLoadingAnimal] = useState(false)
    const [loadingCategoria, setLoadingCategoria] = useState(false)
    const [loadingMarca, setLoadingMarca] = useState(false)
    const [disabled, setDisabled] = useState(false)


    useEffect(() => {
        if (noMoreHits) {
            getData()
        }
    }, []);

    const buscaProducto = () => {
        setLoading(true)
        setDisabled(true)
        let productoVariations = compra ? compra.productos.map(el => el.prv_id) : [];
        let productoCombined = compra ? compra.productos_combinados.map(el => el.cop_id) : [];
        let params = {
            marca: marcaSelected,
            categoria: categSelected,
            animal: animalSelected,
            search: search,
            excluir_no_se_vende: excluir_no_se_vende,
            get_combinados: get_combinados
        }
        Mispichoshelper.getProducto(params).then(async response => {
            if (response && response.payload) {
                let product = response?.payload?.results ? response.payload.results : response.payload
                let combined = response?.payload?.results_combined ? response.payload.results_combined : []

                if (excluirProductoRepetido) {
                    product = await product.filter(el => {
                        if (!productoVariations.includes(el.prv_id))
                            return el
                    })
                    combined = await combined.filter(el => {
                        if (!productoCombined.includes(el.cop_id))
                            return el
                    })
                }
                setProd(product)
                setProductCombined(combined)
                setLoading(false)
                setDisabled(false)
            }
        });
        // setNoMoreHits(false)

    }

    const getData = async () => {
        // setLoading(true)
        setLoadingAnimal(true)
        setLoadingCategoria(true)
        setLoadingMarca(true)
        setDisabled(true)
        await Mispichoshelper.getAnimales().then(response => {
            if (response?.payload) {
                setAnimals(response.payload)
            }
            setLoadingAnimal(false)
        });
        await Mispichoshelper.getCategorias().then(response => {
            if (response?.payload) {
                setCateg(response.payload)
            }
            setLoadingCategoria(false)
        });
        await Mispichoshelper.getMarcas().then(response => {
            if (response?.payload) {
                setMarcas(response.payload)
            }
            setLoadingMarca(false)
        });
        setDisabled(false)
    }

    const addProduct = (item) => {
        sumarProducto(item);
        setProd([])
        setProductCombined([])
        if (selectCloseModal) toggleModal(false);
    }
    return (
        <section>
            <Row className="mb-4">
                <Col xxl={5} xl={12} className='p-2'>
                    <Select
                        showSearch
                        filterOption={
                            (input, option) =>
                                option.props.children &&
                                option.props.children.toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: '100%' }}
                        placeholder="Selecciona marca"
                        onChange={(evt) => { setSelectedMarca(evt); }
                        }
                        loading={loadingMarca}
                    >
                        <Option value=''>Todas las Marcas</Option>
                        {marcas.map((cada_marca, ind) =>
                            <Option
                                key={ind}
                                value={cada_marca.mar_id}
                            >
                                {cada_marca.mar_nombre}
                            </Option>)
                        }
                    </Select>
                </Col>
                <Col xxl={5} xl={12} className='p-2'>
                    <Select
                        showSearch
                        filterOption={
                            (input, option) =>
                                option.props.children &&
                                option.props.children
                                    .toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: '100%' }}
                        placeholder="Selecciona categoría"
                        onChange={(evt) => setSelectedCateg(evt)}
                        loading={loadingCategoria}
                    >
                        <Option value=''>Todas las Categorias</Option>
                        {categ.map((cada_categoria, ind) =>
                            <Option
                                key={ind}
                                value={cada_categoria.cat_id}
                            >
                                {cada_categoria.cat_nombre}
                            </Option>
                        )}
                    </Select>
                </Col>
                <Col xxl={5} xl={12} className='p-2'>
                    <Select
                        showSearch
                        filterOption={
                            (input, option) =>
                                option.props.children &&
                                option.props.children
                                    .toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: '100%' }}
                        placeholder="Selecciona animal"
                        onChange={(evt) => setSelectedAnimal(evt)}
                        loading={loadingAnimal}
                    >
                        <Option value="">Todos los Animales</Option>
                        {animals.map((cada_animal, ind) =>
                            <Option
                                key={ind}
                                value={cada_animal.atr_id}
                            >
                                {cada_animal.atr_nombre}
                            </Option>)
                        }
                    </Select>
                </Col>


                <Col xxl={6} xl={12} className='p-2'>
                    <Search
                        disabled={disabled}
                        placeholder="Buscalo por su nombre aca"
                        allowClear onSearch={() => buscaProducto()}
                        onChange={(e) => setSearch(e.target.value)}
                        style={{ height: "30px !important" }}
                    />

                </Col>

                <Col xxl={3} xl={24} className='p-2'>
                    <Button
                        disabled={disabled}
                        // loading={loading}
                        type={disabled ? 'default' : 'primary'}
                        className='mr-2 mb-2'
                        onClick={() => buscaProducto()}
                    >
                        <FaSearch size={18} className='mr-2' />
                        Buscar
                    </Button>

                </Col>
            </Row>

            {loading ? <Loading />
                :
                <Tabs defaultActiveKey="1" >
                    <TabPane tab="Variaciones" key="1">
                        {products.length > 0 ?
                            <section className='p-2'>
                                <ul className="list-group">
                                    {products.map((item, ind) =>
                                        <div >
                                            <Row type='flex' justify='space-between' key={ind} className='px-2'>
                                                <Col xl={3} className="pl-2">
                                                    <p>{"Producto ID: "}<strong>{item.pro_id}</strong></p>
                                                    <p>{"Variacion ID: "}<strong>{item.prv_id}</strong></p>
                                                </Col>
                                                <Col xl={3} className="pl-2">
                                                    <img src={item.pro_foto} alt='img' width={35} />
                                                </Col>
                                                <Col xxl={16} xl={13}>
                                                    <p>
                                                        {item.producto} &nbsp;&nbsp;
                                                        <strong>${item.precio_minimo}</strong>
                                                    </p>
                                                </Col>
                                                <Col xxl={2} xl={4}>
                                                    <Button
                                                        type='primary'
                                                        className="w100"
                                                        onClick={() => addProduct(item)}
                                                    >
                                                        Elegir
                                                    </Button>
                                                </Col>

                                            </Row>
                                            <hr />
                                        </div>

                                    )}

                                </ul>
                            </section>
                            :
                            <div>No se encontró resultado</div>
                        }
                    </TabPane>
                    {showTabCombined &&
                        <TabPane tab="Combinados" key="2">
                            {productCombined.length > 0 ?
                                <section className='p-2'>
                                    <ul className="list-group">
                                        {productCombined.map((item, ind) =>
                                            <div >
                                                <Row type='flex' justify='space-between' key={ind} className='px-2'>

                                                    <Col xl={3} className="pl-2">
                                                        <img src={item.photo} alt='img' width={35} />
                                                    </Col>
                                                    <Col xxl={19} xl={13}>
                                                        <p>
                                                            {item.full_name} &nbsp;&nbsp;
                                                            <strong>${item.price}</strong>
                                                        </p>
                                                    </Col>
                                                    <Col xxl={2} xl={4}>
                                                        <Button
                                                            type='primary'
                                                            className="w100"
                                                            onClick={() => {
                                                                sumarProducto(item);
                                                                if (selectCloseModal) toggleModal(false);
                                                            }}
                                                        >
                                                            Elegir
                                                        </Button>
                                                    </Col>

                                                </Row>
                                                <hr />
                                            </div>

                                        )}

                                    </ul>

                                </section>
                                :
                                <div>No se encontró resultado</div>
                            }
                        </TabPane>
                    }
                </Tabs>

            }



        </section >
    )
}


