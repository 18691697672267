import React, { useEffect } from 'react'
import { configFullScreen, LoadHeatMap } from '../utils/LoadMaps';

var mymap

export function DrawerHeatMap({ heat }) {

    const position = [-34.586017, -58.432288]
    const zoom = 12
    const scriptMapAndHeatZone = LoadHeatMap();

    useEffect(() => {
        if (scriptMapAndHeatZone === "ready") {
            loadMap();
        }
        // eslint-disable-next-line
    }, [scriptMapAndHeatZone])

    const loadMap = () => {
        var L = window.L
        // MAPA
        mymap = L.map('mapHeatZone', configFullScreen).setView(position, zoom);

        L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(mymap);

        // Z index
        mymap.createPane('labels');
        mymap.getPane('labels').style.zIndex = 1;
        setTimeout(() => { addHeatZoneMap() }, 100)

    }

    const addHeatZoneMap = () => {
        var HeatmapOverlay = window.HeatmapOverlay;

        mymap.eachLayer(function (layer) {
            if (layer._heatmap) {
                mymap.removeLayer(layer)
            }
        });

        if (heat && heat.length > 0) {
            // MAPA CALOR

            // Configuracion
            var config = {
                "radius": 0.002, // Radio del circulo por punto
                "maxOpacity": .8, // opacidad del color
                "scaleRadius": true, // si escala con el zoom del mapa
                "useLocalExtrema": true,
                latField: 'lat',
                lngField: 'lng',
                valueField: 'count'
            };

            // Crear Capa
            var heatmapLayer = new HeatmapOverlay(config);

            // Agregar Capa
            mymap.addLayer(heatmapLayer)

            // Agregar puntos de calor
            var points = {
                max: 3,
                data: heat
            };

            heatmapLayer.setData(points);
        }
    }

    return <div className="Card Aux"> <div id="mapHeatZone" className='MapaCobertura' /> </div>

}
