import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
//import { Icon } from 'antd';
import { MispichosVars } from '../../utils/Mispichoshelper';


//const AnyReactComponent = ({ text }) => <div>{text}</div>;
class AltaUsuarioMap extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeMarker: {},
      showingInfoWindow: false,
      selectedPlace: {},
      center: { lat: this.props.compra.cliente_latitud, lng: this.props.compra.cliente_longitud }, zoom: 14
    };
    this.initMap = this.initMap.bind(this);
  }

  initMap(mapProps, map) {
    const { google } = mapProps;
    console.log("por las dudas", google)
  }

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  render() {
    return (


      <Map google={this.props.google}
        onReady={this.initMap}
        initialCenter={this.state.center}
        zoom={13}
        mapTypeControl={false}
        fullscreenControl={false}
        zoomControl={false}
        streetViewControl={false}
        yesIWantToUseGoogleMapApiInternals>
        <Marker
          title={this.props.compra.compra_domicilio}
          name={'Cliente'}
          onClick={this.onMarkerClick}
          position={{ lat: this.props.compra.cliente_latitud, lng: this.props.compra.cliente_longitud }} />
        <Marker
          name={'Petshop'}
          icon={{
            url: "https://mispichos.com/public/img/favicon.png",
            anchor: { x: 32, y: 32 },
            scaledSize: { height: 45, width: 45 }
          }}
          onClick={this.onMarkerClick}
          position={{ lat: this.props.compra.petshop_latitud, lng: this.props.compra.petshop_longitud }} >

        </Marker>
        <InfoWindow
          marker={this.state.activeMarker}
          style={{ height: 100, width: 100 }}
          visible={this.state.showingInfoWindow}>
          <div>
            <h4>{this.state.selectedPlace.name}</h4>
          </div>
        </InfoWindow>
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: MispichosVars.GMAPS_KEY
})(AltaUsuarioMap)
