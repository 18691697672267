import React, { useEffect, useState } from "react";
import { Modal, Button } from 'antd';
import Mispichoshelper from "../../../utils/Mispichoshelper";
import CopyButton from "../../../components/common/CopyButton";

export default function GenerateLink({ visible, toggleModal, compra }) {

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        console.log('COMPRA!!!! ', compra);
    }, [])

    const sendPayment = (type = null) => {
        setLoading(true)
        Mispichoshelper.postGeneratePaymentLink({ com_id: compra.compra_id, type: type })
            .then((response) => {
                setLoading(false)
                if (response && response.payload && response.payload.status && response.payload.data) {

                    Modal.success({
                        title: response.payload.title,
                        content: (
                            <>
                                <p>{response.payload.data} {<CopyButton text={response.payload.data} />}</p>
                            </>
                        ),
                        onOk: () => toggleModal('generateLink', false),
                        okText: 'Ok'
                    });

                }
                else if (response && response.payload && response.payload.title) {
                    Modal.error({
                        title: "Importante",
                        content: "No pudimos generar el link de pago. " + response.payload.title,
                    });
                }
                else {
                    Modal.error({
                        title: "Importante",
                        content: "Hubo un problema y no pudimos generar el link de pago",
                    });
                }
            })
            .catch((e) => {
                Modal.error({
                    title: "Importante",
                    content: "Hubo un problema y no pudimos generar el link de pago",
                });
            })
            ;
    }

    // Botones
    const cancelButton = () => {
        <Button key="cancel" loading={loading} onClick={() => toggleModal('generateLink', false)}>
            Cancelar
        </Button>
    }

    const linkButton = () => {
        return (
            <Button key="link" loading={loading} onClick={() => sendPayment()}>
                Generar link
            </Button>
        )
    }

    const linkButtonDiscount = () => {
        return (
            <Button key="link_with_discount" loading={loading} onClick={() => sendPayment("DISCOUNT")}>
                Generar link con Descuento (5%)
            </Button>
        )
    }

    return (
        <Modal
            title="Generar link de pago"
            visible={visible}
            onOk={() => loading ? null : sendPayment()}
            okText={loading ? "Cargando..." : "Generar link"}
            onCancel={() => loading ? null : toggleModal('generateLink', false)}
            footer={[
                <Button type="danger" key="cancel" loading={loading} onClick={() => toggleModal('generateLink', false)}>
                    Cancelar
                </Button>
                ,
                ((compra.compra_estado_id === 1 && compra.petshop_pro_id === 1 && !compra.eliminada && !compra.spliteada && !compra.en_pausa && !compra.se_sortea) ?
                    <Button type="primary" key="link_with_discount" loading={loading} onClick={() => sendPayment("DISCOUNT")}>
                        Generar link con Descuento (5%)
                    </Button>
                    :
                    null
                )
                ,
                <Button type="primary" key="link" loading={loading} onClick={() => sendPayment()}>
                    Generar link
                </Button>
            ]}
        >
            <div>
                <p>¿Estás seguro que deseas generar un link de pago? Cuando el cliente complete el pago el pedido cambiará el método de pago a tarjeta.</p>
            </div>
        </Modal>
    )
}

