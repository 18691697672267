import React from "react";
import { withRouter } from "react-router-dom";
import Base from "../componentsCustomerService/Order/Base";

class Compras extends React.Component {

  render() {
    var compra_id = null
    if (this.props.match.params.compra_id !== undefined) {
      compra_id = this.props.match.params.compra_id;
    } else if (this.props.com_id !== undefined) {
      compra_id = this.props.com_id;
    }
    else {
      const { location } = this.props;
      const search = location.search; // could be '?foo=bar'
      const params = new URLSearchParams(search);
      compra_id = params.get('compra_id'); // bar
    }

    return <Base compraIdSeleccionada={compra_id} />;
    /*return (
      <section className="CompraResumenBox">
        <div className='RowCenter' >
          <button onClick={this.volverDash.bind(this)} >
            <FontAwesomeIcon icon={faAngleLeft} />
          </button>
        </div>
        <Base compraIdSeleccionada={compra_id} />
      </section>
    )
    */
  }
}
export default withRouter(Compras);