import { Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'shards-react';
import Loadin from '../../../components/componentsUI/Loading';
import Mispichoshelper from '../../../utils/Mispichoshelper';

const Option = Select.Option;

function ToContactCS({ showModals, compra, close, refrescarCompra }) {
  const [opcionesToCS, setOpcionesToCS] = useState([])
  const [note, setNote] = useState('')
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getOpcionesToContacted();
  }, [])

  const getOpcionesToContacted = () => {
    Mispichoshelper.getMotivosToContactCS().then(response => {
      console.log("Opciones ------>", response.payload);
      if (response.payload) {
        setOpcionesToCS(response.payload);
      }
    });
  }

  function getOptionsData() {
    let data = [];
    selectedOptions.map((value) => {
      opcionesToCS.map((mot) => {
        if (mot.mot_id === value) {
          data.push({ key: value, label: mot.mot_nombre })
        }
        return data
      })
      return data;
    })
    return data;
  }

  function sendData() {
    setLoading(true)
    let auxMotivos = getOptionsData();
    if (auxMotivos.length === 0) {
      Modal.error({
        title: "Información",
        content: "Por favor seleccioná minimo un motivo!",
      });
      return;
    }
    if (note.length <= 3) {
      Modal.error({
        title: "Información",
        content: "Por favor seleccioná ingrese una observación!",
      });
      return;
    }
    Mispichoshelper.toContactByCs(
      { com_id: compra.compra_id, data: getOptionsData(), observation_note: note }
    ).then(informaResponse)
  }

  const informaResponse = (response) => {
    refrescarCompra(compra.compra_id);

    if (response.payload.status) {
      success(response);
      setLoading(false)
    } else {
      error(response);
      setLoading(false)
    }
  }

  const success = (response) => {
    Modal.success({
      title: response.payload.title,
      content: response.payload.message,
      onOk: () => close("showToContactByCs", false),
      okText: 'Entendido'
    });
  }

  const error = (response) => {
    Modal.error({
      title: response.payload.title,
      content: response.payload.message,
      onOk: () => close("showToContactByCs", false),
    });
  }

  return (
    <Modal
      title='A contactar por Customer Service. ¿Estás seguro que lo requiere?'
      className='p-0 w-50'
      visible={showModals.showToContactByCs}
      onOk={sendData}
      onCancel={() => { close("showToContactByCs", false) }}
    >

      <div>
        <Row>
          <Col>
            <Select
              mode="multiple"
              showSearch
              style={{ width: '100%' }}
              placeholder="Selecciona los motivos"
              onChange={(val) => { setSelectedOptions(val) }}>
              {opcionesToCS.map((value, key) =>
                <Option key={key} value={value.mot_id}>{value.mot_nombre}</Option>
              )}
            </Select>
          </Col>
        </Row>
        <Row>
          <Col>
            <textarea style={{ width: '100%', padding: '15px', marginTop: '10px' }} className="campoTextarea" onChange={(val) => { setNote(val.target.value) }} placeholder="Observaciones" value={note}></textarea>

          </Col>

        </Row>
      </div>
      <div style={{ justifyContent: 'center', alignItems: 'center' }}>
        {loading && <Loadin />}
      </div>
    </Modal>
  )
}

export default ToContactCS;
