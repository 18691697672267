import React from "react";
import es_ES from 'antd/es/locale-provider/es_ES';
import { DatePicker, ConfigProvider, Tag, Modal, Select } from 'antd';
import Mispichoshelper from '../../utils/Mispichoshelper';
import Button from '../../components/componentsUI/Button';
import Loadin from "../../components/componentsUI/Loading";

const Option = Select.Option;

class AdminNotificationsComponent extends React.Component {

  state = {
    value: 1,
    petshops: [],
    loading: false,
    data: [],
    selectedPetshoop: "",
    selectedType: null,
    selectedEmail: {},
    fechaSelected: "",
    // Paginacion
    page: 1,
    countRows: null,
  };
  onChange = e => {
    console.log('radio checked', e.target.value);
    this.setState({
      value: e.target.value,
    });
  };

  componentDidMount() {
    this.getPetshopList();
    this.getNotifications();
  }



  getNotifications = (page) => {

    // Loading
    this.setState({ loading: true })

    const limit = 50;
    const auxPage = page ? page - 1 : 0;

    Mispichoshelper.getNotificationHistorial({ limit: limit, page: auxPage, userId: this.state.selectedPetshoop, fecha: this.state.fechaSelected, type: this.state.selectedType }).then(response => {
      this.setState({ loading: false, data: response.payload.rows, countRows: response.payload.cant_total_rows }, () => console.log("recorrio helper", this.state.data))
    })
  }

  getPetshopList = () => {
    Mispichoshelper.getPetshopList({ full: 1 }).then(response => {
      this.setState({ petshops: response.payload });
    });
  }

  setIndexFilter = (value) => {
    this.setState({ selectedPetshoop: value });
  }

  setIndexFilterType = (value) => {
    this.setState({ selectedType: value });
  }

  searchNotifcation = () => {
    this.getNotifications();
  }

  onChangeDate = (date, dateString) => {
    this.setState({ fechaSelected: dateString })
  }

  closeModalEmail = () => {
    this.setState({ selectedEmail: {} })
  }

  // Paginacion
  onChangePagination = (page, pageSize) => {
    this.setState({ page: page })
    this.getNotifications(page);
  }

  render() {

    return (
      <section fluid className="GlobalStructure">

        <h4>Mis Notificaciones</h4>
        <div className="GridDosOctavos">
          <article className='Card'>
            <fieldset>
              <ConfigProvider locale={es_ES}>
                <DatePicker onChange={this.onChangeDate} />
              </ConfigProvider>
            </fieldset>
            <fieldset>
              <Select
                //onSearch={onSearch}
                showSearch
                filterOption={(input, option) =>
                  option.props.children && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                placeholder="Petshop"
                onChange={(e) => this.setIndexFilter(e)}
                style={{ width: "100%" }}
              >
                <Option value={0}>Todos</Option>
                {this.state.petshops.map((eachPetshop, i) => {
                  return <Option key={i} value={eachPetshop.usu_id}>{eachPetshop.nombre}</Option>
                })}
              </Select>
            </fieldset>
            <fieldset>
              <select
                showSearch
                filterOption={(input, option) =>
                  option.props.children && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                placeholder="Tipo de notificacion"
                onChange={(e) => this.setIndexFilterType(e.target.value)}>
                <option value={0}>Todas</option>
                <option value={"web"}>Web</option>
                <option value={"webCheck"}>webCheck</option>
                <option value={"webClickOrder"}>webClickOrder</option>
                <option value={"push"}>Push</option>
                <option value={"checkStatus"}>CheckStatus</option>
                <option value={"mail"}>Mail</option>
              </select>
            </fieldset>
            <fieldset style={{ marginBottom: '0' }}>
              <Button type="primary" onPress={this.searchNotifcation} loading={this.state.loading} labelText={'Buscar'} />
            </fieldset>
          </article>
          <article>
            {this.state.loading ?
              <Loadin fullHeight={true} />
              :
              this.state.data.length > 0 ? this.state.data.map((cada_nt, i) => {
                return (
                  <article className='Card Notificaciones' key={i}>
                    <div className="RowCenter SpaceBetween">
                      {cada_nt.punto_venta ?
                        <h5 className="notification-rigth">
                          {cada_nt.punto_venta.puv_nombre}
                        </h5>
                        : cada_nt.usuario &&
                        <h5 className="notification-rigth">
                          {cada_nt.usuario.name}
                        </h5>
                      }
                      <em>{cada_nt.created_at + ' Hs'}</em>
                    </div>

                    <div className="RowCenter SpaceBetween">
                      <div className='M1 w100'>
                        {cada_nt.title}
                      </div>
                      {cada_nt.type !== "mail" ? <p>{cada_nt.message}</p> : <Button type='success' labelText={'Ver Mail'} onPress={() => { this.setState({ selectedEmail: cada_nt }) }} />}
                    </div>

                    {cada_nt.response && (cada_nt.type === "mail" ? null : <p><Tag color="#2db7f5">{cada_nt.response}</Tag></p>)}

                  </article>
                )
              })
                :
                this.state.data && this.state.data.length === 0 &&
                <div className='notificationBox-item alert-normal'>
                  <h6>{"Todavia no tenes Notificaciones"}</h6>
                  <p>
                    {"Seguramente tendras una dentro de poco No te desesperes"}
                  </p>
                </div>
            }
          </article>
        </div>

        <Modal
          title={"Enviado a:" + this.state.selectedEmail.email_to}
          className='p-0'
          style={{ minWidth: '60vw' }}
          visible={this.state.selectedEmail.id !== undefined}
          onOk={this.closeModalEmail}
          width={700}
          onCancel={this.closeModalEmail}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: this.state.selectedEmail.message
            }}></div>
        </Modal>
      </section>
    );
  }
};

export default AdminNotificationsComponent;
