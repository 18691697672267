import { Modal } from 'antd';
import React, { useState } from 'react';
import Loadin from '../../../components/componentsUI/Loading';
import Mispichoshelper from '../../../utils/Mispichoshelper';



function ContactedByCS({ showModals, compra, close, refrescarCompra }) {
    const [note, setNote] = useState('')
    const [loading, setLoading] = useState(false)

    function sendData() {
        setLoading(true)
        Mispichoshelper.contactedByCs({ com_id: compra.compra_id, observation_note: note }).then(informaResponse)
    }

    const informaResponse = (response) => {
        refrescarCompra(compra.compra_id);

        if (response.payload.status) {
            success(response);
            setLoading(false)
        } else {
            error(response);
            setLoading(false)
        }
    }

    const success = (response) => {
        Modal.success({
            title: response.payload.title,
            content: response.payload.message,
            onOk: () => close("showContactedByCs", false),
            okText: 'Entendido'
        });
    }

    const error = (response) => {
        Modal.error({
            title: response.payload.title,
            content: response.payload.message,
            onOk: () => close("showContactedByCs", false),
        });
    }

    return (
        <Modal
            title='¿Cliente contactado?'
            className='p-0 w-50'
            visible={showModals.showContactedByCs}
            onOk={sendData}
            onCancel={() => { close("showContactedByCs", false) }}
        >
            <div>
                <h5>Confirmo que el cliente ya fué contactado por Customer Service</h5>
            </div>
            <div>
                <textarea style={{ minWidth: '105.5vh', padding: '15px', marginTop: '10px' }} className="campoTextarea" onChange={(val) => { setNote(val.target.value) }} placeholder="Observaciones" value={note}></textarea>
            </div>
            <div style={{ flex: 'row', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                {loading && <Loadin />}
            </div>
        </Modal>
    )
}

export default ContactedByCS;
