import React, { useEffect, useState } from "react";
import Mispichoshelper from "../../../utils/Mispichoshelper";
import { Collapse, Input, Icon } from 'antd';
import { useHistory } from "react-router-dom";
import Loadin from "../../../components/componentsUI/Loading";

const { Panel } = Collapse;
function PreviousOrder({ compra }) {

    const [previousOrder, setPrevOrder] = useState([])
    const [loading, setLoading] = useState(true)

    const history = useHistory()

    useEffect(() => {
        Mispichoshelper.getComprasPerfil({ usuario_id: compra.cliente_id, com_eliminada: 1 }).then((response) => {
            setPrevOrder(response.payload.rows);
            setLoading(false);
        });
        // eslint-disable-next-line
    }, []);

    const redireccionaHistorial = (order) => {
        history.push('/compras?compra_id=' + order);
        history.go();
    }

    const customPanelStyle = {
        background: '#fafafa',
        borderRadius: 4,
        marginBottom: 10,
        border: 0,
        overflow: 'hidden',
    };

    const entregado = (eachOrder) => {
        var icon = eachOrder.tag_mala_experiencia || eachOrder.estado == 'cancelado' ? 'close-circle' : 'check-circle';
        var color
        if (eachOrder.tag_mala_experiencia) {
            color = '#e00d1f'
        } else if (eachOrder.estado == 'cancelado') {
            color = 'silver'
        } else {
            color = '#52c41a'
        }
        return <Icon type={icon} theme="twoTone" twoToneColor={color} onClick={(event) => event.stopPropagation()} />
    };

    const selectBefore = () => {
        return (
            <select defaultValue="Todos" style={{ width: 150 }}>
                <option value="Todos">Todos</option>
                <option value="Cancelados">Entregados</option>
                <option value="Entregados">Cancelados</option>
            </select>
        );
    }

    if (loading) {
        return <Loadin />
    } else {
        return (
            <div>
                <div style={{ marginBottom: 16 }}>
                    <Input addonBefore={selectBefore} placeholder="Busca aca lo que necesites" />
                </div>
                <Collapse bordered={false} className='w-100'>
                    {previousOrder.map((order, index) =>
                        <Panel header={"#" + order.id + " Entrega " + order.proveedor + " el " + order.fechaEntrega + "hs | " + order.medio + " $" + order.monto} key={index} extra={entregado(order)} style={customPanelStyle}>
                            {order.producto}
                            <br />
                            <p className="textosLink" onClick={() => redireccionaHistorial(order.id)}>Ir a ese Pedido</p>
                        </Panel>
                    )}
                </Collapse>
            </div>
        );
    }
}



export default PreviousOrder;