import React from "react";

function InfoMessage ({text,additionalComponent}) {
    return (
        <p className="info-container info-message">
            {text}
            &nbsp;{additionalComponent}
        </p>
    )
}

export {InfoMessage};