import React, { useEffect, useState } from "react";
import moment from 'moment';
import 'moment/locale/es';
import { Modal, Drawer, Popover, Card, Avatar, Tag } from 'antd';
import Mispichoshelper from "../../../utils/Mispichoshelper";
import Zohohelper from "../../../utils/Zohohelper";
import UsuariosPerfil from "../../../components/Usuarios-elments/UsuariosPerfil";
import PreviousOrder from "../Actions/PreviousOrder";
import Movements from "../Actions/Movements";

import { AiOutlineEdit, AiOutlineFolderOpen } from "react-icons/ai";
import { AiOutlineMessage } from "react-icons/ai";
import { AiOutlineLoading } from "react-icons/ai";
import { AiOutlineSearch } from "react-icons/ai";
import { AiOutlineForm } from "react-icons/ai";
import { AiOutlineTags } from "react-icons/ai";
import { AiOutlinePhone } from "react-icons/ai";
import { AiOutlineRead } from "react-icons/ai";
import { FaHeadset } from "react-icons/fa";
import { FaTruck } from "react-icons/fa";
import { AiOutlineWallet } from "react-icons/ai";
import { AiOutlineDollar } from "react-icons/ai";
import { CriterionTags } from "../../../functions/criterionTag";

// Mapa
import Leaflet from "../../../components/map/LeafletMultiple";
import Loading from "../../../components/componentsUI/Loading";


function VerPerfilCliente({ refrescarCompra, compraId, clienteId, showbutton }) {
    const [verUser, setVerUser] = useState(false)

    return (
        <div>
            {showbutton ?
                <AiOutlineForm className='compraAccionBtn' onClick={() => setVerUser(true)} />
                :
                <div className='TagItem Danger'> Revisar teléfono  </div>
            }
            <Drawer
                width={'400px'}
                placement="right"
                closable={false}
                onClose={() => setVerUser(false)}
                visible={verUser}
            >
                <UsuariosPerfil refrescarCompra={refrescarCompra} compraId={compraId} clienteId={clienteId} />
            </Drawer>
        </div>
    )
}

function VerComprasAnt({ compra }) {
    const [comprasAnt, setComprasAnt] = useState(false)

    return (
        <div>
            <AiOutlineFolderOpen className='compraAccionBtn' onClick={() => setComprasAnt(true)} />
            <Modal
                title="Compras Anteriores"
                style={{ minWidth: '45vw' }}
                visible={comprasAnt}
                onOk={() => setComprasAnt(false)}
                onCancel={() => setComprasAnt(false)}
            >
                <PreviousOrder compra={compra} />
            </Modal>
        </div>
    )

}

function TelefonoPet({ telefono, petshop_email }) {

    const content =
        <div className='TelefonoPet'>
            {telefono}
            <FaHeadset onClick={() => { window.open('https://crmplus.zoho.com/mispichos/index.do/cxapp/support/mispichos/ShowHomePage.do#Contacts/search/CurDep/' + petshop_email) }} />
        </div>;

    return <Popover placement="right" content={content} trigger="click"> <AiOutlinePhone className='compraAccionBtn ml-3' /></Popover>

}
function TelefonoTransporter({ telefono, name_transporter }) {

    const content =
        <div className='TelefonoPet'>
            {telefono}
            <FaHeadset />
        </div>

    return <Popover placement="right" content={content} trigger="click"> <FaTruck className='compraAccionBtn ml-3' /></Popover>
}

function MovimientosPichoPesos({ compra }) {
    const [resultados, setResultados] = useState(compra.mov_pichoPesos);

    const content =
        <div>
            {resultados.map((item, i) => {
                var monto = 0
                if (item.data !== null) {
                    let data = JSON.parse(item.data)
                    if (data?.amount_credit) {
                        monto = data.amount_credit
                    }
                }
                return (
                    <div>
                        <AiOutlineDollar className='compraAccionBtn' />&nbsp;
                        {item.title} - ${monto}
                    </div>
                )

            })}
        </div>

    return <Popover placement="top" content={content} trigger="hover"> <AiOutlineWallet className='compraAccionBtn ml-3' /> </Popover>
}

function VerMovimientos({ compra }) {
    const [resultados, setResultados] = useState([])
    const [movimientos, setMovimientos] = useState()
    const [showAlgorithm, setAlgorithm] = useState()

    useEffect(() => {
        getMovimientos()
        // eslint-disable-next-line
    }, []);

    const getMovimientos = () => {
        var params = { 'tipo': 0, 'compra_id': compra.compra_id };
        Mispichoshelper.getMovimientosCompra(params).then((response) => {
            if (response && response.payload !== undefined) {
                setResultados(response.payload);
                console.log("Datos de los movimientos de la compra", response)
            }
        });
    }

    /* Movimientos */

    const showMovimientos = () => {
        setMovimientos(true)
        getMovimientos()
    }

    const toggleAlgorithm = () => setAlgorithm(!showAlgorithm)


    return (
        <div>
            <AiOutlineSearch className='compraAccionBtn' onClick={toggleAlgorithm} />

            <Modal
                title={`Información calculada por el algoritmo, última actualización ${compra.algorithm && compra.algorithm.date ? moment(compra.algorithm.date).format('DD/MM/YYYY H:mm:s') : null}`}
                style={{ minWidth: '62vw' }}
                visible={showAlgorithm}
                onOk={toggleAlgorithm}
                onCancel={toggleAlgorithm}
            >
                <div>
                    {compra.algorithm && compra.algorithm.petshops.map((eachPetshop, ind) =>
                        <div key={ind} style={{ marginBottom: 10 }}>
                            <strong>{eachPetshop.puv_nombre + ": "}</strong>
                            <CriterionTags data={eachPetshop} />
                        </div>
                    )}

                </div>
            </Modal>


            <AiOutlineRead className='compraAccionBtn' onClick={showMovimientos} />
            <Modal
                title="Movimientos"
                style={{ minWidth: '62vw' }}
                visible={movimientos}
                onOk={() => setMovimientos(false)}
                onCancel={() => setMovimientos(false)}
            >
                <Movements resultados={resultados} />
            </Modal>
        </div>
    )

}

function VerNotas({ loadAsync, data }) {
    const [verDrawer, setVerDrawer] = useState()
    var optionsArray = [];

    /* Perfil del cliente */

    const showDrawer = async () => {
        if (loadAsync) { await loadAsync(); }
        setVerDrawer(true)
    };

    const getOptionsArray = (comment) => {
        let lastIndex = comment.indexOf('-');
        let newIndex = 0;
        while (newIndex !== -1) {
            for (let i = 1; i < comment.length; i++) {
                newIndex = comment.indexOf('-', (lastIndex + i));
                if (newIndex !== -1) {
                    console.log("new", newIndex);
                    optionsArray.push(comment.slice(lastIndex, newIndex));
                    lastIndex = newIndex
                    console.log("last", lastIndex)
                }
            }
        }
    }

    return (
        <div styles='margin-left: -20'>
            <AiOutlineMessage className='compraAccionBtn' onClick={showDrawer} />
            <Drawer
                width={'400px'}
                placement="right"
                closable={false}
                onClose={() => setVerDrawer(false)}
                visible={verDrawer}
            >
                <section className='CompraNotas'>
                    {data.map((eachComment, iComment) =>
                        <article key={iComment}>
                            <div>
                                <b>
                                    <span>{eachComment.date + 'hs'}</span>
                                    <h4>{eachComment.user}</h4>
                                </b>
                                {console.log("EACH COMMENT-->", eachComment.data)}
                                {eachComment.data ?
                                    <p>
                                        {eachComment.data.length > 0 ? <strong>A contactar por CS. Motivo/s:</strong> : <strong>Cliente contactado CS:</strong>}
                                        {eachComment.data.length > 0 && eachComment.data.map((motivo) =>
                                            <div key={motivo.key}>&bull;{motivo.label}</div>
                                        )}
                                        <div><strong>Observaciones:</strong> {eachComment.value}</div>
                                    </p>
                                    :
                                    <div>
                                        {typeof (eachComment.data) === 'object' && eachComment.label ? <p>{eachComment.label}</p> : <p>{eachComment.value}</p>}
                                    </div>
                                }
                            </div>
                        </article>

                    )}
                </section>
            </Drawer>
        </div>
    )
}

function VerTickets({ data }) {
    const [verDrawer, setDrawer] = useState()
    const [dataTicket, setDataTicket] = useState()

    useEffect(() => {
        if (!data) return;

        let params = { email: data.compra_clt_mail }

        Zohohelper.getTickets(params).then((response) => {
            //console.log("RESPONSE: ", response)
            if (response && response.status) {
                response.payload.data && setDataTicket(response.payload.data.data);
            }
        });

        // eslint-disable-next-line
    }, []);



    return (
        <div styles='margin-left: -20'>
            <AiOutlineTags className='compraAccionBtn' onClick={() => setDrawer(true)} />
            <Drawer
                width={'400px'}
                placement="right"
                closable={false}
                onClose={() => setDrawer(false)}
                visible={verDrawer}
            >
                <section className='CompraNotas'>
                    {dataTicket && dataTicket.length > 0 ?
                        dataTicket.map((element, index) =>
                            <a key={index} href={element.webUrl} target="_blank" rel="noopener noreferrer">
                                <article>
                                    <Card>
                                        <div>
                                            <b>
                                                <span>{moment(element.createdTime).format('MMMM Do YYYY, h:mm:ss a')}</span>
                                            </b>
                                            <p><strong>{"Ticket #" + element.ticketNumber}</strong><br />{element.subject}</p>
                                            {element.assignee ? (
                                                element.assignee.emailId ?
                                                    <Card.Meta
                                                        avatar={<Avatar src={element.assignee.photoURL} />}
                                                        title={"Asignado a: "}
                                                        description={element.assignee.emailId}
                                                    />
                                                    :
                                                    <p>{"Sin asignar"}</p>
                                            )
                                                :
                                                <p>{"Sin asignar"}</p>
                                            }
                                        </div>
                                    </Card>
                                </article>
                            </a>
                        )
                        :
                        <div style={{ textAlign: "center" }}>
                            <p>No tiene tickets registrados</p>
                        </div>
                    }
                </section>

            </Drawer>
        </div>
    )
}


function VerMapaMultiple({ mapShow, points, setMapShow }) {
    const [map, setMap] = useState(mapShow ? mapShow : false)
    const [cordenates, setCordenates] = useState(points)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        preparing();
        setMap(mapShow);
        setCordenates(points);
        // eslint-disable-next-line
    }, [mapShow, points]);


    const preparing = () => {
        setTimeout(() => { setLoading(false); }, 2500);
    }

    return (
        <Drawer
            width={'45%'}
            placement="right"
            closable={false}
            onClose={() => {
                setLoading(true)
                setMapShow(false)
            }
            }
            visible={map}
            bodyStyle={{ height: "100%", overflow: "auto", margin: 0, padding: 0 }}
        >
            {!loading ?
                <Leaflet points={cordenates} id={"mapCompraAccionesMult"} />
                :
               <Loading />
            }
        </Drawer>
    )
}

function AddComment({ compra, refrescarCompra }) {

    const [read, setRead] = useState(false);
    const [coment, setComent] = useState(false);
    const [nota, setNota] = useState(compra.compra_nota)


    const hideAddComent = () => {
        setRead(false);
        setComent(false);
    }

    const saveComent = () => {
        Mispichoshelper.postCompraComentario({ compra_id: compra.compra_id, 'nota': nota }).then(response => {
            if (response.status) {
                hideAddComent();
                refrescarCompra(compra.compra_id);
            }
        });
    }


    return (
        <div>

            <  AiOutlineEdit className='compraAccionBtn' onClick={() => setComent(true)} />
            <Drawer
                width={'480px'}
                placement="right"
                closable={false}
                onClose={() => hideAddComent()}
                visible={coment}
            >
                <div className="mt-5">
                    <textarea rows="8" maxLength="440" onChange={(evt) => setNota(evt.target.value)} className="campoTextarea" disabled={!read} defaultValue={compra.compra_nota}>
                    </textarea>
                    <p>Recuerda, el Pet que tenga este pedido <span className='textosCeleste'>no recibira notificacion</span> sobre los cambios en la nota. Si es algo relevante por favor escribele para que lo sepa.</p>
                    {read ?
                        <button className="btn btn-primary btn-block" onClick={saveComent}>
                            Guardar notas
                        </button>
                        :
                        <div>
                            <button onClick={() => setRead(true)} className="btn btn-outline-primary">Entendido</button>
                        </div>
                    }

                </div>
            </Drawer>

        </div>
    )

}





export { AddComment, VerPerfilCliente, VerComprasAnt, VerMovimientos, VerMapaMultiple, TelefonoPet, VerNotas, VerTickets, TelefonoTransporter, MovimientosPichoPesos };