
import React, { useState } from "react";
import { Icon, Popover } from 'antd';

const CopyButton = ({ text }) => {

    const [copy, setCopy] = useState(false);
    const [content, setContent] = useState("Copiar");

    const copyText = (text) => {
        var textField = document.createElement('textarea')
        textField.innerText = text
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
        setCopy(true)
        resetText()
        //alert("Se ha copiado: " + text)
    }

    const resetText = () => {
        setTimeout(() => {
            setCopy(false)
        }, 2000)
    }

    const copyfalse = () => {
        return (
            <p>{"Copiar"}</p>
        )
    }

    const copytrue = () => {
        return (
            <p style={{ color: 'green' }}>{"Copiado"}</p>
        )
    }

    return (
        <Popover content={copy ? copytrue() : copyfalse()} title={null} trigger="hover">
            <Icon type="copy" onClick={() => copyText(text)} />
        </Popover>
    )
}

export default CopyButton