import React, { useState } from "react";
import { FiTrash } from "react-icons/fi";
import { FiEdit } from "react-icons/fi";


function EditProductsByItem({ editaCantidadProducto, indiceProducto, producto, calculaTotal }) {
    const [disable, setDisable] = useState(true)
    const [newCant, setCant] = useState(producto.cor_cantidad)
    const [total, setTotal] = useState((producto.cor_precio * producto.cor_cantidad))


    const calculaPrecio = (valor) => {
        cambiaCantidad(valor.target.value);
    }

    const cambiaCantidad = async(cantidad) => {
      var cantPrev = newCant;
      setCant(cantidad)
      let tipoIdentificador  = producto.cop_id ? 'cop_id' : null
      let result = editaCantidadProducto(indiceProducto, cantidad,tipoIdentificador);
      if (result === undefined || result !== false) {
          setTotal(cantidad * producto.cor_precio)
          calculaTotal();
      } else {
          setCant(cantPrev)
      }
    }
    const borraProducto = () => cambiaCantidad(0);
    const handleInput =(event) =>{
      if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
      }
    }

    return (
        <div className="editarProductosCadaItem">

            <div className='' onClick={()=>borraProducto()}><FiTrash/></div>

            <img width='30px' src={producto.prv_foto_link} alt='imgen producto' />

            <p className={newCant === 0 ? 'itemTachado' : null}>
                {producto.prv_producto_nombre}, c/u ${producto.cor_precio}
            </p>

            {disable ?

                <div className='TextEnd w100'><FiEdit onClick={() => setDisable(!disable)} /></div>

                :
                <input className="itemAumentaProducto" type={"number"} min={1} max={15} disabled={disable} onKeyPress={(event) => handleInput(event)}  onChange={(value) => calculaPrecio(value)} value={newCant} />
            }

            <span className={newCant === 0 ? 'itemTachado' : null}>${total}</span>
        </div>
    )

}
export default EditProductsByItem;
