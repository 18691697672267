import React, { useEffect, useState } from "react";
import { Tabs, Modal, Button, Icon, Select, Tooltip } from 'antd';
import { Container, FormCheckbox, FormRadio } from "shards-react";
import Mispichoshelper from "../../../utils/Mispichoshelper";
import { InfoMessage } from "../../../utils/GeneralComponents";
import TextArea from "antd/lib/input/TextArea";
import constants from "../../../flux/constants";
import { FcInfo } from 'react-icons/fc';

const { Option } = Select;
const TabPane = Tabs.TabPane;
const confirm = Modal.confirm;

export default function ChangeDeliveryTime({ orderId, compra, refrescarCompra, hideReprogramar, showReprogramar, wizard = false, setNewSchedule = false, hidePetshop = false }) {
    const [timeSelect, setTimeSelect] = useState(0);
    const [noFilters, setNoFilters] = useState(false);
    const [dateSelected, setDateSelected] = useState(0);
    const [times, setTimes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [allPetshops, setAllPetshops] = useState(true);
    const [petSelected, setPetSelected] = useState(compra.compra_petshop);
    const [showModalInfo, toggleModalInfo] = useState(false);
    const [motivo, setMotiv] = useState(null);
    const [motivoId, setMotivoId] = useState(null);
    const [motivos, setMotivos] = useState([]);
    const [obs, setObs] = useState('');
    const [loadingPets, setLoadingPets] = useState(false);
    const [petshops, setPetshops] = useState([]);
    const [selectPet, setSelectPet] = useState([]);
    const [logistic_type, setTypeLogistic] = useState('petshop');
    const [loadingSelect, setLoadingSelect] = useState(false);
    const [hideOptionSelect, setHideOptionSelect] = useState(false);

    useEffect(() => {
        buscaHorarios();
        // eslint-disable-next-line
    }, [compra, noFilters, allPetshops, logistic_type])

    useEffect(() => {
        if (times.length > 0) {
            setSelectPet([])
            setTimeSelect(0)
            buscaHorarios()
        }
    }, [motivo])
    const buscaHorarios = () => {
        setLoading(true);
        setLoadingSelect(true);
        setPetshops([])
        var aux = orderId ? orderId : compra.compra_id;
        if (!wizard && motivos.length == 0) {
            Mispichoshelper.getReproggramingReasons().then(response => {
                let motiv = response.payload.filter(item => item.nombre == 'Responsabilidad cliente')
                setMotivoId(motiv.length > 0 ? motiv[0].id : null)
                setMotivos(response.payload)
            })
        }
        Mispichoshelper.getHorariosDeReprogramacion({
            solo_proveedor_compra: allPetshops,
            sin_filtros: noFilters ? 1 : 0,
            compra_id: aux,
            logistic_type: logistic_type
        }).then((response) => {
            setTimes(response.payload)
            setLoadingSelect(false);
            setLoading(false);
        });

    }

    const changeMotivo = (value) => {
        if (motivoId && value === motivoId) {
            setTypeLogistic('petshop')
            setHideOptionSelect(true)
            Modal.info({
                title: "Importante!",
                content: "Se cambio a logistica tipo petshop.",
            });
        } else {
            setHideOptionSelect(false)
        }
        setMotiv(value);
    }
    const setHorarioElegido = (evt) => {
        if (evt.target.value) {
            var splitted = evt.target.value.split(',');
            setTimeSelect(splitted[0]);
            setDateSelected(splitted[1]);
        }
    }

    const informaResponse = (response) => {
        var aux = orderId ? orderId : compra.compra_id;
        refrescarCompra(aux);
        setLoading(false);
        if (response.payload.status) {
            success(response);
        } else {
            error(response);
        }
    }

    const success = (response) => {
        Modal.success({
            title: response.payload.title,
            content: response.payload.message,
            onOk: () => hideReprogramar('reprogramarCompra', false),
            okText: 'Entendido'
        });
    }

    const error = (response) => {
        Modal.error({
            title: response.payload.title,
            content: response.payload.message,
            onOk: () => hideReprogramar('reprogramarCompra', false),
        });
    }

    const setPetShopHorarios = () => {
        setLoading(true);
        setAllPetshops(!allPetshops);
        setPetSelected(allPetshops ? false : compra.compra_petshop);
    }

    useEffect(() => {
        if (timeSelect !== 0 && dateSelected !== 0) {
            setLoadingPets(true)
            var aux = orderId ? orderId : compra.compra_id;
            console.log(JSON.stringify(compra?.allProductByPrv ? compra.allProductByPrv : compra.productos))
            Mispichoshelper.getPetshopsForReproggraming({
                tim_id: timeSelect,
                date: dateSelected,
                compra_id: aux,
                logistic_type: logistic_type
            }).then(response => {
                setPetshops(response.payload)
                let defaultPetshop =  response.payload.some(value => value.puv_pro_id === compra.petshop_pro_id);
                defaultPetshop = wizard &&  defaultPetshop? compra.petshop_pro_id : []
                setSelectPet(defaultPetshop)
                setLoadingPets(false)
            })
        }

    }, [timeSelect, dateSelected])

    const reasonNameById = (id) => {
        return (motivos.filter(each => each.id == id))[0];
    }

    const reprogramarCompra = () => {
        if (!motivo && !wizard) {
            Modal.error({
                title: "Momento!",
                content: "Por favor selecciona un motivo de reprogramacion",
            });
            return
        }
        if (timeSelect==0) {
            Modal.error({
                title: "Momento!",
                content: "Por favor selecciona un horario de reprogramacion",
            });
            return
        }
        if (!selectPet && !hidePetshop) {
            Modal.error({
                title: "Momento!",
                content: "Por favor selecciona un petshop",
            });
            return
        }

        if (obs === '' && !wizard) {
            Modal.error({
                title: "Momento!",
                content: "Por favor agrega una observacion",
            });
            return
        }
        var aux = orderId ? orderId : compra.compra_id;
        confirm({
            title: 'Confirma la reprogramación del pedido?',
            content: (wizard || !reasonNameById(motivo) ? '' : `Motivo:${reasonNameById(motivo).nombre}`),
            cancelText: 'Volver',
            okText: 'Si, reprogramar',
            onOk: () => {
                setLoading(true);
                let params = {
                    solo_proveedor_compra: selectPet ? false : allPetshops,
                    compra_id: aux, tim_id: timeSelect,
                    fecha: dateSelected,
                    petshop: selectPet,
                    motivo: motivo,
                    observaciones: obs,
                    logistic_type: logistic_type
                };
                if (wizard) {
                    setNewSchedule(params);
                } else {
                    Mispichoshelper.postReprogramaCompra(params).then(informaResponse);
                }

            }
        });
    }

    const getTextCondition = () => {
        let text = "Los horarios mostrados  cumplen con (producto, cobertura) y los petshop  cumplen con (radio, producto, dia, horario) para entregar este pedido"
        if (logistic_type == constants.LOGISTICA_COLECTA) {
            text = "Los horarios mostrados  cumplen con (producto, cobertura, categoria) y los petshop  cumplen con (radio, producto, dia, horario, categoria) para entregar este pedido"
        } else if (logistic_type == constants.LOGISTICA_ON_DEMAND) {
            text = "Los horarios mostrados  cumplen con (producto, cobertura) y los petshop  cumplen con (producto) para entregar este pedido"
        }
        return text
    }

    return (
        <Modal
            title="Reprograma el Horario"
            visible={showReprogramar}
            onOk={reprogramarCompra}
            onCancel={() => hideReprogramar('reprogramarCompra', false)}
            footer={[
                <Button key="back" onClick={() => hideReprogramar('reprogramarCompra', false)}>
                    Volver
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={() => reprogramarCompra()}>
                    Reprogramar
                </Button>,
            ]}
        >
            <Container>
                <InfoMessage

                    text={getTextCondition()}
                    additionalComponent={<p style={{ color: 'blue', cursor: 'pointer' }} onClick={() => toggleModalInfo(true)}>Necesito otros horarios</p>}
                />

                <Modal onCancel={() => toggleModalInfo(false)} onOk={() => toggleModalInfo(false)} title="Información adicional" visible={showModalInfo} >
                    <div className='container'>
                        <h3>Necesito otros horarios</h3>
                        <p>Esto suele pasar cuando se arregló con un petshop para entregar un pedido con alguna condición especial.</p>
                        <br />
                        <p>En este caso, tenes que controlar en la cuenta de petshop destino los siguientes puntos:</p>
                        <ul>
                            <li>Que el petshop tenga habilitado todos los productos que tiene la compra</li>
                            <li>Que el petshop entregue en la zona donde se encuentra el domicilio del cliente</li>
                            <li>Que el petshop tenga capacidad logística para recibir este pedido, en ese día y esa hora.</li>
                            <li>Confirmado esos puntos, recargas la sección de reprogramar pedido y podrás continuar :).</li>
                        </ul>

                    </div>
                </Modal>

                <h4>Actual:{petSelected ? petSelected : 'Todos los petshops'}</h4>
                {!wizard ? <ShowMotives changeMotivo={changeMotivo} motivos={motivos} /> : null}
                <ShowTypeSerch setTypeLogistic={setTypeLogistic} hideOptionSelect={hideOptionSelect} logistic_type={logistic_type} loadingSelect={loadingSelect} />
                <ShowTypeFilter setPetShopHorarios={setPetShopHorarios} setNoFilters={setNoFilters} setLoading={setLoading} noFilters={noFilters} />
                <ShowSchedules loading={loading} times={times} setHorarioElegido={setHorarioElegido} />
                <br />
                <ShowPetshops loadingPets={loadingPets} petshops={petshops} setSelectPet={setSelectPet} times={times} hidePetshop={hidePetshop} selectPet={selectPet} />
                <br />
                {!wizard ? <ShowObs setMotiv={setMotiv} changeMotivo={changeMotivo} motivos={motivos} obs={obs} setObs={setObs} /> : null}

                <br />
            </Container>
        </Modal>
    )

}


const ShowSchedules = ({ loading, times, setHorarioElegido }) => {
    if (loading) {
        return <Icon type="loading" className='loader mb-4' />
    } else {
        return (
            <div>
                <label>Horarios</label>
                {times.length > 0 ?

                    <Tabs defaultActiveKey="1">
                        {times.map((cada_fecha, index) =>
                            <TabPane tab={cada_fecha.nombre.toUpperCase() + ' ' + cada_fecha.numero_dia} key={index + 1}>
                                {cada_fecha.horarios.map((cada_horario, i) =>
                                    <FormRadio
                                        key={i}
                                        name='Horario'
                                        value={cada_horario.tim_id + ',' + cada_fecha.fecha}
                                        onChange={evt => setHorarioElegido(evt)}  >
                                        De {cada_horario.tim_inicio} {cada_horario.tim_fin} hs
                                    </FormRadio>
                                )}
                            </TabPane>
                        )}
                    </Tabs>
                    :

                    <div ><br /> <label>Sin horarios de entrega</label> </div>
                }
            </div>


        );
    }
}

const ShowPetshops = ({ loadingPets, petshops, setSelectPet, times, hidePetshop, selectPet}) => {
    if (loadingPets) {
        return <p>Cargando petshops...</p>
    }

    if (petshops.length > 0) {
        return (
            <div> <label>Petshops disponibles</label>
                <legend>Petshops disponibles &nbsp;
                    {hidePetshop &&
                        <Tooltip title="En el caso de generar una orden de retiro, solo puede retirar el Petshop que originalmente entregó los productos" >
                            <FcInfo size={18} />
                        </Tooltip>
                    }
                </legend>
                <Select style={{ width: "100%" }}
                    disabled={hidePetshop}
                    onChange={(value) => setSelectPet(value)}
                    placeholder="Selecciona un petshop"
                    showSearch
                    filterOption={(input, option) =>
                        option.props && option.props.children && option.props.children[0] && option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
                        //option.props && option.props.children && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    value={selectPet}
                >
                    {petshops.map((pet, ind) =>
                        <Option key={ind} value={pet.puv_pro_id} >
                            {pet.puv_nombre}
                            {pet.distancia ? (pet.distancia / 1000) + "km " : ""}
                            {pet.tiene_capacidad ? "Capacidad: Si " : "Capacidad: No"}
                        </Option>
                    )}

                </Select>

            </div>
        )
    } else if (times.length > 0) {
        return (
            <div><br /> <label>Sin petshops disponibles</label> </div>
        )
    }
    return <div />
}
const ShowTypeSerch = ({ loading, setTypeLogistic, logistic_type, loadingSelect, hideOptionSelect }) => {


    return (
        <div> <label>Tipo logistica</label>

            <Select className="my-1" style={{ width: "100%" }}
                onChange={(value) => setTypeLogistic(value)}
                value={logistic_type}
                placeholder="Selecciona un tipo de logistica"
                showSearch
                disabled={loadingSelect}
                filterOption={(input, option) =>
                    option.props && option.props.children && option.props.children[0] && option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            >
                <Option value="">Selecciona un tipo de logistica</Option>
                <Option key={constants.LOGISTICA_PETSHOP} value={constants.LOGISTICA_PETSHOP}>Petshop</Option>
                <Option key={constants.LOGISTICA_COLECTA} value={constants.LOGISTICA_COLECTA}>Colecta</Option>
                {!hideOptionSelect && <Option key={constants.LOGISTICA_ON_DEMAND} value={constants.LOGISTICA_ON_DEMAND}>On demand</Option>}

            </Select>
        </div>
    )

}
const ShowTypeFilter = ({ setPetShopHorarios, setNoFilters, setLoading, noFilters }) => {

    return (
        <div className="my-3">
            <legend>Tipo filtrado de horarios&nbsp;
                <Tooltip title="capacidad: esto es la capacidad de compras por dia que indica en la tabla configuracion. no es capacidad del petshop.
                              inicio de franja: no descarta los bloques horarios que ya empezaron" >
                    <FcInfo size={18} />
                </Tooltip>
            </legend>
            <div className="my-1">
                <FormCheckbox value='1' onClick={setPetShopHorarios}>
                    Ver todos los Pet Shop disponibles
                </FormCheckbox>
                <FormCheckbox value='2' onClick={() => {
                    setNoFilters(!noFilters)
                    setLoading(true);
                }}>
                    ver sin filtro (de capacidad e inicio de franja)
                </FormCheckbox>

            </div>
        </div>

    )

}

const ShowObs = ({ obs, setObs }) => (

    <div>
        <br />
        <TextArea
            className='campoTextarea'
            placeholder="Detalles del inconveniente"
            autosize={{ minRows: 6, maxRows: 6 }}
            value={obs}
            onChange={(evt) => setObs(evt.target.value)}
        />
    </div>
)
const ShowMotives = ({ changeMotivo, motivos }) => (

    <div>
        <br />
        <label>Motivo de reprogramación</label>
        <br />
        <select
            className='w100 MT1 '
            onChange={(evt) => changeMotivo(parseInt(evt.target.value))}
            placeholder="Elige un motivo"
            required
        >
            <option value=''>Seleccionar</option>
            {motivos.map((item, idx) => (
                <option key={idx} value={item.id}>{item.nombre}</option>
            ))}
        </select>
        <br />
    </div>
)
