import React, { useEffect, useState } from "react";
import { Collapse, Icon } from 'antd';
import Loading from "../../../components/componentsUI/Loading";
//import { spread } from "q";

const Panel = Collapse.Panel;


function MassiveConsultation({ resultados }) {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoading(false), 2500)
        // eslint-disable-next-line
    }, []);


    if (loading) {
        return <Loading />
    } else {
        var respondieron = resultados.respondieron
        var no_respondieron = resultados.no_respondieron

        return (
            <div>
                <Collapse bordered={false} onChange={callback} className='w-100'>
                    {respondieron && respondieron.length > 0 &&
                        respondieron.map((cada_petshop, index) =>
                            <Panel header={cada_petshop.petshop} key={index} extra={todo()}>
                                <ul className='consultaItem' style={{ listStyle: 'none' }}>
                                    {cada_petshop.productos.map((cada_producto, i_producto) =>
                                        <li key={i_producto} className='tiene'>{cada_producto.producto}
                                            <ul>
                                                {cada_producto.variaciones.map((cada_variacion, i_variacion) => <div key={i_variacion}><li>{cada_variacion.prv_nombre}</li></div>)}
                                            </ul>
                                        </li>
                                    )}
                                </ul>
                            </Panel>
                        )}
                </Collapse>
                <Collapse bordered={false} onChange={callback} className='w-100'>
                    {no_respondieron && no_respondieron.length > 0 &&
                        no_respondieron.map((no_respondio, index_nr) =>
                            <Panel header={no_respondio.puv_nombre + ' no respondió'} key={index_nr} extra={seco()}></Panel>
                        )}
                </Collapse>
            </div>
        );
    }
}

export default MassiveConsultation;

function callback(key) {
    console.log(key);
}


const todo = () => (
    <Icon
        type="check-circle"
        theme="twoTone" twoToneColor="#52c41a"
        onClick={(event) => {
            // If you don't want click extra trigger collapse, you can prevent this:
            event.stopPropagation();
        }}
    />
);
/*const maso = () => (
    <Icon
        type="exclamation-circle"
        theme="twoTone" twoToneColor="#ffd700"
        onClick={(event) => {
        // If you don't want click extra trigger collapse, you can prevent this:
        event.stopPropagation();
        }}
    />
);*/

const seco = () => (
    <Icon
        type="close-circle"
        theme="twoTone" twoToneColor="tomato"
        onClick={(event) => {
            // If you don't want click extra trigger collapse, you can prevent this:
            event.stopPropagation();
        }}
    />
);