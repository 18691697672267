import { useEffect, useState } from "react";

// --- Librerias 

// Leaflet
const LeafletJS = "https://unpkg.com/leaflet@1.6.0/dist/leaflet.js";
const LeafletCss = "https://unpkg.com/leaflet@1.6.0/dist/leaflet.css";

// Leaflet Draw
const LeafletDraw = "https://cdnjs.cloudflare.com/ajax/libs/leaflet.draw/1.0.4/leaflet.draw.js";
const LeafletDrawCss = "https://cdnjs.cloudflare.com/ajax/libs/leaflet.draw/1.0.4/leaflet.draw.css"

// Geometry util
const LeafletGeometry = "/js/leaflet.geometryutil.js";

// Turf util
const LeafletTurf = "https://cdn.jsdelivr.net/npm/@turf/turf@6/turf.min.js";

// Heatmap
const scriptHeatMap = '/js/heatmap.min.js';
const scriptLeafletHeatMap = '/js/leaflet-heatmap.js';

// fullscreen
const LeafletFullScreenJs = '/js/leaflet.fullscreen.js';
const LeafletFullScreenCss = '/css/leaflet.fullscreen.css';

// Marker cluster
const LeafletMarkerClusterCss = '/css/MarkerCluster.css';
const LeafletMarkerClusterCssDefault = '/css/MarkerCluster.Default.css';
const LeafletMarkerCluster = '/js/leaflet.markercluster.js';

// Sipiderfier 
const LeafletSpiderfier = "https://cdnjs.cloudflare.com/ajax/libs/OverlappingMarkerSpiderfier-Leaflet/0.2.6/oms.min.js"

// --- Parametros configuracion

const configFullScreen = { fullscreenControl: true, fullscreenControlOptions: { position: 'topleft' } };

// --- Funciones para cargar archivos

function useScript(src, options, waitingScriptStatus) {
    // Keep track of script status ("idle", "loading", "ready", "error")
    const [status, setStatus] = useState(src ? "loading" : "idle");

    useEffect(
        () => {
            // Allow falsy src value if waiting on other data needed for
            // constructing the script URL passed to this hook.
            if (!src) {
                setStatus("idle");
                return;
            }

            if (waitingScriptStatus && waitingScriptStatus !== 'ready') {
                setStatus("waiting another script to ready");
                return;
            }

            // Fetch existing script element by src
            // It may have been added by another intance of this hook
            let script = document.querySelector(`script[src="${src}"]`);

            if (!script) {
                // Create script
                script = document.createElement("script");
                script.src = src;
                if (options) {
                    script.async = options.async;
                    script.defer = options.defer;
                } else {
                    script.async = true;
                }

                script.setAttribute("data-status", "loading");
                // Add script to document body
                document.body.appendChild(script);
                //console.log('want to load', script);

                // Store status in attribute on script
                // This can be read by other instances of this hook
                const setAttributeFromEvent = (event) => {
                    script.setAttribute(
                        "data-status",
                        event.type === "load" ? "ready" : "error"
                    );
                };

                script.addEventListener("load", setAttributeFromEvent);
                script.addEventListener("error", setAttributeFromEvent);
            } else {
                // Grab existing script status from attribute and set to state.
                setStatus(script.getAttribute("data-status"));
            }

            // Script event handler to update status in state
            // Note: Even if the script already exists we still need to add
            // event handlers to update the state for *this* hook instance.
            const setStateFromEvent = (event) => {
                setStatus(event.type === "load" ? "ready" : "error");
            };

            // Add event listeners
            script.addEventListener("load", setStateFromEvent);
            script.addEventListener("error", setStateFromEvent);

            // Remove event listeners on cleanup
            return () => {
                if (script) {
                    script.removeEventListener("load", setStateFromEvent);
                    script.removeEventListener("error", setStateFromEvent);
                }
            };
        },
        // eslint-disable-next-line
        [src, waitingScriptStatus] // Only re-run effect if script src changes
    );

    return status;
}

function useLinkStyle(src) {
    const [status, setStatus] = useState(src ? "loading" : "idle");

    useEffect(
        () => {
            // Allow falsy src value if waiting on other data needed for
            // constructing the script URL passed to this hook.
            if (!src) {
                setStatus("idle");
                return;
            }

            // Fetch existing script element by src
            // It may have been added by another intance of this hook
            let script = document.querySelector(`link[href="${src}"]`);

            if (!script) {
                // Create script
                script = document.createElement("link");
                script.href = src;
                script.rel = "stylesheet"

                script.setAttribute("data-status", "loading");
                // Add script to document body
                document.body.appendChild(script);
                //console.log('want to load', script);

                // Store status in attribute on script
                // This can be read by other instances of this hook
                const setAttributeFromEvent = (event) => {
                    script.setAttribute(
                        "data-status",
                        event.type === "load" ? "ready" : "error"
                    );
                };

                script.addEventListener("load", setAttributeFromEvent);
                script.addEventListener("error", setAttributeFromEvent);
            } else {
                // Grab existing script status from attribute and set to state.
                setStatus(script.getAttribute("data-status"));
            }

            // Script event handler to update status in state
            // Note: Even if the script already exists we still need to add
            // event handlers to update the state for *this* hook instance.
            const setStateFromEvent = (event) => {
                setStatus(event.type === "load" ? "ready" : "error");
            };

            // Add event listeners
            script.addEventListener("load", setStateFromEvent);
            script.addEventListener("error", setStateFromEvent);

            // Remove event listeners on cleanup
            return () => {
                if (script) {
                    script.removeEventListener("load", setStateFromEvent);
                    script.removeEventListener("error", setStateFromEvent);
                }
            };
        },
        // eslint-disable-next-line
        [src] // Only re-run effect if script src changes
    );

    return status;
}

// --- Cargar librerias: cada funcion carga los js y los css necesarios

function LoadMapAndDraw(returnFullStatus = false) {
    const css = useLinkStyle(LeafletCss);
    const cssDraw = useLinkStyle(LeafletDrawCss);
    const scriptLeaflet = useScript(LeafletJS);
    const scriptLeafletDraw = useScript(LeafletDraw, { defer: true, async: true }, scriptLeaflet);
    const loadFullScreen = LoadFullScreen();
    return !returnFullStatus ? scriptLeafletDraw : [scriptLeafletDraw, scriptLeaflet];
}

function LoadFullScreen() {
    const css = useLinkStyle(LeafletFullScreenCss);
    const scriptLeaflet = useScript(LeafletFullScreenJs);
    return scriptLeaflet;
}

function LoadMapAndTurf() {
    const loadMaps = LoadMapAndDraw();
    const scriptLeafletTurf = useScript(LeafletTurf)
    const loadFullScreen = LoadFullScreen();
    return [loadFullScreen, scriptLeafletTurf, loadMaps];
}

function LoadHeatMap() {
    const loadMaps = LoadMapAndDraw();
    const loadFullScreen = LoadFullScreen();
    const scriptLeFletHeat = useScript(scriptLeafletHeatMap, { defer: true, async: true }, loadMaps);
    const scriptHeat = useScript(scriptHeatMap);
    return loadMaps;
}

function LoadMapAndGeo() {
    const loadMaps = LoadMapAndDraw();
    const scriptLeFleatGeo = useScript(LeafletGeometry, { defer: true, async: true }, loadMaps);
    return loadMaps;
}

function LoadAllMaps() {
    const loadMaps = LoadMapAndDraw();
    const scriptLeafletTurf = useScript(LeafletTurf)
    const scriptLeFleatGeo = useScript(LeafletGeometry, { defer: true, async: true }, loadMaps);
    return loadMaps;
}

function LoadMapDrawAndCluster() {
    const css = useLinkStyle(LeafletCss);
    const scriptLeaflet = useScript(LeafletJS);

    const cssFullScreen = useLinkStyle(LeafletFullScreenCss);
    const jsFullScreen = useScript(LeafletFullScreenJs, { defer: true, async: false }, scriptLeaflet);

    const cssCluster = useLinkStyle(LeafletMarkerClusterCss);
    const cssClusterDefault = useLinkStyle(LeafletMarkerClusterCssDefault);
    const jsCluster = useScript(LeafletMarkerCluster, { defer: true, async: true }, jsFullScreen);

    return jsCluster;
}

function LoadMapAndSpiderfier() {
    const css = useLinkStyle(LeafletCss);
    const scriptLeaflet = useScript(LeafletJS);

    const cssFullScreen = useLinkStyle(LeafletFullScreenCss);
    const jsFullScreen = useScript(LeafletFullScreenJs, { defer: true, async: false }, scriptLeaflet);

    const jsSpiderfier = useScript(LeafletSpiderfier, { defer: true, async: true }, jsFullScreen);

    return jsSpiderfier;
}

export default LoadMapAndDraw
export { LoadMapAndTurf, LoadHeatMap, LoadMapAndGeo, LoadAllMaps, LoadMapDrawAndCluster, LoadMapAndSpiderfier, configFullScreen }