import React, { useEffect, useState } from 'react'
import tranporterIcon from '../../assets/icons/faTruck.png';
import problemIcon from '../../assets/icons/problem.png';
import HomeIcon from '../../assets/icons/faHome.png';
import LoadMapAndDraw from '../../utils/LoadMaps';

// Variables
var mymap;

function Leaflet({ points, id }) {

    const zoom = 17
    const scriptMapAndDraw = LoadMapAndDraw(true);
    const [firstLoad, setFirstLoad] = useState(false);
    const centerDefault = { lat: "-34.5860629", lng: "-58.4320691" }

    const cordenates = points ? points : [];

    useEffect(() => {
        let statusLoaded = scriptMapAndDraw.every(current => current == 'ready');
        if (scriptMapAndDraw.length && statusLoaded && !firstLoad) {
            initMap();
        }
        // eslint-disable-next-line
    }, [cordenates, scriptMapAndDraw])

    const initMap = () => {
        var L = window.L;

        var centro = cordenates.length > 0 ? [cordenates[0].lat, cordenates[0].lng] : [centerDefault.lat, centerDefault.lng];

        // MAPA
        clearMap(mymap);
        setFirstLoad(true);
        mymap = L.map(id).setView(centro, zoom);

        L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(mymap);

        addMarkers(cordenates)
    }

    const clearMap = (map) => {
        if (map !== undefined) {
            //map.off();
            map.remove();
        }
    }

    const addMarkers = (cordenates) => {
        var L = window.L;
        var marker;
        if (mymap) {
            mymap.eachLayer(function (layer) {
                if (!!layer.toGeoJSON) {
                    mymap.removeLayer(layer);
                }
            });
        }

        // Markers
        let centerMap = [];
        cordenates.map((point, index) => {
            let markercentro = [point.lat, point.lng];
            let title = point.title;
            let body = point.body;

            // Icons Img
            if (point.icon) {

                let objIcon = {
                    iconUrl: null,
                    iconSize: [40, 40], // size of the icon
                };
                if (point.icon === 1) {
                    objIcon.iconUrl = tranporterIcon;
                } else if (point.icon === 2) {
                    objIcon.iconUrl = HomeIcon;
                } else if (point.icon === 3) {
                    objIcon.iconUrl = problemIcon;
                } else if (point.iconUrl) {
                    objIcon.iconUrl = point.iconUrl;
                    objIcon.iconSize = point.iconSize ? point.iconSize : null;
                }

                // ICON
                var icon = L.icon(objIcon);

                marker = L.marker(markercentro, { icon: icon });
            }
            // Icons Color
            else if (point.color) {
                const markerHtmlStyles = `
                    background-color: ${point.color};
                    width: 36px;
                    height: 36px;
                    display: block;
                    left: -18px;
                    top: -18px;
                    position: relative;
                    border-radius: 20px 20px 0;
                    transform: rotate(45deg);
                    border: 1px solid #FFFFFF
                `

                var icon = L.divIcon({
                    iconAnchor: [0, 24],
                    labelAnchor: [-6, 0],
                    popupAnchor: [0, -36],
                    html: `<span style="${markerHtmlStyles}" />`
                })
                marker = L.marker(markercentro, { icon: icon });
            }
            // Default
            else {
                marker = L.marker(markercentro);
            }

            marker.addTo(mymap).bindPopup("<b>" + title + "</b><br>" + body);
            centerMap.push(markercentro)
            return centerMap
        })

        // CENTRAR
        console.log("CENTRAR MAPA", centerMap)
        if (centerMap.length > 0) mymap.fitBounds(centerMap);

    }

    return (
        <div id={id} style={{ width: "100%", height: "100%" }}></div>
    )

}

export default Leaflet;