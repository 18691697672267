import React from "react";
import { Popover, Calendar, Icon, ConfigProvider, Select, Modal } from 'antd';
import es_ES from 'antd/es/locale-provider/es_ES';
import Mispichoshelper from '../../utils/Mispichoshelper';
import { FaThList } from "react-icons/fa";
import { MispichosVars } from "../../utils/Mispichoshelper";

const dateFormat = 'DD-MM-YYYY';
const Option = Select.Option;

class PetshopFIlterDay extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            activo: this.props.activo ? this.props.activo : 'hoy',
            calendarDate: this.props.date ? this.props.date : null,
            selectDate: null,
            visible: false,
            petshops: [],
            petshopSelected: null,
            times: [],
            timeSelected: null,
            logisticSelected: null,
            suspiciousSelected: null,
            solutionOrderSelected: null,
            zones: [],
            zoneSelected: null,
            paymentMethod: null,
        };
    }

    componentDidMount() {
        this.getPetshops();
        this.getTimes();
        this.getZones();
    }

    getPetshops = () => {
        Mispichoshelper.getPetshopList({ full: 1 }).then((response) => {
            if (response.status) this.setState({ petshops: response.payload })
        });
    }

    getTimes = () => {
        Mispichoshelper.getSchedulesActive().then((response) => {
            if (response.status) this.setState({ times: response.payload.data })
        });
    }

    getZones = () => {
        var dateForZone = new Date();
        var params = {
            date: this.state.calendarDate ? this.state.calendarDate : dateForZone,
        }
        Mispichoshelper.ControlLogistic.getZonesFilterPanel(params).then((response) => {
            if (response && response.payload) this.setState({ zones: response.payload.rows })
        });
    }

    selectPetshop = (evt) => {
        this.setState({ petshopSelected: evt }, () => {
            console.log("estadooo", this.state.petshopSelected)
            this.sendDataFilters()
        })
    }
    

    selectPaymentMethod = (evt) => {
        this.setState({ paymentMethod: evt }, () => this.sendDataFilters())
    }

    selectZone = (evt) => {
        this.setState({ zoneSelected: evt }, () => this.sendDataFilters())
    }

    selectTime = (evt) => {
        this.setState({ timeSelected: evt }, () => this.sendDataFilters())
    }

    selectLogistic = (evt) => {
        this.setState({ logisticSelected: evt }, () => this.sendDataFilters())
    }

    selectSuspicious = (evt) => {
        this.setState({ suspiciousSelected: evt }, () => this.sendDataFilters())
    }

    selectSolutionOrder = (evt) => {
        this.setState({ solutionOrderSelected: evt }, () => this.sendDataFilters())
    }

    showSpecialTime = (time) => {
        let text = "";
        if (time.special_day && time.special_zone) {
            text = " (feriado/zona especial)"
        } else if (time.special_day) {
            text = " (feriado)"
        } else if (time.special_zone) {
            text = " (zona especial)"
        } else if (time.sunday) {
            text = " (domingo)"
        } else if (time.saturday) {
            text = " (sabado)"
        }
        return text
    }

    onCalendarChange = (date) => {
        var calendarDate = new Date(date.format('YYYY-MM-DDT00:00:00'));
        this.setState({ calendarDate: calendarDate });
    }

    onCalendarSubmit = () => {
        this.setState({ visible: false, selectDate: this.state.calendarDate }, () => {
            this.props.changeDate(this.state.calendarDate, this.state.activo);
        });
    }

    sendDataFilters = () => {
        console.log("ESTADOS A ENVIAR: ", this.state)
        let params = {
            puv_id: this.state.petshopSelected ? this.state.petshopSelected : null,
            tim_id: this.state.timeSelected ? this.state.timeSelected : null,
            logistic: this.state.logisticSelected ? this.state.logisticSelected : null,
            date: this.state.calendarDate ? this.state.calendarDate : null,
            suspicious: this.state.suspiciousSelected ? this.state.suspiciousSelected : null,
            solution_order: this.state.solutionOrderSelected ? this.state.solutionOrderSelected : null,
            zone_selected: this.state.zoneSelected ? this.state.zoneSelected : null,
            paymentMethod: this.state.paymentMethod ? this.state.paymentMethod : null,
        }
        console.log("LLEGA HASTA ACA!!", params);
        this.props.sendFilters && this.props.sendFilters(params);
    }

    handleVisibleChange = (visible) => {
        console.log("CAMBIAR A VISIBLEEE!!!: ", visible)
        this.setState({ visible })
    }

    // BTN clear
    reset() {
        this.props.reset();
        this.setState({ activo: 'ninguno' });
    }

    // BTN Hoy
    btnHoy(today) {
        this.setState({ activo: 'hoy' }, () => {
            this.props.changeDate(today, this.state.activo);
        });
    }

    // BTN MANANA
    btnMan(tomorrow) {
        this.setState({ activo: 'mañana' }, () => {
            this.props.changeDate(tomorrow, this.state.activo);
        });
    }

    // BTN CALENDARIO
    clickDate = () => {
        this.setState({ activo: 'calendario', visible: true });
    }

    treggoStatus = () => {
        var params = {
            com_id: this.props.rowSelected,
            treggoState: 'treggo',
        }
        Mispichoshelper.sendTreggoStatus(params).then(response => {
            if (response.status) {
                let parameters = {
                    puv_id: this.state.petshopSelected ? this.state.petshopSelected : null,
                    tim_id: this.state.timeSelected ? this.state.timeSelected : null,
                    date: this.state.calendarDate ? this.state.calendarDate : null
                }
                Modal.success({
                    title: response.payload.title,
                    content: response.payload.message,
                    okText: 'Entendido',
                    onOk: () => { this.props.sendFilters(parameters) }
                })
            }
        })
    }

    render() {
        var today = new Date();
        var tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);

        //Default value
        //var defaultValue = this.state.calendarDate ? moment(this.state.calendarDate) : null;

        const content = (
            <div style={{ width: 300, border: '1px solid transparent', borderRadius: 4, textAlign: 'center' }}>
                <ConfigProvider locale={es_ES}>
                    <Calendar fullscreen={false} format={dateFormat} onChange={(date) => this.onCalendarChange(date)} />
                    <label className={'botonItem'} onClick={() => { this.onCalendarSubmit() }} >
                        Buscar
                    </label>
                </ConfigProvider>
            </div>
        );
        console.log('render filter days');
        return (

            <div className='Card P0 RowCenter PetshopFIlterDay'>
                {this.props.reset &&
                    <button htmlFor="hoy" className={'botonItem botonItem'} onClick={() => { this.reset() }} >
                        Limpiar
                    </button>
                }
                <button htmlFor="hoy" className={this.state.activo === 'hoy' ? 'botonItem botonActivado' : 'botonItem'} onClick={() => this.btnHoy(today)} >
                    Hoy
                </button>
                <button htmlFor="mañana" className={this.state.activo === 'mañana' ? 'botonItem botonActivado' : 'botonItem'} onClick={() => this.btnMan(tomorrow)} >
                    Mañana
                </button>

                <button htmlFor="calendario" className={this.state.activo === 'calendario' ? 'botonItem botonActivado RowCenter' : 'botonItem'} onClick={() => { this.clickDate() }}>
                    <Icon type="calendar" />
                    {this.state.activo === 'calendario' && this.state.selectDate ? <div className='PL'>{' ' + this.state.selectDate.getDate() + '/' + (this.state.selectDate.getMonth() + 1) + '/' + this.state.selectDate.getFullYear()} </div> : null}
                </button>
                <Popover content={content}
                    title={false}
                    trigger="click"
                    visible={this.state.visible}
                    onVisibleChange={this.handleVisibleChange}
                />

                {/* Filtros */}
                {this.props.sendFilters &&
                    <div style={{ width: '250px', marginLeft: 10, paddingRight: "1%" }}>
                        <Select
                            showSearch
                            filterOption={(input, option) =>
                                option.props.children && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: '100%' }}
                            placeholder="Selecciona un petshop"
                            onChange={(evt) => this.selectPetshop(evt)}
                        >
                            <Option value={null}>Todos</Option>
                            {this.state.petshops.map((cada_pet, iPet) => {
                                if (cada_pet.pro_activo) {
                                    return <Option key={iPet} value={cada_pet.id}>{cada_pet.nombre}</Option>
                                }
                            })}
                        </Select>
                    </div>
                }
                {this.props.sendFilters &&
                    <div style={{ width: '250px', marginLeft: 10, paddingRight: "1%" }}>
                        <Select
                            showSearch
                            filterOption={(input, option) =>
                                option.props.children && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: '100%' }}
                            placeholder="Selecciona un horario"
                            onChange={(evt) => this.selectTime(evt)}
                        >
                            <Option value={null}>Todos</Option>
                            {this.state.times.map((time, i) => <Option key={i} value={time.id}>{"De " + time.start + " a " + time.end + " " + this.showSpecialTime(time)}</Option>)}
                        </Select>
                    </div>
                }
                {this.props.sendFilters &&
                    <div style={{ width: '250px', marginLeft: 10, paddingRight: "1%" }}>
                        <Select
                            showSearch
                            filterOption={(input, option) =>
                                option.props.children && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: '100%' }}
                            placeholder="Selecciona logística"
                            onChange={(evt) => this.selectLogistic(evt)}
                        >
                            <Option value={null}>Todos</Option>
                            <Option value={1}>Logística Propia Pet</Option>
                            <Option value={2}>Logística Colecta</Option>
                            <Option value={3}>Logística OnDemand</Option>
                        </Select>
                    </div>
                }
                {this.props.sendFilters &&
                    <div style={{ width: '250px', marginLeft: 10, paddingRight: "1%" }}>
                        <Select
                            showSearch
                            filterOption={(input, option) =>
                                option.props.children && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: '100%' }}
                            placeholder="Filtrar por estafa"
                            onChange={(evt) => this.selectSuspicious(evt)}
                        >
                            <Option value={null}>Todos</Option>
                            <Option value={1}>Posible Estafa</Option>
                            <Option value={2}>Estafa</Option>
                        </Select>
                    </div>
                }
                {this.props.sendFilters &&
                    <div style={{ width: '250px', marginLeft: 10, paddingRight: "1%" }}>
                        <Select
                            showSearch
                            filterOption={(input, option) =>
                                option.props.children && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: '100%' }}
                            placeholder="Filtrar por orden de solución"
                            onChange={(evt) => this.selectSolutionOrder(evt)}
                        >
                            <Option value={null}>Todos</Option>
                            <Option value={1}>Es orden de solución</Option>
                            <Option value={2}>Tiene orden de solución</Option>
                        </Select>
                    </div>
                }
                {this.props.sendFilters &&
                    <div style={{ width: '250px', marginLeft: 10, paddingRight: "1%" }}>
                        <Select
                            showSearch
                            filterOption={(input, option) =>
                                option.props.children && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: '100%' }}
                            placeholder="Filtrar por zona"
                            onChange={(evt) => this.selectZone(evt)}
                        >
                            <Option value='todos'>Todos</Option>
                            {this.state.zones.map((zone, k) => <Option key={k} value={zone.acc_display_name}>{zone.acc_display_name}</Option>)}
                        </Select>
                    </div>
                }
                {this.props.sendFilters &&
                    <div style={{ width: '250px', marginLeft: 10, paddingRight: "1%" }}>
                        <Select
                            showSearch
                            filterOption={(input, option) =>
                                option.props.children && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: '100%' }}
                            placeholder="Filtrar por método de pago"
                            onChange={(evt) => this.selectPaymentMethod(evt)}
                        >
                            <Option value={null}>Todos</Option>
                            <Option value={MispichosVars.MEDIO_PAGO_EFECTIVO}>Efectivo</Option>
                            <Option value={MispichosVars.MEDIO_PAGO_TARJETA}>Tarjeta</Option>
                            
                        </Select>
                    </div>
                }
                {this.state.petshopSelected != null && this.state.timeSelected != null && this.props.rowSelected.length > 0 && this.props.activeKey == 5 &&
                    <button className={'botonItem '} onClick={this.treggoStatus} >  Logistica Terciarizada  </button>
                }
                <div>
                </div>
            </div>
        )
    }
};

export default PetshopFIlterDay;