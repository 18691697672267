import React from 'react'
import './CardOliver.css'

const CardOliver = ({ children }) => {
    return (
        <div className='card-oliver'>
            {children}
        </div>
    )
}

export default CardOliver
