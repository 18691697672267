import React from "react";
import { Modal, Drawer, Popover, Icon, Card, Avatar, Tag } from 'antd';

import UsuariosPerfil from '../Usuarios-elments/UsuariosPerfil';
import ComprasAnteriores from './ComprasAnteriores';
import CompraMovimientos from './CompraMovimientos';
//import AltaUsuariosMapa from '../Usuarios-elments/AltaUsuariosMapa';
import Mispichoshelper from "../../utils/Mispichoshelper";
import Zohohelper from "../../utils/Zohohelper";
import moment from 'moment';
import 'moment/locale/es';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeadset } from "@fortawesome/free-solid-svg-icons";

// Mapa
import Leaflet from '../map/Leaflet'
import LeafletMultiple from '../map/LeafletMultiple'
import Loadin from "../componentsUI/Loading";
import Loading from "../componentsUI/Loading";




class VerPerfilCliente extends React.Component {

  constructor(props) {
    super(props);
    this.state = { verUser: false };
  }

  /* Perfil del cliente */

  showDrawer = () => {
    this.setState({
      verUser: true,
    });
  };

  onClose = () => {
    this.setState({
      verUser: false,
    });
  };

  render() {
    return (
      <div>

        <Icon type="form" className='compraAccionBtn' onClick={this.showDrawer} />
        <Drawer
          width={'400px'}
          placement="right"
          closable={false}
          onClose={this.onClose}
          visible={this.state.verUser}
        >
          <UsuariosPerfil refrescarCompra={this.props.refrescarCompra} compraId={this.props.compraId} clienteId={this.props.clienteId} />
        </Drawer>
      </div>
    )
  }
}

class VerComprasAnt extends React.Component {
  constructor(props) {
    super(props);
    this.state = { comprasAnt: false };

    this.showComprasAnt = this.showComprasAnt.bind(this);
    console.log('VerComprasAnt constructor');
  }

  /* Compras anteriores */
  showComprasAnt = () => {
    this.setState({ comprasAnt: true });
  }

  hideComprasAnt = () => {
    this.setState({ comprasAnt: false });
  }


  render() {
    return (
      <div>
        <Icon type="folder-open" className='compraAccionBtn' onClick={this.showComprasAnt} />
        <Modal
          title="Compras Anteriores"
          style={{ minWidth: '45vw' }}
          visible={this.state.comprasAnt}
          onOk={this.hideComprasAnt}
          onCancel={this.hideComprasAnt}
        >
          <ComprasAnteriores hideComprasAnteriores={this.hideComprasAnt} compra={this.props.compra} />
        </Modal>
      </div>
    )
  }
}

class TelefonoPet extends React.Component {
  constructor(props) {
    super(props);
    console.log('TelefonoPet constructor');
  }
  render() {
    const content = <div className='TelefonoPet RowCenter'>
      {this.props.telefono}
      <button onClick={() => {
        window.open('https://crmplus.zoho.com/mispichos/index.do/cxapp/support/mispichos/ShowHomePage.do#Contacts/search/CurDep/' + this.props.petshop_email)
      }} >
        <FontAwesomeIcon icon={faHeadset} />
      </button>
    </div>;
    return (
      <Popover placement="right" content={content} trigger="click">
        <Icon type="phone" className='compraAccionBtn ml-3' />
      </Popover>
    )
  }
}

class VerMovimientos extends React.Component {
  constructor(props) {
    super(props);
    this.state = { movimientos: false, resultados: [], showAlgorithm: false };

    this.showMovimientos = this.showMovimientos.bind(this);
    console.log('VerMovimientos constructor');
  }

  componentDidMount() {
    this.getMovimientos();
  }

  getMovimientos = () => {
    var params = { 'tipo': 0, 'compra_id': this.props.compra.compra_id };
    Mispichoshelper.getMovimientosCompra(params).then((response) => {
      if (response && response.payload !== undefined) {
        this.setState({ resultados: response.payload });
        console.log("Datos de los movimientos de la compra", response)
      }
    });
  }

  /* Movimientos */

  showMovimientos = () => {
    this.setState({ movimientos: true }, () => {
      this.getMovimientos();
    });
  }
  toggleAlgorithm = () => this.setState({ showAlgorithm: !this.state.showAlgorithm })

  hideMovimientos = () => {
    this.setState({ movimientos: false });
  }

  render() {
    return (
      <div>
        <Icon title="Información del algoritmo" type="search" className='compraAccionBtn' onClick={() => this.toggleAlgorithm()} />

        <Modal
          title={`Información calculada por el algoritmo, última actualización ${this.props.compra.algorithm && this.props.compra.algorithm.date ? moment(this.props.compra.algorithm.date).format('DD/MM/YYYY H:mm:s') : null}`}
          style={{ minWidth: '62vw' }}
          visible={this.state.showAlgorithm}
          onOk={() => this.toggleAlgorithm()}
          onCancel={() => this.toggleAlgorithm()}
        >
          <div>
            {this.props.compra.algorithm && this.props.compra.algorithm.petshops.map(eachPetshop =>
              <div style={{ marginBottom: 10 }}>
                <strong>{eachPetshop.puv_nombre}</strong>
                <Tag color={eachPetshop.radio ? 'green' : ''}>Cobertura</Tag>
                <Tag color={eachPetshop.productos ? 'green' : ''}>Producto</Tag>
                <Tag color={eachPetshop.tiene_capacidad ? 'green' : ''}>Capacidad</Tag>
                <Tag color={!eachPetshop.problemas_con_usuario ? 'green' : ''}>Rendimiento</Tag>
                <Tag color={eachPetshop.horarios ? 'green' : ''}>Horario</Tag>
                <Tag color={!eachPetshop.delego ? 'green' : ''}>Delegó</Tag>
              </div>
            )}

          </div>
        </Modal>


        <Icon alt="Movimientos de la compra" title="Movimientos de la compra" type="read" className='compraAccionBtn' onClick={this.showMovimientos} />
        <Modal
          title="Movimientos"
          style={{ minWidth: '62vw' }}
          visible={this.state.movimientos}
          onOk={this.hideMovimientos}
          onCancel={this.hideMovimientos}
        >
          <CompraMovimientos resultados={this.state.resultados} />
        </Modal>
      </div>
    )
  }
}

class Contactar extends React.Component {
  constructor(props) {
    super(props);
    console.log("Contactar constructor");
  }

  render() {
    return (
      <div>
        <a target="_BLANK" rel="noopener noreferrer" href={"https://api.whatsapp.com/send?phone=" + this.props.telcliente}>
          <Icon type="form" className='compraAccionBtn' />
        </a>
      </div>
    )
  }
}

class VerMapa extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mapShow: this.props.mapShow ? this.props.mapShow : false,
      latitud: this.props.latitud,
      longitud: this.props.longitud,
      loading: true,
    };

    //    this.showMapa = this.showMapa.bind(this);
    console.log('VerMapa constructor');
  }

  componentDidMount() {
    this.loading()
  }

  loading() {
    setTimeout(() => {
      this.setState({ loading: false })
    }, 2500);
  }

  componentWillReceiveProps(next_props) {
    if (next_props.mapShow !== this.state.mapShow || next_props.latitud !== this.state.latitud || next_props.longitud !== this.state.longitud) {
      this.setState({
        mapShow: next_props.mapShow,
        latitud: next_props.latitud,
        longitud: next_props.longitud,
        loading: true
      }, () => {
        this.loading()
      });
    }
  }

  /* Mapa */
  showDrawer = () => {
    this.setState({
      mapShow: true,
    });
  };

  onClose = () => {
    this.setState({
      mapShow: false,
    });
  };

  render() {
    return (
      <div>
        <Drawer
          width={'45%'}
          placement="right"
          closable={false}
          onClose={this.onClose}
          visible={this.state.mapShow}
          bodyStyle={{ height: "100%", overflow: "auto", margin: 0, padding: 0 }}
        >
          {!this.state.loading ?
            <Leaflet
              lat={this.state.latitud}
              lng={this.state.longitud}
              id={"mapCompraAcciones"}
            />
            :
            <Loading />
          }
          {/*
            <div className='GeoRadar'>
              <Leaflet
                lat={this.props.compra.cliente_latitud}
                lng={this.props.compra.cliente_longitud}
              />
              <AltaUsuariosMapa compra={this.props.compra} />
            </div>
          */}
        </Drawer>
      </div>
    )
  }
}

class VerMapaMultiple extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mapShow: this.props.mapShow ? this.props.mapShow : false,
      points: this.props.points,
      loading: true,
    };
  }

  componentDidMount() {
    this.loading()
  }

  loading() {
    setTimeout(() => {
      this.setState({ loading: false })
    }, 2500);
  }

  showDrawer = () => {
    this.setState({
      mapShow: true,
    });
  };

  onClose = () => {
    this.setState({
      mapShow: false,
    });
  };

  componentWillReceiveProps(next_props) {
    if (next_props.mapShow !== this.state.mapShow || next_props.points !== this.state.points) {
      this.setState({
        mapShow: next_props.mapShow,
        points: next_props.points,
        loading: true
      }, () => {
        this.loading()
      });
    }
  }

  render() {
    return (
      <Drawer
        width={'45%'}
        placement="right"
        closable={false}
        onClose={this.onClose}
        visible={this.state.mapShow}
        bodyStyle={{ height: "100%", overflow: "auto", margin: 0, padding: 0 }}
      >
        {!this.state.loading ?
          <LeafletMultiple
            points={this.state.points}
            id={"mapCompraAccionesMult"}
          />
          :
          <Loading />
        }
      </Drawer>
    )
  }
}

class VerCalendario extends React.Component {
  constructor(props) {
    super(props);
    this.state = { calendario: false };

    this.showCalendario = this.showCalendario.bind(this);
    console.log('VerCalendario constructor');
  }

  /* Movimientos */

  showCalendario = () => {
    this.setState({ calendario: true });
  }

  hideCalendario = () => {
    this.setState({ calendario: false });
  }

  render() {
    return (
      <div>
        <Icon type="calendar" className='compraAccionBtn' onClick={this.showCalendario} />
        <Modal
          title="Calendario"
          style={{ minWidth: '62vw' }}
          visible={this.state.calendario}
          onOk={this.hideCalendario}
          onCancel={this.hideCalendario}
        >
          <CompraMovimientos />
        </Modal>
      </div>
    )
  }
}

class AgregarComentario extends React.Component {
  constructor(props) {
    super(props);
    this.state = { leido: false, coment: false, nota: this.props.compra.compra_nota };

    this.showAgregaComentario = this.showAgregaComentario.bind(this);
    console.log('AgregarComentario constructor');
  }

  /* Movimientos */
  entendiNota = () => {
    this.setState({ leido: true });
  }

  showAgregaComentario = () => {
    this.setState({ coment: true });
  }

  hideAgregaComentario = () => {
    this.setState({ coment: false });
    this.setState({ leido: false });
  }
  changeTextArea = (evt) => {
    this.setState({ nota: evt.target.value });
  }

  guardaComentario = () => {
    Mispichoshelper.postCompraComentario({ compra_id: this.props.compra.compra_id, 'nota': this.state.nota }).then(response => {
      if (response.status) {
        this.hideAgregaComentario();
        this.props.refrescarCompra(this.props.compra.compra_id);
      }
    });
  }

  render() {

    return (
      <div>
        <Icon type="edit" className='compraAccionBtn' onClick={this.showAgregaComentario} />
        <Drawer
          width={'480px'}
          placement="right"
          closable={false}
          onClose={this.hideAgregaComentario}
          visible={this.state.coment}
        >
          <div className="mt-5">
            <textarea rows="8" maxLength="440" onChange={this.changeTextArea} className="campoTextarea" disabled={!this.state.leido} defaultValue={this.props.compra.compra_nota}>
            </textarea>
            <p>Recuerda, el Pet que tenga este pedido <span className='textosCeleste'>no recibira notificacion</span> sobre los cambios en la nota. Si es algo relevante por favor escribele para que lo sepa.</p>
            {this.state.leido ?
              <button className="btn btn-primary btn-block" onClick={this.guardaComentario}>
                Guardar notas
              </button>
              :
              <div>
                <button onClick={this.entendiNota} className="btn btn-outline-primary">Entendido</button>
              </div>
            }

          </div>
        </Drawer>

      </div>
    )
  }
}

class VerNotas extends React.Component {

  constructor(props) {
    super(props);
    this.state = { verDrawer: false, comments: [], loading: false };
    console.log('VerNotas constructor');
  }

  componentDidMount() {
    console.log('llega con order id', this.props.orderId);
    if (this.props.orderId) {
      this.setState({ loading: true })
      Mispichoshelper.getCSNotes({ order_id: this.props.orderId }).then(response => {
        this.setState({ comments: response.payload.data, loading: false });
      })
    }
  }

  /* Perfil del cliente */

  showDrawer = async () => {
    if (this.props.loadAsync) {
      await this.props.loadAsync();
    }
    this.setState({
      verDrawer: true,
    });
  };

  onClose = () => {

    this.setState({
      verDrawer: false,
    });
    if (this.props.closeDrawer) {
      this.props.closeDrawer();
    }
  };

  render() {
    //console.log('en notas', this.props.data);
    return (
      <div styles='margin-left: -20'>

        {this.props.orderId != undefined ? '' : <Icon type="message" className='compraAccionBtn' onClick={this.showDrawer} />}
        <Drawer
          width={'400px'}
          placement="right"
          closable={false}
          onClose={this.onClose}
          visible={this.props.orderId != undefined ? true : this.state.verDrawer}
        >
          <section className='CompraNotas'>

            {this.state.loading ? <Loadin /> : null}

            {this.state.comments.map((eachComment, iComment) => {
              return (
                <article key={iComment}>
                  <div>
                    <b>
                      <span>{eachComment.date + 'hs'}</span>
                      <h4>{eachComment.user}</h4>
                    </b>
                    <p>{eachComment.value}</p>
                  </div>
                </article>
              )
            })}
            {this.props.data != undefined && this.props.data.map((eachComment, iComment) => {
              return (
                <article key={iComment}>
                  <div>
                    <b>
                      <span>{eachComment.date + 'hs'}</span>
                      <h4>{eachComment.user}</h4>
                    </b>
                    <p>{eachComment.value}</p>
                  </div>
                </article>
              )
            })}
          </section>
        </Drawer>
      </div>
    )
  }
}

class VerTickets extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      verDrawer: false,
      data: []
    };
    console.log('VerTickets constructor');
  }

  componentDidMount() {
    this.getTickets()
  }

  getTickets() {

    if (!this.props.data) return;

    let params = {
      email: this.props.data.compra_clt_mail,
    }

    Zohohelper.getTickets(params).then((response) => {
      //console.log("RESPONSE: ", response)
      if (response && response.status) {
        response.payload.data && this.setState({ data: response.payload.data.data });
      }
    });
  }

  showDrawer = async () => {
    this.setState({ verDrawer: true });
  };

  onClose = () => {
    this.setState({ verDrawer: false });
  };

  render() {
    return (
      <div styles='margin-left: -20'>
        <Icon type="tags" className='compraAccionBtn' onClick={this.showDrawer} />
        <Drawer
          width={'400px'}
          placement="right"
          closable={false}
          onClose={this.onClose}
          visible={this.state.verDrawer}
        >
          <section className='CompraNotas'>
            {this.state.data.length > 0 ?
              this.state.data.map((element, index) => {
                return (
                  <a href={element.webUrl} target="_blank" rel="noopener noreferrer">
                    <article key={index}>
                      <Card>
                        <div>
                          <b>
                            <span>{moment(element.createdTime).format('MMMM Do YYYY, h:mm:ss a')}</span>
                          </b>
                          <p><strong>{"Ticket #" + element.ticketNumber}</strong><br />{element.subject}</p>
                          {element.assignee ? (
                            element.assignee.emailId ?
                              <Card.Meta
                                avatar={<Avatar src={element.assignee.photoURL} />}
                                title={"Asignado a: "}
                                description={element.assignee.emailId}
                              />
                              : <p>{"Sin asignar"}</p>
                          ) :
                            <p>{"Sin asignar"}</p>
                          }
                        </div>
                      </Card>
                    </article>
                  </a>
                  /*
                  <article key={index}>
                    <div>
                      <b>
                        <span>{moment(element.createdTime).format('MMMM Do YYYY, h:mm:ss a')}</span>
                        <h4>{element.assignee ? (element.assignee.emailId ? element.assignee.emailId : "Sin asignar") : "Sin asignar"}</h4>
                      </b>
                      <p>{element.subject}</p>
                    </div>
                  </article>
                  */
                )
              })
              :
              <div style={{ textAlign: "center" }}>
                <p>No tiene tickets registrados</p>
              </div>
            }
          </section>

        </Drawer>
      </div>
    )
  }
}


export { VerPerfilCliente, VerComprasAnt, VerMovimientos, Contactar, VerMapa, VerMapaMultiple, VerCalendario, AgregarComentario, TelefonoPet, VerNotas, VerTickets };