import React, { useEffect, useState } from "react";
import { Modal, Button, Checkbox } from 'antd';
import Mispichoshelper from "../../../utils/Mispichoshelper";
import ChangeDeliveryTime from "./ChangeDeliveryTime";
import moment from 'moment';
const confirm = Modal.confirm;


export default function ChangeAddress({ compra, domicilios, toggleModal, showCambiaDomicilio, refrescarCompra }) {
    const [dom, setDom] = useState(domicilios ? domicilios : []);
    const [domSelected, setDomSelected] = useState(0);
    const [addMispichos, setAddMispichos] = useState(0);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if (domicilios !== dom) {
            setDom(domicilios)
        }
        // eslint-disable-next-line
    }, [domicilios])


    const changeAddress = async () => {
        setLoading(true);
        const checkIfAreValidDeliveryDates = await Mispichoshelper.checkIfAreValidDeliveryDates({ incluye_mispichos: addMispichos, domicilio_id: domSelected, 'compra_id': compra.compra_id });
        if (checkIfAreValidDeliveryDates?.payload?.status) {
            Mispichoshelper.postCompraDomicilio({ incluye_mispichos: addMispichos, domicilio_id: domSelected, 'compra_id': compra.compra_id }).then(informaResponse);
        } else error(checkIfAreValidDeliveryDates)
        setLoading(false);
    }

    const confirmChange = () => {

        // Validamos dom seleccionado
        if (!domSelected) {
            Modal.error({
                title: "Importante!",
                content: "Por favor selecciona domicilio",
            });
            return
        }

        confirm({
            title: 'Estas a punto de cambiar el domicilio, esto puede causar que tambien se cambie el Petshop.',
            onOk: () => changeAddress(),
            okText: 'Si, cambiar domicilio',
            cancelText: 'No, volver'
        });
    }



    const informaResponse = (response) => {
        refrescarCompra(compra.compra_id);
        if (response.payload.status === "success") {
            success(response);
        } else {
            error(response);
        }
    }

    const success = (response) => {
        if (response.payload.change_time_zone) {
            Modal.confirm({
                title: response.payload.title,
                content: response.payload.message + ' - ' + response.payload.msg_change_time_zone,
                onCancel: () => hideCambiaDomi(),
                cancelText: 'Volver al panel',
                onOk: () => openChangeDeliveryTime(),
                okText: 'Reprogramar',
            });
        } else {
            Modal.success({
                title: response.payload.title,
                content: response.payload.message + ' - ' + response.payload.msg_change_time_zone,
                onOk: () => hideCambiaDomi(),
                okText: 'Volver al panel'
            });
        }
    }

    const error = (response) => {
        Modal.error({
            title: response.payload.title,
            onOk: () => hideCambiaDomi(),
            content: response.payload.message,
        });
    }

    const hideCambiaDomi = () => toggleModal('cambiaDomi', false)

    const openChangeDeliveryTime = () => {
        toggleModal('cambiaDomi', false);
        <ChangeDeliveryTime refrescarCompra={refrescarCompra} showReprogramar={toggleModal('reprogramarCompra', true)} hideReprogramar={toggleModal} compra={compra} />
    }

    return <>
        <Modal
            title="Cambiar Domicilio"
            style={{ minWidth: '45vw' }}
            visible={showCambiaDomicilio}
            onOk={confirmChange}
            onCancel={hideCambiaDomi}
            footer={[
                <Button className="btn-outline-danger" key="back" onClick={hideCambiaDomi} loading={loading}>
                    No, volver
                </Button>,
                <Button className="btn-primary" onClick={() => confirmChange()} loading={loading}>
                    Continuar
                </Button>,
            ]}
        >
            <select style={{ minWidth: "100%", marginBottom: ".5rem" }} onChange={(e) => setDomSelected(e.target.value)}>
                <option value={null}>Selecciona un domicilio</option>
                {dom && dom.length > 0 && dom.map((cada_domicilio, i) =>
                    <option key={i} value={cada_domicilio.dom_id}  >{cada_domicilio.domicilio} {compra.compra_domicilio_id === cada_domicilio.dom_id ? '(actual de la compra)' : ''}</option>
                )}
            </select>
            <Checkbox value='1' onClick={(e) => setAddMispichos(e.target.value)}>Incluir MisPichos como opción</Checkbox>

            <p style={{ marginTop: "3rem", marginBottom: "-1rem" }}>Si lo necesitas, agrega un <span className="textosLink" onClick={() => toggleModal('altaDomi', true)}>domicilio diferente</span>.<br />Solo recuerda buscarlo dentro de la lista, aparecerá listado al final.</p>
        </Modal>
    </>
}

