import React from "react";
import { withRouter } from "react-router-dom";



class CompraSplitBox extends React.Component {


    irSplit = (cada_split) => {
        this.props.history.push('/compras?compra_id=' + cada_split.compra_id);
        this.props.history.go()
    }

    render() {
        return (
            <section className='splitBox'>
                <h2>
                    Compras Split asociadas
                </h2>
                {this.props.compra.compras_split.map((cada_split, indexSplit) =>
                    <article key={indexSplit} className={this.props.compra.compra_id === cada_split.compra_id ? "itemOculto" : 'Sombra'} onClick={() => this.irSplit(cada_split)}>
                        <div className={cada_split.color_estado ? cada_split.color_estado : 'blanco'}>
                            <h3>
                                {'#' + cada_split.compra_id + ' | ' + cada_split.petshop}
                            </h3>
                            <p>
                                {"(" + cada_split.estado + ") " + cada_split.fecha_entrega + ' de ' + cada_split.tim_inicio + "hs a " + cada_split.tim_fin + "hs"}
                            </p>
                        </div>
                        {cada_split.productos.map((cada_ItemDelSplit, indexDelIndexSplit) =>
                            <b key={indexDelIndexSplit} className='compraResumenListaProductos'>
                                <img src={cada_ItemDelSplit.pro_foto} alt="Img Prod Split" />
                                <span>
                                    {cada_ItemDelSplit.producto + " x" + cada_ItemDelSplit.cor_cantidad}
                                </span>
                            </b>)}
                    </article>
                )}
            </section>
        )
    }
}
export default withRouter(CompraSplitBox);