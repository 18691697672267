import React, { useEffect, useState } from "react";
import { Alert, Modal } from 'antd';
import Mispichoshelper from "../../../utils/Mispichoshelper";


const confirm = Modal.confirm;


export default function Refund({ compra, hideReembolso, showReembolso, refrescarCompra }) {

    const [motivSelect, setMotiv] = useState()
    const [observaciones, setObs] = useState("")
    const [motivos, setMotivos] = useState([])

    useEffect(() => {
        Mispichoshelper.getMotivosCompraCancelaciones().then(response => setMotivos(response.payload));
        // eslint-disable-next-line
    }, [])

    const cancelaCompra = () => {
        var params = { observaciones: observaciones, motivo_id: motivSelect, 'compra_id': compra.compra_id };
        Mispichoshelper.postCancelaCompra(params).then(informaResponse);
    }

    const informaResponse = (response) => {
        refrescarCompra(compra.compra_id);
        if (response && response.status) {
            confirm({
                title: response.payload.message,
                content: 'Datos guardados satisfactoriamente',
                onOk: () => hideReembolso('reembolso', false)
            });
        } else {
            Modal.error({
                title: "Ocurrio un Error",
                content: 'No pudimos cancelar la compra',
            });
        }
    }

    const handleOk = () => {
        if (observaciones !== "") {
            confirm({
                title: 'Confirma la cancelación de la compra?',
                content: '',
                onOk: () => cancelaCompra()
            });
        } else {
            Modal.error({
                title: "Por favor completa el campo de observaciones",
            });
        }
    }

    return (
        <Modal
            title="Advertencia"
            visible={showReembolso}
            onOk={handleOk}
            onCancel={() => hideReembolso('reembolso', false)}
        >
            <Alert
                message="Se Cancelara esta compra."
                description={compra.compra_medio_pago === 'tarjeta' ? "Se generera un reembolso total de $" + compra.compra_total + " sobre la compra del Cliente " + compra.compra_cliente + ", el mismo se vera reflejado es su proximo resumen." : "La compra fue realizada en efectivo, no hay nada más que hacer Rick."}
                type="warning"
            />
            <select
                className='w100 MT1 '
                onChange={(evt) => setMotiv(parseInt(evt.target.value))}
                placeholder="Elige un motivo"
                required
            >
                <option value=''>Seleccionar</option>
                {motivos.map((item, idx) => (
                    <option key={idx} value={item.id}>{item.nombre}</option>
                ))}
            </select>

            <textarea rows="4" maxLength="140" onChange={(evt) => setObs(evt.target.value)} className="MT1">

            </textarea>
        </Modal>
    )
}

