import React, { useEffect, useState } from "react";
import { Modal } from 'antd';
import Mispichoshelper from "../../utils/Mispichoshelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeadset, faUser } from "@fortawesome/free-solid-svg-icons";
import Loadin from "../componentsUI/Loading";

const confirm = Modal.confirm;

function UsuariosPerfil({ clienteId }) {
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Mispichoshelper.getUsuarioPerfil({ usuarioId: clienteId }).then((data) => {
      setUserData(data.payload[0])
      setLoading(false);
    });
    // eslint-disable-next-line
  }, [])


  const cambiaDatosPerfil = () => {
    Mispichoshelper.postPerfil({
      'usuario_id': userData.id, 'cod_pais': userData.cod_pais,
      'cod_area': userData.cod_area, 'telefono': userData.nro_telefono,
      'nombre_cl': userData.nombre, 'apellido_cl': userData.apellido
    }).then(informaResponse);
  }

  const informaResponse = (response) => {
    confirm({
      title: response.payload.message,
      content: 'Datos guardados satisfactoriamente',
      onOk: () => window.location.reload(),
      onCancel: () => window.location.reload()
    });
  }


  if (loading) {
    return <Loadin fullHeight={true} />
  } else {
    return (
      <article className="UsuarioPerfil">
        <div>
          <FontAwesomeIcon icon={faUser} size={'3x'} style={{ marginRight: 10, marginBottom: 10 }} />
          <h5>
            {userData.cliente}
            <br />
            <small>{"#" + userData.id}</small>
          </h5>
          <span>
            <button onClick={() => window.open('https://soporte.mispichos.com/support/mispichos/ShowHomePage.do#Contacts/search/CurDep/' + userData.email)} >
              <FontAwesomeIcon icon={faHeadset} />
            </button>
          </span>
        </div>
        <form onSubmit={(evt) => evt.preventDefault()}>
          <fieldset>
            <label>Nombre:</label><br />
            <input type="text" onChange={(evt) => setUserData({ ...userData, 'nombre': evt.target.value })} value={userData.nombre} style={{ width: '10rem' }} />
          </fieldset>
          <fieldset>
            <label>Apellido:</label><br />
            <input type="text" onChange={(evt) => setUserData({ ...userData, 'apellido': evt.target.value })} value={userData.apellido} style={{ width: '10rem' }} />
          </fieldset>
          <fieldset>
            <label>Telefono:</label><br />
            <div>
              <input onChange={(evt) => setUserData({ ...userData, 'cod_pais': evt.target.value })} value={userData.cod_pais} maxLength={5} />
              <input onChange={(evt) => setUserData({ ...userData, 'cod_area': evt.target.value })} value={userData.cod_area} maxLength={5} />
              <input onChange={(evt) => setUserData({ ...userData, 'nro_telefono': evt.target.value })} value={userData.nro_telefono} style={{ width: '7rem' }} />
            </div>
          </fieldset>
          <fieldset>
            <label>Email:</label><br />
            <input type="text" value={userData.email} disabled="true" style={{ width: '15rem' }} />
          </fieldset>
          <fieldset>
            <button style={{ marginLeft: "25%" }} type='submit' onClick={cambiaDatosPerfil}>Guardar</button>
          </fieldset>
        </form>
      </article>
    );
  }
}

export default UsuariosPerfil;