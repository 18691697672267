import { useState, useEffect } from "react";
import { DatePicker, ConfigProvider, Button, Table, Select } from 'antd';
import es_ES from 'antd/es/locale-provider/es_ES';
import moment, { Moment } from 'moment';
import Mispichoshelper from "../utils/Mispichoshelper";
import { Download } from "../components/dashboard-elements/TablaPedidos";
const { RangePicker } = DatePicker;
const { Option } = Select;

// al momento de usa la variable de fechas para enviar data, formatearla ---> .format('YYYY-MM-DD');

const columns = [
    {
        title: 'Cupon',
        dataIndex: 'nombre_cupon',
        key: 'nombre_cupon',
        width: 100,
    },
    {
        title: 'Nro pedido',
        dataIndex: 'com_id',
        key: 'com_id',
        width: 100,
    },
    {
        title: 'Fecha creación',
        dataIndex: 'fecha_creacion',
        key: 'fecha_creacion',
        width: 100,
    }
];

interface Params {
    limit: number;
    page: number;
    desde: string;
    hasta: string;
    puv_id: number | null;
    dis_id: number[] | null;
}
interface Order {
    com_id: number,
    fecha_creacion: string,
}

interface Coupons {
    dis_id: number,
    dis_code: string,
}

interface Petshops {
    id: number,
    nombre: string,
}

export const ReportTS = () => {
    const [filterFrom, setFilterFrom] = useState<Moment>(moment().subtract(90, 'd'));
    const [filterTo, setFilterTo] = useState<Moment>(moment());
    const [loading, setLoading] = useState<boolean>(false);
    const [pagination, setPagination] = useState<number>(50);
    const [position, setPosition] = useState<"bottom" | "top" | "both" | undefined>("bottom");
    const [check, setCheck] = useState<boolean>(false);
    const [orders, setOrders] = useState<Order[]>([]);
    const [cant_total_rows, setCant_total_rows] = useState<number>(0);
    const [page, setPage] = useState<number>(0)
    const [petshop, setPet] = useState<Petshops[]>([]);
    const [petshopSelected, setPetshopSelected] = useState<number | null>(null);
    const [cupones, setCupones] = useState<Coupons[]>([]);
    const [cuponSelected, setCuponSelected] = useState<number[] | null>([]);


    useEffect(() => {
        if (petshop.length === 0) {
            getPetshop();
        }
        if (cupones.length === 0) {
            getAllCupones();
        }

        getData();
    }, [pagination]);

    const getPetshop = async () => {
        await Mispichoshelper.getPetshopList({ full: 1 }).then((response) => setPet(response.payload));
    }

    const getAllCupones = async () => {
        await Mispichoshelper.getAllCuponesList().then((response) => setCupones(response.payload.rows));
    }


    const getData = (page?: number | null) => {
        setLoading(true)
        let auxPage = page ? page - 1 : 0;

        let params: Params = {
            limit: pagination,
            page: auxPage,
            desde: filterFrom.format('YYYY-MM-DD'),
            hasta: filterTo.format('YYYY-MM-DD'),
            puv_id: petshopSelected,
            dis_id: cuponSelected,
        }

        Mispichoshelper.getReportCupones(params).then((response) => {
            if (response.status) {
                setLoading(false)
                setOrders(response.payload.rows)
                setCant_total_rows(response.payload.cant_total_rows)
                setPage(response.payload.page)
            } else {
                setLoading(false)
                alert("hubo un problema al conectar con el servidor")
            }
        });
    }

    const onChangeDate = (date: any) => {
        setFilterFrom(date[0]);
        setFilterTo(date[1]);
    };

    const getAllFiles = () => {
        if (!check) {
            setCheck(true);
            setPagination(3000);
            setPosition('top');
        } else {
            setCheck(false);
            setPagination(50);
            setPosition('bottom');
        }
    }

    const downloadToExcel = (cols: any, orders: any) => {
        return (
            <div>
                <label>
                    <input type="checkbox" checked={check} onChange={() => { getAllFiles() }} /> Descargar todos los resultados (hasta 3.000 por página)
                </label>
                <Download cols={cols} orders={orders} />
            </div>)
    }

    return (
        <div className='GlobalStructure'>
            <h3>Hola Mundo en TS</h3>
            <section className='Card Sombra'>
                <div className="RowCenter SpaceBetween">
                    <div className="RowCenter">
                        <div style={{ width: '250px', paddingRight: "1%" }} >
                            <h5>Periodo a Evaluar</h5>
                            <ConfigProvider locale={es_ES}>
                                <RangePicker
                                    onChange={(date) => onChangeDate(date)}
                                    defaultValue={[filterFrom, filterTo]}
                                />
                            </ConfigProvider>
                        </div>
                        <div style={{ width: '250px', paddingRight: "1%" }}>
                            <h5>Petshop</h5>
                            <Select
                                showSearch
                                filterOption={(inputValue, option) =>
                                    (option.props.children as string)
                                        .toLowerCase()
                                        .indexOf(inputValue.toLowerCase()) >= 0
                                }
                                style={{ width: '100%' }}
                                placeholder="Selecciona un petshop"
                                onChange={(value: number) => setPetshopSelected(value)}
                            >
                                <Option value='todos'>Todos</Option>
                                {petshop.map((cada_pet, iPet) => <Option key={iPet} value={cada_pet.id}>{cada_pet.nombre}</Option>)}
                            </Select>
                        </div>
                        <div style={{ width: '250px', paddingRight: "1%" }}>
                            <h5>Cupones</h5>
                            <Select
                                mode="multiple"
                                showSearch
                                filterOption={(inputValue, option) =>
                                    (option.props.children as string)
                                        .toLowerCase()
                                        .indexOf(inputValue.toLowerCase()) >= 0
                                }
                                style={{ width: '100%' }}
                                placeholder="Selecciona un cupon"
                                onChange={(selectedValues: number[]) => setCuponSelected(selectedValues)}
                            >
                                <Option value='todos'>Todos</Option>
                                {cupones.map((cada_cupon, iCup) => <Option value={cada_cupon.dis_id} key={iCup}>{cada_cupon.dis_code}</Option>)}
                            </Select>
                        </div>
                    </div>
                    <div style={{ width: '250px' }}>
                        <Button loading={loading} type="primary" style={{ marginTop: "24px", width: "100%" }} onClick={() => getData()}>
                            Filtrar
                        </Button>
                    </div>
                </div>
            </section>
            <section className='Card'>
                <div className='RowCenter SpaceBetween'>
                    <span>{"Filas " + cant_total_rows}</span>
                    {downloadToExcel(columns, orders)}
                </div>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={orders}
                    pagination={{
                        position: position,
                        pageSize: pagination,
                        current: page,
                        total: cant_total_rows,
                        onChange: (page) => getData(page)
                    }}
                />
            </section>
        </div>
    );
};