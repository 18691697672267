import React, { useEffect, useState } from "react";
import { Drawer } from 'antd'
import Compras from "../views/Compras";
import { FaExternalLinkAlt } from "react-icons/fa";

const DrawerContent = ({ ...props }) => {

    const [order, setOrder] = useState(props.order)

    useEffect(() => {
        if (props.order) setOrder(props.order)
    }, [props.order]);

    const onCloseDrawer = () => {
        setOrder(null)
        props.setOrder && props.setOrder(null)
    };

    const Title = () => {
        return (
            <a href={"/compras?compra_id=" + order.order_id} target="_blank" onClick={() => onCloseDrawer()}>
                <p>{"Compra #" + order.order_id + " "}
                    <FaExternalLinkAlt />
                </p>
            </a>
        )
    }

    return (
        <Drawer
            title={<Title />}
            width={'70%'}
            onClose={onCloseDrawer}
            visible={order}
        >
            <Compras com_id={order.order_id} />
        </Drawer>
    )
}

export default DrawerContent;