import React, { useEffect, useState } from "react";
import Mispichoshelper from "../../../utils/Mispichoshelper";
import MassiveConsultation from "./MassiveConsultation";



function FixRedOrder({ compra }) {

    const [reprograma, setReprograma] = useState(false)
    const [consulta, setConsulta] = useState(true)
    const [resultadosStock, setStock] = useState([])
    const [resultadosEntrega, setEntrega] = useState([])


    useEffect(() => {
        var stock = [], entrega = [];
        Mispichoshelper.getConsultaEntrega({ compra_id: compra.compra_id }).then(response => entrega = response.payload);
        Mispichoshelper.getConsultaStock({ compra_id: compra.compra_id }).then(response => stock = response.payload);
        setStock(stock)
        setEntrega(entrega)
        // eslint-disable-next-line
    }, []);

    const activaPanel = (panel) => {
        if (panel === 'buscar') {
            setReprograma(false)
            setConsulta(false)
        } else if (panel === 'horario') {
            setReprograma(true)
            setConsulta(false)
        } else {
            setReprograma(false)
            setConsulta(true)
        }
    }

    return (
        <div className="SolucionarPedidos">
            <h3>Evita cancelar este pedido, analiza las opciones para ayudar al cliente.</h3>
            <article className="GridDeTres">
                <div className={consulta ? "TarjetaRoja" : "TarjetaRoja TarjetaApagada"}>
                    <h5>Consulta masiva de stock</h5>
                    {consulta ?
                        <MassiveConsultation resultados={resultadosStock} />
                        : null}
                    <button disabled={!consulta} onClick={() => activaPanel('horario')}>Cliente no Acepta </button>
                </div>
                <div className={reprograma ? "TarjetaRoja" : "TarjetaRoja TarjetaApagada"}>
                    <h5>
                        Ofrece reprogramar su horario de entrega
                        </h5>
                    <ul className="ListaHorarios">
                        {resultadosEntrega.map((cada_fecha, index) =>
                            <li key={index} className="ListaHorariosItem">
                                <strong>{cada_fecha.nombre.toUpperCase() + ' ' + cada_fecha.numero_dia}</strong>
                                <ul className="activa">
                                    {cada_fecha.horarios.map((cada_horario, ind) => <li key={ind} >De {cada_horario.tim_inicio} {cada_horario.tim_fin} hs</li>)}
                                </ul>
                            </li>
                        )}
                    </ul>
                    <button disabled={!reprograma} onClick={() => activaPanel('buscar')}>Cliente no Acepta </button>
                </div>


            </article>
        </div>
    )

}

export default FixRedOrder;
