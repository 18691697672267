import React from 'react'
import { AiOutlineLoading } from "react-icons/ai";
import './Button.css';

export default function Button({ type, onPress, loading, labelText, icon, disabled = false }) {


    return (
        <button className={'MegaButton ' + `${type}`} onClick={() => onPress()} disabled={disabled} style={disabled ? { backgroundColor: 'grey', color: 'white', border: '1px solid grey' } : {}}>
            {icon && !loading ? icon : null}
            {loading ? <AiOutlineLoading className='loading' /> : labelText}
        </button>
    )
}