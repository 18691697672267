import React, { useEffect, useState } from "react";
import { Modal, Button, Input, Select } from 'antd';
import TextArea from "antd/lib/input/TextArea";
import Mispichoshelper from "../../../utils/Mispichoshelper";
const confirm = Modal.confirm;
const Option = Select.Option;


export default function NewUserCheckingAccount({ compra, usuarioId, showRefer, toggleModal }) {
  const [monto, setMonto] = useState(0);
  const [type, setType] = useState('');
  const [subType, setSubType] = useState('');
  const [responsibility, setResponsibility] = useState("");
  const [reason, setReason] = useState("");
  const [petshops, setPetshops] = useState([]);
  const [petshopSelected, setPetSelect] = useState('');
  const [disabledPet, setDisabledPet] = useState(true);
  const [search, setSearch] = useState([]);
  const [loading, setLoading] = useState(false);
  let responsibility_petshop = "responsabilidad petshop"
  let ingreso = [
    { value: 'Motivo', nombre: "Motivo" },
    { value: "diferencia precio", nombre: "Diferencia de precio" },
    { value: "bonificacion promocion", nombre: "Bonificación por promoción" },
    { value: "ajuste saldo", nombre: "Ajuste Saldo" },
    { value: "oliver envios credito", nombre: "Oliver envíos" },
    { value: "nota credito", nombre: "Nota Crédito" },
    { value: "no puede concretar venta", nombre: "Bonificación por no poder concretar venta asignada" },
    { value: "cupon mispichos", nombre: "Cupón a cargo de mispichos" },
  ]
  let egreso = [
    { value: 'Motivo', nombre: "Motivo" },
    { value: "contracargo de mercado pago", nombre: "Contracargo mercadopago" },
    { value: "ajuste iva", nombre: "Ajuste IVA" },
    { value: "nota debito", nombre: "Nota Débito" },
    { value: "oliver envios", nombre: "Oliver envíos" },
    { value: "mala experiencia comprador", nombre: "Mala experiencia comprador" },
    { value: "pedido no entregado satisfactoriamente", nombre: "Pedido no entregado satisfactoriamente" },

  ]

  useEffect(() => {
    Mispichoshelper.getPetshopList({ full: 1, offset: 0, order: 'DESC' }).then(response => {
      setPetshops(response.payload);
    });
  }, [])
  const SaveCheckingAccount = async () => {
    setLoading(true);
    let validate = validateForm();
    if (validate.status) {

      let params = {
        monto: monto,
        type: type,
        reason: reason,
        client_id: usuarioId,
        responsibility: responsibility,
        compra: compra.compra_id,
        pro_id: petshopSelected,
        subType: subType
      }

      Mispichoshelper.newBalance(params).then(response => {
        if (response.status == false) {
          error(response.payload);
          setLoading(false)
        } else {
          success(response.payload);
          setLoading(false)
        }
      })

    } else {
      error(validate);
      setLoading(false)
    }
  }

  const hideModal = () => toggleModal('referModal', false)
  const success = (response) => {

    Modal.success({
      title: 'Exito',
      content: response.message,
      onOk: () => hideModal(),
      okText: 'Volver al panel'
    });
  }
  const validateForm = () => {
    if (type == "") {
      return {
        'status': false,
        'message': 'Seleccionar tipo de movimiento'
      }
    }
    if (responsibility == "") {
      return {
        'status': false,
        'message': 'Seleccionar responsabilidad'
      }
    }
    if (Number.isNaN(parseFloat(monto)) || parseFloat(monto) < 1) {
      return {
        'status': false,
        'message': 'El monto debe ser mayor a cero'
      }

    }
    if (reason.trim() == '') {
      return {
        'status': false,
        'message': 'Descripción es requerida'
      }
    }

    if (responsibility == responsibility_petshop && petshopSelected == 0) {
      return {
        'status': false,
        'message': 'Seleccionar petshops'
      }
    }
    if (responsibility == responsibility_petshop && subType == '') {
      return {
        'status': false,
        'message': 'Seleccionar motivo'
      }
    }

    return {
      'status': true,
    }
  }

  const error = (response) => {

    Modal.error({
      title: 'Error',
      content: response.message,
    });
  }
  return (
    <Modal
      title="Nuevo movimiento cuenta corriente cliente"
      visible={showRefer}
      onCancel={() => hideModal()}
      cancelButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button key="submit" type="primary" loading={loading} onClick={() => SaveCheckingAccount()}>
          OK
        </Button>,
      ]}
    >
      <div>
        <label>Tipo de movimiento</label>
        <select
          className='w100 MT1 '
          onChange={(evt) => setType(evt.target.value)}
          placeholder="Elige tipo de movimiento"
          required
          value={type}
        >
          <option value={""}>Seleccionar</option>
          <option value={"+"}>Ingreso</option>
          <option value={"-"}>Egreso</option>

        </select>
        <br />

      </div>
      <div>
        <label>Responsabilidad</label>
        <select
          className='w100 MT1 '
          onChange={(evt) => {
            if (evt.target.value === responsibility_petshop) {
              setDisabledPet(false)
            } else {
              setDisabledPet(true)
            }
            setResponsibility(evt.target.value)
          }}
          placeholder="Elige responsabilidad"
          required
          value={responsibility}                >
          <option value={""}>Seleccionar</option>
          <option value={"responsabilidad interna"}>Responsabilidad interna</option>
          <option value={"responsabilidad petshop"}>Responsabilidad petshop</option>

        </select>
        <br />

      </div>
      <div>
        <fieldset className='MT1'>
          <label>Petshops</label>
          <Select
            showSearch
            disabled={disabledPet}
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: "100%" }}
            defaultValue="" onChange={(e) => setPetSelect(e)}>
            <Option value={0} >Seleccionar</Option>
            {petshops.map((eachPetshop, i) => {
              return <Option key={i} value={eachPetshop.pro_id}>{eachPetshop.nombre}</Option>
            })}
          </Select>
          <br />
        </fieldset>
      </div>

      <div>
        <fieldset className='MT1'>
          <label>Motivo</label>


          <Select className='btn-block MB1'
            showSearch
            disabled={disabledPet}
            onChange={(e) => setSubType(e)}>

            {type === "-" &&
              ingreso.map(item => <Option value={item.value}>{item.nombre}</Option>)
            }
            {type === "+" &&
              egreso.map(item => <Option value={item.value}>{item.nombre}</Option>)
            }
          </Select>

          <br />
        </fieldset>
      </div>




      <div>
        <fieldset className='MT1'>
          <label>Monto</label>
          <Input
            id="monto"
            className='w100 MT1 '
            placeholder={`Ingresa monto`}
            value={monto}
            type={'number'}
            onChange={e => setMonto(e.target.value)}
            style={{ width: 'auto', marginBottom: 8, display: 'block' }}
            required
          />
          <br />
        </fieldset>
      </div>
      <div>
        <label>Descripción </label>
        <TextArea
          className='campoTextarea'
          placeholder="Detalles del movimiento"
          autosize={{ minRows: 6, maxRows: 6 }}
          value={reason}
          onChange={(evt) => setReason(evt.target.value)}
          required
        />

      </div>

    </Modal >
  )

}

