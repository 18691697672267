import React, { useEffect, useState } from "react";
import { Steps } from 'antd';
import { TreeSelect } from 'antd';
import { FiCalendar, FiUser, FiEdit, FiEye, FiEdit3 } from "react-icons/fi";
import Button from '../../../components/componentsUI/Button';
import { Modal } from 'antd';
import AssignmentProcess from "../../../componentsAssignment/AssignmentProcess";
import { PurchaseEditHistory} from '../../../components/compra/PurchaseEditHistory';
import { Col, Row } from 'shards-react';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
import InnerImageZoom from 'react-inner-image-zoom'
const Step = Steps.Step;

const SHOW_PARENT = TreeSelect.SHOW_PARENT;

const treeData = [{
  title: 'Delegado',
  value: 'delega compra',
  key: 'delega compra',
},
{
  title: 'Delegado eliminado',
  value: 'delega compra eliminado',
  key: 'delega compra eliminado',
},
{
  title: 'Cambio de estado',
  value: 'estado compra',
  key: 'estado compra',
},
{
  title: 'Reprogramado',
  value: 'reprograma compra',
  key: 'reprograma compra',
},
{
  title: 'Edicion de Producto',
  value: 'edita compra',
  key: 'edita compra',
},
{
  title: 'Intervención',
  value: 'intervenciones',
  key: 'intervenciones',
},
{
  title: 'Cancelación',
  value: 'cancela compra',
  key: 'cancela compra',
},

];


function Movements({ resultados }) {

  // console.log('RESULTS', resultados)
  const [results, setResults] = useState(resultados)
  const [digitales, setDigitales] = useState(false)
  const [imgDigitales, setImgDigitales] = useState()
  const [filter, setFilter] = useState([])

  /* --- :D Paso el id del movimiento al assigmentProcess --- */
  const [visible, setVisible] = useState(false)
  const [visiblePurchaseIssueHistory, setVisiblePurchaseIssueHistory] = useState(false)
  const [visibleDigitals, setVisibleDigitals] = useState(false)
  const [digitals, setDigitals] = useState([])
  const [movementId, setMovementId] = useState(null)
  const [purchaseEdit, setPurchaseEdit] = useState(null)


  useEffect(() => {
    filtraResultados();
    // eslint-disable-next-line
  }, [filter]);


  const filtraResultados = () => {
    var filteredItems = filter.length <= 0 ? resultados : [];
    results.map((cada_resultado) => {
      filter.map(cada_filtro => {
        if (cada_resultado.tipo === cada_filtro) {
          filteredItems.push(cada_resultado);
        }
        return filteredItems
      })
      return filteredItems
    })
    setResults(filteredItems);
  }

  // Modal Digitales
  const showDigitales = (img) => {
    setDigitales(!digitales);
    setImgDigitales(img);
  }
  const showModalDigitales = (data) => {
    setVisibleDigitals(true);
    setDigitals(data);
  }

  if (digitales && imgDigitales) {
    return (
      <div style={{ textAlign: "center" }}>
        <Button style={{ width: "20%", marginBottom: 20 }} onPress={() => setDigitales(false)} labelText='Volver' />
        <img alt={imgDigitales} style={{ width: "100%", height: "100%" }} src={imgDigitales} />
      </div>
    )
  } else {

    return (
      <section className='Timeline-List'>
        {results.map((item, i) => {
          var monto = 0

            let data =item.data ? JSON.parse(item.data) : item.data

            if (data?.amount_credit) {
              monto = data.amount_credit
            }

          let url_digital = false;
          if (item.content !== null) {
            if (item.content.includes("consulta_telefono_")) {
              url_digital = true;
            } else if (item.content.includes("problema_cliente_")) {
              url_digital = true;
            }
          }

          let titulo = item.titulo === "carga digital" ? "Adjunta digital" : item.titulo + (monto > 0 ? ' ($' + monto + ')' : '');
          let descripcion = (item.titulo === "carga digital" || item.titulo === "Valida domicilio con foto" || url_digital) ? <div><button style={{ border: 'none', background: 'none' }} onClick={() => showDigitales(`${item.url_digital}${(item.content.split('/'))[1].trim()}`)} >Ver digital</button></div> : null;
          let format = item.content ? item.content.split(' / ') : null;
          return (
            <article className='Timeline-Item Card RemueveSombra Bordeadito'>
              <div className="CardHead SpaceBetween">
                <Row className='align-items-center'>
                  <Col md={7}>
                    <h6 style={{ fontWeight: 600 }}>{item.tipo === 'edicion productos' ? item.tipo + ' - ' + titulo : titulo === 'logs' ? titulo + ' - ' + item.tipo : titulo}</h6>
                  </Col>
                  <Col md={5} className='d-flex justify-content-end'>
                    {item.tipo === "asignacion petshops" && item.mov_id && item.data &&
                      <>
                        <Button type={'outline-primary'} labelText={<FiEye />} onPress={() => { setMovementId(item.mov_id); setVisible(true) }} />
                      </>
                    }
                    {item.tipo === "edicion productos" && item.mov_id && item.data &&
                      <>
                        <Button type={'outline-primary'} labelText={<FiEye />} onPress={() => { setPurchaseEdit(JSON.parse(item.data)); setVisiblePurchaseIssueHistory(true) }} />
                      </>
                    }
                    {item.titulo === "Es orden de solución" && item.mov_id && item.data &&
                      <Button type={'outline-primary'} labelText={'Ver pedido origen'} onPress={() => { window.location.href = '/compras?compra_id=' + JSON.parse(item.data).compra_id }} />
                    }
                    {item.titulo ==="Crea orden de solución" && item.mov_id && item.data &&
                      <Button type={'outline-primary'} style={{ fontWeight: 700 }} labelText={'Ver orden de solución'} onPress={() => { window.location.href = '/compras?compra_id=' + JSON.parse(item.data).new_order_id }} />
                    }
                    {data?.digitals &&
                      <Button type={'outline-primary'} labelText={<FiEye /> } onPress={() => showModalDigitales(data.digitals)} />
                    }
                  </Col>
                </Row>
              </div>
              <p>
                <div className="RowCenter">
                  <FiCalendar className='MR1' /> <p>{item.fecha} hr</p>
                </div>
                <div className="RowCenter">
                  <FiUser className='MR1' /> <p>{format ? format[0] : 'sin definir'}</p>
                </div>
                <FiEdit /> <b>{format ? format[1] : 'sin datos'}</b>
                {format && format[2] &&
                  <><br /><FiEdit3 />  <b>Observaciones adicionales: {format[2]}</b> </>
                }
                {descripcion}
              </p>
            </article>
          )
        })}

        <Modal
          title={"🤓 Detalles del movimiento " + movementId}
          centered
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          width={1200}
        >
          <AssignmentProcess movementId={movementId} />
        </Modal>

        <Modal
          title={"🤓 Edición de compra "}
          centered
          visible={visiblePurchaseIssueHistory}
          onOk={() => setVisiblePurchaseIssueHistory(false)}
          onCancel={() => setVisiblePurchaseIssueHistory(false)}
          width={1200}
        >
          <PurchaseEditHistory purchaseEdit={purchaseEdit} />
        </Modal>

        <Modal
            title={"Digitales"}
            centered
            visible={visibleDigitals}
            onOk={() => setVisibleDigitals(false)}
            onCancel={() => setVisibleDigitals(false)}
            width={1200}
        >
            <div>
                {digitals.length>0 && digitals.map((imgR, ind) =>
                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '8px' }}>
                        <img style={{ maxHeight: '500', maxWidth: '100%', marginLeft: 5 }} key={ind} alt={imgR} src={imgR} />
                    </div>
                )}
            </div>
          </Modal>
      </section>
    );
  }
}

export default Movements;
