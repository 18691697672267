
const storage_orders_pined = '@storage:ordersPined';

const OrdersPined = {
    saveItem: function (items) {
        localStorage.setItem(storage_orders_pined, JSON.stringify(items));
    },

    getItems: function () {
        return JSON.parse(localStorage.getItem(storage_orders_pined))
    },

    remove: function () {
        localStorage.removeItem(storage_orders_pined);
    },

    addItem: function (item) {
        var storage = localStorage.getItem(storage_orders_pined);
        var aux = null;
        if (storage) {
            aux = JSON.parse(storage);
            const index = this.findItemIndex(item, aux)
            if (index !== null) {
                aux.splice(index, 1);
            }
            aux.push(item);

        } else {
            aux = [];
            aux.push(item);
        }

        this.saveItem(aux);
    },
    updateItem: function (updatedItem) {
        var storage = localStorage.getItem(storage_orders_pined);
        var aux = null;
        if (storage) {
            aux = JSON.parse(storage);
            const index = this.findItemIndex(updatedItem, aux);
            if (index !== null) {
                aux[index] = updatedItem; // Actualiza el elemento en la posición index
            }
        }

        this.saveItem(aux);
    },

    removeItem: function (item) {
        var storage = localStorage.getItem(storage_orders_pined);
        var aux = null;
        if (storage) {
            aux = JSON.parse(storage);
            const index = this.findItemIndex(item, aux)
            if (index !== null) {
                aux.splice(index, 1);
            }
        }

        this.saveItem(aux);
    },

    findItemIndex: function (item, storage) {
        var indice = null;
        storage.map((eachItem, index) => {
            if (eachItem.order_id === item.order_id) {
                indice = index;
            }
            return indice
        })
        return indice;
    },
}

export default OrdersPined;