import React, { useEffect, useState } from "react";
import { Modal, Select, Input, Button, Tooltip } from "antd";
import { FaFileUpload, FaTrash, FaUserCheck } from 'react-icons/fa';
import { GrView, GrUpdate } from 'react-icons/gr';
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md';
import { AiOutlinePlus } from 'react-icons/ai';
// import Button from "../componentsUI/Button";
import Mispichoshelper from "../../utils/Mispichoshelper";
import { Col, FormRadio, Row } from "shards-react";
import Loading from "../componentsUI/Loading";
import ChangeDeliveryTime from "../../componentsCustomerService/Order/Actions/ChangeDeliveryTime";
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
import InnerImageZoom from 'react-inner-image-zoom'
import { InfoMessage } from "../../utils/GeneralComponents";
import ImageUploading from 'react-images-uploading';
const { Option } = Select;

const confirm = Modal.confirm;

const PROBLEM_TYPE = { cliente_producto_confundido: 1, petshop_producto_confundido: 2, petshop_pedido_incompleto: 3, petshop_producto_vencido: 4, petshop_producto_roto: 5, petshop_producto_mal_estado: 6, petshop_producto_no_llego: 7 }
const components = {
    SELECT_TYPE: { 'wizard': { component: OrderIncompleteType }, 'prev': null },
    RECEIPT: { 'wizard': { component: OrderIncompleteReceipt }, 'prev': 'SELECT_TYPE', 'prevCash': 'CLIENT_PAY_ORDER', 'prevPetshop': 'CLIENT_MUST_UPLOAD_PHOTO' },
    RECEIPT_SUCCESS: { component: OrderIncompleteReceiptSuccess },
    MISSING_PRODUCTS: { 'wizard': { component: OrderIncompleteMissingProducts }, 'prev': 'RECEIPT', 'prevClient': 'CLIENT_MUST_RE_ORDER' },
    SOLUTION: { 'wizard': { component: OrderIncompleteSolutions }, 'prev': 'MISSING_PRODUCTS', 'prevPetshop': 'RETURN_ORDER_CUSTOM' },
    SOLUTION_COMPLETE_ORDER: { 'wizard': { component: OrderIncompleteSolutionCompleteOrder }, 'prev': 'SOLUTION' },
    SOLUTION_PARTIAL_REFUND: { 'wizard': { component: OrderIncompleteSolutionPartialRefund }, 'prev': 'SOLUTION' },
    SOLUTION_DELIVERIED: { component: OrderSolutionDeliveried, name: "OrderSolutionDelivered"  },
    RETURN_ORDER_CUSTOM: { 'wizard': { component: ReturnOrderCustom }, 'prev': 'MISSING_PRODUCTS' },
    CLIENT_MUST_UPLOAD_PHOTO: { 'wizard': { component: ClientUploadPhoto }, 'prev': 'SELECT_TYPE', 'prevCash': 'CLIENT_PAY_ORDER' },
    CLIENT_MUST_RE_ORDER: { 'wizard': { component: ClientMustReOrder }, 'prev': 'SELECT_TYPE', 'prevCash': 'CLIENT_PAY_ORDER' },
    CLIENT_PAY_ORDER: { 'wizard': { component: ClientPayAllOrder }, 'prev': 'SELECT_TYPE' },
};

function WizardOrderClaim({ toggle, refreshPage, order }) {
    const [step, setStep] = useState(components.SELECT_TYPE);
    const [products, setProducts] = useState(order.allProductByPrv);
    const [missingProducts, setMissingProducts] = useState([]);
    const [payAllOrder, setPayAllOrder] = useState(null);
    const [hideModal, setHideModal] = useState(false);
    const [problem, setProblem] = useState({});
    const [customProductsToReturn, setCustomProductsToReturn] = useState([]);
    const [brokenOrExpired, setBrokenOrExpired] = useState('roto')
    const [images, setGlobalImages] = useState([]);
    const [response, setGlobalResponse] = useState([]);
    const [refundType, setRefundType] = useState('picho-pesos');

    let CurrentComponent = step?.wizard ? step.wizard : step;
    let hidePetshop = problem.type == PROBLEM_TYPE.cliente_producto_confundido ? true : false
    const handleClose = (item, visibility) => {

        console.log('OrderSolutionDelivered? =>', step?.name);

        if (step?.name  == "OrderSolutionDelivered") {
            toggle(item, visibility)
        } else {
            confirm({
                title: 'Realmente quiere cerrar el proceso de reclamo?',
                content: 'Se perderán todos los datos cargados hasta el momento.',
                cancelText: 'Volver',
                okText: 'Si',
                onOk: () => toggle(item, visibility)
            });
        }
    }
    const handleBackStep = () => {
        if ((problem.type != PROBLEM_TYPE.petshop_producto_confundido && problem.type != PROBLEM_TYPE.petshop_producto_mal_estado && problem.type != PROBLEM_TYPE.cliente_producto_confundido) && step?.prevCash && order.compra_medio_pago == 'efectivo') {
            setStep(components[step.prevCash])
        } else if (!step?.prevPetshop && step?.prevCash && order.compra_medio_pago == 'efectivo') {
            setStep(components[step.prevCash])
        } else if ((problem.type == PROBLEM_TYPE.petshop_producto_confundido) && step?.prevPetshop) {
            setStep(components[step.prevPetshop])
        } else if (problem.type == PROBLEM_TYPE.cliente_producto_confundido && step?.prevClient) {
            setStep(components[step.prevClient])
        } else {
            setStep(components[step.prev])
        }
    }
    return (

        <Modal
            title={problem.label !== undefined ? `Problema actual - ${problem.label}` : "Reclamo de un cliente"}
            className='p-0'
            style={{ minWidth: '40vw' }}
            visible={!hideModal}
            onCancel={() => handleClose('showWizard', false)}
            footer={null}
        >
            <CurrentComponent.component setStep={setStep} products={products} setProducts={setProducts} setCustomProductsToReturn={setCustomProductsToReturn} toggle={toggle}
                customProductsToReturn={customProductsToReturn}
                setGlobalProblem={setProblem}
                hidePetshop={hidePetshop}
                problem={problem}
                PROBLEM_TYPE={PROBLEM_TYPE}
                handleBackStep={handleBackStep}
                setHideModal={setHideModal}
                brokenOrExpired={brokenOrExpired}
                setBrokenOrExpired={setBrokenOrExpired}
                images={images}
                setGlobalResponse={setGlobalResponse}
                setRefundType={setRefundType}
                refundType={refundType}
                response={response}
                setGlobalImages={setGlobalImages}
                missingProducts={missingProducts}
                payAllOrder={payAllOrder}
                setPayAllOrder={setPayAllOrder}
                refreshPage={refreshPage} setGlobalMissingProducts={setMissingProducts} orderId={order.compra_id} order={order} />
        </Modal >
    )
}

function PageTitle({ title }) { return <h4>{title}</h4> }

function ReturnOrderCustom({ order, setCustomProductsToReturn, customProductsToReturn, setStep, handleBackStep }) {
    const [productsToReturn, setProducts] = useState([]);

    useEffect(() => {
        if (productsToReturn.length == 0 && customProductsToReturn.length > 0) {
            setProducts(customProductsToReturn)
        } else {
            setProducts([{}])
        }
    }, []);
    const settingProductsArray = (operation, key) => {
        const aux = [...productsToReturn];
        if (operation == 'add') {
            aux.push({ product_name: '' });
            setProducts(aux);
        } else if (operation == 'rest') {
            aux.splice(key, 1);
            setProducts(aux);
        }
    }

    const saveReturnOrder = () => {
        let miss_name = productsToReturn.filter(item => !item?.product_name || item.product_name == '')
        if (miss_name.length == 0) {
            setCustomProductsToReturn(productsToReturn);
            setStep(components.SOLUTION);
        } else {
            Modal.error({
                title: 'Importante!',
                content: 'Nombre del producto es requerido.'
            });
        }
    }

    const changeProductName = (key, value) => {
        const aux = [...productsToReturn];
        aux[key].product_name = value;
        setProducts(aux);
    }


    return (
        <div>
            <div className='d-flex justify-content-center'>
                <PageTitle title='Qué productos debe retirar el petshop' />
            </div>
            <br></br>
            {productsToReturn.map((product, k) => {
                return (
                    <Row className='d-flex justify-content-center'>
                        <Col className='my-1'>
                            <span>Producto #{k + 1}</span>
                            <Row>
                                <Col md={10}>
                                    <Input value={product.product_name} onChange={(e) => changeProductName(k, e.target.value)} type={"text"} />
                                </Col>
                                <Col md={2}>
                                    {k > 0 ? <Button type={'danger'} onClick={() => settingProductsArray('rest', k)} className='d-flex align-items-center'><FaTrash size={14} className='mr-1' /> Eliminar</Button> : null}
                                </Col>
                            </Row>
                        </Col>
                        {/* <Col md={3} className='my-2'>
                        </Col> */}
                    </Row>
                )
            })}
            <Row>
                <Col md={3} className='my-2'>
                    <Button type={'outline-primary'} onClick={() => settingProductsArray('add')} className='d-flex align-items-center'>
                        <AiOutlinePlus size={20} />
                        Agregar producto
                    </Button>
                </Col>
            </Row>

            <Row>
                <Col className='d-flex justify-content-start'>
                    <Button type={'primary'}
                        onClick={() => handleBackStep()}
                    >
                        <MdKeyboardArrowLeft size={20} />
                        Volver
                    </Button>
                </Col>
                <Col className='d-flex justify-content-end'>
                    <Button type={'primary'} onClick={() => saveReturnOrder()}>
                        Siguiente
                        <MdKeyboardArrowRight size={20} />
                    </Button>
                </Col>
            </Row>
        </div>
    );
}


function OrderIncompleteType({ setStep, setGlobalProblem, order }) {

    const [problem, setProblem] = useState({});

    const PROBLEM_OPTIONS = [
        { value: 'pedido incompleto', label: 'El pedido llegó incompleto', next_step: components.RECEIPT, type: PROBLEM_TYPE.petshop_pedido_incompleto },
        { value: 'petshop pedido confundido', label: 'El petshop se confundió de productos', next_step: components.CLIENT_MUST_UPLOAD_PHOTO, type: PROBLEM_TYPE.petshop_producto_confundido },
        { value: 'cliente pedido confundido', label: 'El cliente quiere un cambio o una devolucion (culpa cliente)', next_step: components.CLIENT_MUST_RE_ORDER, type: PROBLEM_TYPE.cliente_producto_confundido },
        { value: 'pedido vencido', label: 'Hay productos vencidos', next_step: components.CLIENT_MUST_UPLOAD_PHOTO, type: PROBLEM_TYPE.petshop_producto_vencido },
        { value: 'pedido roto', label: 'Hay productos rotos', next_step: components.CLIENT_MUST_UPLOAD_PHOTO, type: PROBLEM_TYPE.petshop_producto_roto },
        { value: 'Producto en mal estado ', label: 'Hay productos en mal estado ', next_step: components.CLIENT_MUST_UPLOAD_PHOTO, type: PROBLEM_TYPE.petshop_producto_mal_estado },
        { value: 'Pedido no llego', label: 'Pedido no llego y esta en estado entregado', next_step: components.RECEIPT, type: PROBLEM_TYPE.petshop_producto_no_llego },

    ];

    const OPTION_PAY_CASH = [components.RECEIPT, components.CLIENT_MUST_UPLOAD_PHOTO, components.CLIENT_MUST_RE_ORDER, components.CLIENT_MUST_UPLOAD_PHOTO, components.CLIENT_MUST_UPLOAD_PHOTO]

    const onChange = (value) => {
        PROBLEM_OPTIONS.map(each => each.value == value && setProblem(each))
    }

    const onSearch = (value) => {
        console.log('search:', value);
    };

    const changeStep = () => {
        setGlobalProblem(problem);
        if (order.compra_medio_pago == 'efectivo' && OPTION_PAY_CASH.includes(problem.next_step)) {
            setStep(components.CLIENT_PAY_ORDER);
        } else {
            setStep(problem.next_step);
        }
    }
    return (
        <div>
            <Row className='d-flex justify-content-center'>
                <Col md={8} >
                    <div className='d-flex justify-content-center'>
                        <PageTitle title='Seleccioná el motivo del reclamo' />
                    </div>
                    <Select
                        className='mt-3'
                        showSearch
                        placeholder="Seleccionar un motivo de reclamo"
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        style={{ minWidth: '100%', marginBottom: '10px' }}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                    >
                        {PROBLEM_OPTIONS.map(each => <Option value={each.value}>{each.label}</Option>)}
                    </Select>
                    {problem.next_step != undefined ?
                        <Row>
                            <Col md={8} />

                            <Col md={4} className='d-flex justify-content-end'>
                                <Button type={'primary'}
                                    onClick={() => changeStep()}
                                >
                                    Siguiente
                                    <MdKeyboardArrowRight size={20} />
                                </Button>
                            </Col>

                        </Row>
                        : null}

                </Col>
            </Row>
        </div>
    )
}

function ClientPayAllOrder({ setStep, setPayAllOrder, payAllOrder, problem, handleBackStep, PROBLEM_TYPE }) {
    const [cantEdit, setCantEdit] = useState(false)
    useEffect(async () => {
        if (problem.type == PROBLEM_TYPE.petshop_producto_no_llego) {
            setCantEdit(true)
            setPayAllOrder(0)
        } else {
            setCantEdit(false)
        }
    }, [problem]);
    const changeStep = () => {

        if (!payAllOrder) {
            if (problem.next_step == components.CLIENT_MUST_RE_ORDER) {
                Modal.warning({
                    title: 'Importante!',
                    content: 'Sugerir al cliente que vuelva a hacer una compra con el producto que originalmente queria comprar.'
                });
            } else if (problem.next_step != components.CLIENT_MUST_RE_ORDER && problem.next_step != components.RECEIPT) {
                Modal.warning({
                    title: 'Importante!',
                    content: 'Cargar reclamo como pedido incompleto, pues el cliente no recibio una parte del pedido y tampoco la abonó.'
                });
            } else {
                setStep(problem.next_step);
            }
        } else {
            setStep(problem.next_step);
        }
    }
    return (
        <div>
            <Row className='d-flex justify-content-center'>
                <Col md={9}>
                    <div className='d-flex justify-content-center'>
                        <PageTitle title='¿El cliente abono todo el pedido?' />
                    </div>
                    {
                        problem.type == PROBLEM_TYPE.petshop_producto_no_llego &&
                        <Row>
                            <Col >
                                <InfoMessage text={'Como el pedido no llegó, el cliente no pudo abonar en efectivo'} />
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Select
                            className='mt-3'
                            placeholder="Seleccionar"
                            optionFilterProp="children"
                            onChange={(ev) => setPayAllOrder(ev)}
                            value={payAllOrder}
                            disabled={cantEdit}
                            style={{ minWidth: '100%', marginBottom: '10px' }}
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        >
                            <Option value={1} >Si</Option>
                            <Option value={0} >No</Option>
                        </Select>
                    </Row>
                    {payAllOrder !== null &&
                        <Row>
                            <Col className='d-flex justify-content-start'>
                                <Button type={'primary'}
                                    onClick={() => handleBackStep()}
                                >
                                    <MdKeyboardArrowLeft size={20} />
                                    Volver
                                </Button>
                            </Col>

                            <Col className='d-flex justify-content-end'>
                                <Button type={'primary'}
                                    onClick={() => {
                                        changeStep();
                                    }}
                                >
                                    Siguiente
                                    <MdKeyboardArrowRight size={20} />
                                </Button>
                            </Col>
                        </Row>
                    }
                </Col>
            </Row>
        </div>
    )
}

function OrderIncompleteReceipt({ orderId, setStep, images, handleBackStep }) {
    const [digitals, setDigitals] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        Mispichoshelper.getOrderDeliveriedDigitals({ order_id: orderId }).then(response => {
            setDigitals(response.payload);
            setLoading(false);
        });
    }, []);

    if (loading) {
        return <Loading />
    }


    return (
        <div>
            <div className='d-flex justify-content-center'>
                <PageTitle title='Verificación de comprobante de entrega' />
            </div>

            <hr></hr>
            <h6>Fotos subidas por el petshop</h6>
            {digitals.length ? digitals.map(digital =>
                <Row>
                    <Col>
                        <InnerImageZoom width={'300'} src={digital.url_digital} />
                    </Col>
                </Row>
            )
                : <span>No hay adjuntos por parte del petshop</span>}
            <hr></hr>
            <h6>Fotos enviadas por el cliente</h6>
            {images.map(image =>
                <Row>
                    <Col className='d-flex justify-content-center'>
                        <InnerImageZoom width={'300'} src={image.dataURL} />
                    </Col>
                </Row>
            )}
            <hr></hr>
            <Row className='justify-content-between'>
                <Col className='d-flex justify-content-center'>
                    {digitals.length
                        ? <Button type={'outline-success'} onClick={() => setStep(components.RECEIPT_SUCCESS)}>Petshop tiene razón</Button>
                        : <span>No existe comprobante de entrega</span>}
                </Col>
            </Row>
            <Row className='d-flex justify-content-end'>
                <Col className='d-flex justify-content-start'>
                    <Button type={'primary'}
                        onClick={() => handleBackStep()}
                    >
                        <MdKeyboardArrowLeft size={20} />
                        Volver
                    </Button>
                </Col>

                <Col className='d-flex justify-content-end'>
                    <Button type={'danger'} onClick={() => setStep(components.MISSING_PRODUCTS)}>
                        <FaUserCheck size={20} style={{ marginRight: 5 }} />
                        Cliente tiene razón
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

function OrderIncompleteMissingProducts({ order, products, setProducts, setGlobalMissingProducts, setStep, problem, handleBackStep, PROBLEM_TYPE }) {
    const [missingProducts, setMissingProducts] = useState([]);
    const [missingId, setMissingId] = useState([]);
    const [cantEdit, setCantEdit] = useState(false)
    useEffect(async () => {
        if (problem.type == PROBLEM_TYPE.petshop_producto_no_llego) {
            setCantEdit(true)
        } else {
            setCantEdit(false)
        }
    }, [problem]);
    useEffect(() => {
        if (missingProducts.length == 0 && missingId.length == 0) {
            products.map((each, i) => {
                if (problem.type == PROBLEM_TYPE.petshop_producto_no_llego) {
                    each.nueva_cantidad = each.cor_cantidad
                }
                if (each?.nueva_cantidad) {
                    missingId.push(each.prv_id)
                    missingProducts.push(each)
                }
            });
            setProducts([...products])
        }
    }, []);
    const setActiveVariation = (item, val) => {
        var auxProducts = missingProducts;
        if (val.target.checked) {
            auxProducts.push(item);
        } else {
            auxProducts.map((each, i) => {
                if (each.prv_id == item.prv_id) {
                    auxProducts.splice(i, 1);
                }
            });
        }
        const idProducts = auxProducts.map(item => item.prv_id);
        setMissingId(idProducts);
        setMissingProducts(auxProducts);
        setProducts([...products])
    }

    const changeCant = (item, cant) => {

        var auxProducts = missingProducts;

        let finalCant = cant;
        products.map((originalProduct, o) => {
            if (originalProduct.prv_id == item.prv_id && cant > originalProduct.cor_cantidad) {
                Modal.error({
                    title: 'Importante!',
                    content: 'No puede seleccionar mas productos de los comprados.'
                });
                finalCant = originalProduct.cor_cantidad;
                return;
            }
        });

        auxProducts.map((each, i) => {
            if (each.prv_id == item.prv_id) {
                item.nueva_cantidad = parseInt(finalCant);
                auxProducts.splice(i, 1);
                auxProducts.push(item);
            }
        });
        setMissingProducts(auxProducts);
        setProducts([...products])
        setMissingId([...missingId])
    }

    const moveToNextPage = () => {
        if (missingProducts.length == 0) {
            Modal.error({
                title: 'Importante!',
                content: 'debe seleccionar al menos un producto.'
            });
            return
        }
        setGlobalMissingProducts(missingProducts);
        if (problem.type == PROBLEM_TYPE.petshop_producto_confundido) {
            setStep(components.RETURN_ORDER_CUSTOM)
        } else {
            setStep(components.SOLUTION)
        }

    }

    let title = 'Selecciona los productos a completar';
    let subtitle = "Por favor selecciona solo los productos a completar"
    if (problem.type == PROBLEM_TYPE.cliente_producto_confundido || problem.type == PROBLEM_TYPE.petshop_producto_roto || problem.type == PROBLEM_TYPE.petshop_producto_vencido || problem.type == PROBLEM_TYPE.petshop_producto_mal_estado) {
        title = 'Seleccione los productos a retirar';
        subtitle = "Por favor selecciona solo los productos a retirar"
    }


    return (
        <div>
            <div>
                <div className='d-flex flex-column align-items-center'>
                    <PageTitle title={title} />
                    <h5>{subtitle}</h5>
                </div>
                {
                    problem.type == PROBLEM_TYPE.petshop_producto_no_llego &&
                    <Row>
                        <Col >
                            <InfoMessage text={'Sólo se puede indicar que el pedido no llegó si no llegó entero. Si llegó parcialmente, debería cargarse un reclamo de tipo Pedido Incompleto'} />
                        </Col>
                    </Row>
                }
                <hr />
                {products.map((item, index) =>
                    <Row className='align-items-center'>
                        <Col className='d-flex align-items-center'>
                            <input style={{ marginRight: 15 }} type={"checkbox"} checked={missingId.includes(item.prv_id) ? true : false} disabled={cantEdit} onChange={(val) => setActiveVariation(item, val)} />
                            <img src={item.prv_foto_link} height={'50'} alt='img' className='mr-3' />
                            <strong>{item.prv_producto_nombre}</strong>
                        </Col>
                        <Col className='d-flex align-items-center justify-content-end'>
                            <div className='mr-4'>
                                <span className='mr-2'>Pedido</span>
                                <input className="itemAumentaProducto" disabled={true} value={item.cor_cantidad} />
                            </div>
                            <div>
                                <span className='mr-2'>Faltan</span>
                                <Select
                                    disabled={missingId.includes(item.prv_id) && !cantEdit ? false : true}
                                    placeholder="Seleccionar una cantidad"
                                    onChange={(val) => changeCant(item, val)}
                                    style={{ minWidth: 120 }}
                                    value={item?.nueva_cantidad ? item.nueva_cantidad : ''}
                                >
                                    {[...Array(item.cor_cantidad).keys()].map(each => <Option value={each + 1} >{each + 1}</Option>)}
                                </Select>
                            </div>
                        </Col>
                    </Row>

                )}
            </div>
            <hr />
            <Row>
                <Col className='d-flex justify-content-start'>
                    <Button type={'primary'}
                        onClick={() => handleBackStep()}
                    >
                        <MdKeyboardArrowLeft size={20} />
                        Volver
                    </Button>
                </Col>
                <Col className='d-flex justify-content-end'>
                    <Button type={'primary'} onClick={() => { moveToNextPage() }}>
                        Siguiente
                        <MdKeyboardArrowRight size={20} />
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

function OrderIncompleteReceiptSuccess({ }) {
    return (
        <div>
            <PageTitle title='El cliente no puede reclamar' />
            <p>
                Dado que el cliente firmó correctamente el comprobante de entrega satisfactoria, y en el mismo están correctamente detallado todos los productos que debía recibir
                no podemos dar lugar al reclamo. El cliente dió consentimiento de que recibió todo correctamente. Para evaluar una excepción, hablar con el coordinador de felicidad
                al cliente.
            </p>
        </div>
    )
}

function OrderIncompleteSolutionCompleteOrder({ refreshPage, order, setHideModal, setGlobalResponse, refundType, toggle, setStep, missingProducts, problem, images, customProductsToReturn, payAllOrder, handleBackStep, hidePetshop }) {
    const [newSchedule, setNewSchedule] = useState(null);
    const [visibleModal, setVisibleModal] = useState(true);
    useEffect(() => {

        if (newSchedule && newSchedule.tim_id != undefined && newSchedule.fecha != undefined && (newSchedule.petshop)) {
            let params = newSchedule;
            params.products = missingProducts;
            params.order_id = order.compra_id;
            params.problem = problem.type;
            params.problemName = problem.value;
            params.refund_type = refundType;
            params.images = images;
            params.customProductsToReturn = customProductsToReturn
            params.payAllOrder = payAllOrder;
            setVisibleModal(false)
            Mispichoshelper.resolveWithCompleteTheOrder(params).then(response => {
                refreshPage(order.compra_id);
                setGlobalResponse(response.payload);
                setStep(components.SOLUTION_DELIVERIED);
                // setHideModal(false);
                // setVisibleModal(false);
            });
        }
    }, [newSchedule]);

    const hideReprogramar = (item, visibility) => {
        // setHideModal(true);
        toggle('reprogramarCompra', false);
        //toggle(item, visibility);
        setVisibleModal(false);
        handleBackStep()
        //toggle('showWizard', true);
    }

    // setHideModal(true);
    return (
        <div>
            <PageTitle title='El cliente solicitó completar el pedido' />

            <ChangeDeliveryTime refrescarCompra={refreshPage} showReprogramar={visibleModal} setNewSchedule={setNewSchedule} hideReprogramar={hideReprogramar} wizard={true} compra={order} hidePetshop={hidePetshop} />
            <Loading />
        </div>
    )
}

function OrderIncompleteSolutionPartialRefund({ missingProducts, setStep, setGlobalResponse, order, products, refreshPage, payAllOrder, handleBackStep, problem }) {
    const [loading, setLoading] = useState(false);

    const getTotalOrder = (data) => {
        let total = 0;
        data.map(item => {
            let cant = item.nueva_cantidad
            if (item?.array_renglones) {
                item.array_renglones.map(row => {
                    if (cant > 0) {
                        if (row.quantity > cant) {
                            total += (item.nueva_cantidad * row.price)
                            cant = 0
                        } else {
                            total += (row.quantity * row.price)
                            cant = cant - row.quantity
                        }
                    }
                })
            } else {
                total += (item.nueva_cantidad * item.cor_precio)
            }
        })

        return total
    }

    let costCard = getTotalOrder(missingProducts);
    let costPichoPesos = getTotalOrder(missingProducts);
    let totalOrderFivePercent = getTotalOrder(products) * 0.05;
    costPichoPesos = order.compra_medio_pago == 'efectivo' && !payAllOrder ? Math.round(order.compra_total_productos * 0.05) : Math.round(costPichoPesos + totalOrderFivePercent);

    const refundWithPichoPesos = () => {
        confirm({
            title: 'Confirma la devolución parcial en PichoPesos?',
            content: 'Se aplicará a la cuenta del cliente un crédito de $' + costPichoPesos + ' (una vez elegida esta opción, no se puede volver atrás)',
            cancelText: 'Volver',
            okText: 'Si',
            onOk: () => {
                setLoading(true)
                Mispichoshelper.resolveWithRefundPichoPesos({ order_id: order.compra_id, products: missingProducts, type: 'picho-pesos', payAllOrder: payAllOrder, problem: problem.type, problemName: problem.value }).then(response => {
                    setLoading(false)
                    if (response.status) {
                        setGlobalResponse(response.payload);
                        refreshPage(order.compra_id);
                        setStep(components.SOLUTION_DELIVERIED);
                    } else {
                        Modal.error({
                            title: 'Importante!',
                            content: response.message
                        });
                    }
                });

            }
        });

    }

    const refundWithCard = () => {

        confirm({
            title: 'Confirma la devolución parcial en tarjeta?',
            content: 'Se aplicará inmediatamente un reembolso de $' + costCard + ' en el medio de pago utilizado para la compra. El mismo se verá reflejado en el próximo resumen de la tarjeta del cliente (una vez elegida esta opción, no se puede volver atrás)',
            cancelText: 'Volver',
            okText: 'Si',
            onOk: () => {
                setLoading(true)
                Mispichoshelper.resolveWithRefundCard({ order_id: order.compra_id, products: missingProducts, type: 'card', payAllOrder: payAllOrder, problem: problem.type, problemName: problem.value }).then(response => {
                    setLoading(false)
                    if (response.payload.status) {
                        setGlobalResponse(response.payload);
                        setStep(components.SOLUTION_DELIVERIED);
                        refreshPage(order.compra_id);
                    } else {
                        Modal.error({
                            title: 'Importante!',
                            content: response.payload.message
                        });
                    }
                });

            }
        });
    }



    return (
        <div>
            <div className='d-flex flex-column align-items-center mb-3'>
                <PageTitle title='El cliente solicitó una devolución parcial' />
            </div>
            <Row>
                {order.opciones.puede_devolver_tarjeta &&
                    <Col style={{ borderRight: '1px solid #ccc' }} className='d-flex flex-column aling-items-center'>
                        <Button loading={loading} type={'default'} onClick={() => refundWithCard()} className='mb-2 success'>
                            Devolución en tarjeta $ {costCard}
                        </Button>
                        <p>
                            Con esta opción el cliente recibirá el monto de los productos faltantes en el medio de pago que utilizó para realizar la compra. El reembolso de realiza inmeditamente
                            pero el cliente lo podrá ver en su próximo resumen de tarjeta.
                        </p>
                    </Col>
                }

                <Col className='d-flex flex-column aling-items-center'>
                    {order.compra_medio_pago == 'efectivo' && !payAllOrder ?
                        <>
                            <Button type={'primary'} loading={loading} labelText={`Devolver en PichoPesos $${costPichoPesos}`} onClick={() => refundWithPichoPesos()} className='mb-2'>
                                Devolver en PichoPesos ${costPichoPesos}
                            </Button>
                            <p>
                                Con esta opción el cliente ha decidido no completar el pedido, como no lo abono no se le hará devolución sin embargo por la mala experiencia recibirá
                                una compensación de 5% para que pueda ser usada en su próxima compra, la acreditación es inmediata y puede ser usado en los próximos 5 minutos
                            </p>
                        </>
                        :
                        <>
                            <Button type={'primary'} loading={loading} labelText={`Devolver en PichoPesos $${costPichoPesos} (incluye 5%)`} onClick={() => refundWithPichoPesos()} className='mb-2'>
                                Devolver en PichoPesos ${costPichoPesos} (incluye 5%)
                            </Button>
                            <p>
                                Con esta opción el cliente recibirá el monto de los productos faltantes en pichos pesos para poder ser utilizados en una próxima compra.
                                Además recibirá un regalo de un 5% extra si elige esta opción.
                                La acreditación es inmediata y puede ser usado en los próximos 5 minutos.
                            </p>
                        </>
                    }

                </Col>
            </Row>
            <Row>
                <Col className='d-flex justify-content-start'>
                    <Button type={'primary'}
                        onClick={() => handleBackStep()}
                    >
                        <MdKeyboardArrowLeft size={20} />
                        Volver
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

function OrderIncompleteSolutions({ setStep, missingProducts, problem, setRefundType, handleBackStep, order }) {

    let costPichopesos = Math.round(order.compra_total * 0.05);
    let completeOrderMessage = `Con esta opción el cliente puede elegir el día y franja horaria para recibir todos los productos faltantes.
    Por este inconveniente, le acreditaremos en PichoPesos. ${'$' + costPichopesos} correspondiente a el 5% del total del pedido en su cuenta para utilizarlo en su próxima compra.`;
    let btnTitle = `Completar pedido`;
    if (problem.type == PROBLEM_TYPE.cliente_producto_confundido) {
        completeOrderMessage = `Con esta opción el cliente eligirá el día y la hora para que el petshop que entregó la compra pueda retirar los productos que el cliente se confundió. Recuerde que el dinero de los productos confundidos será devuelto
        una vez que se complete la "orden de devolución" por parte del petshop`;
        btnTitle = 'Crear orden de devolución'
    }
    if (problem.type == PROBLEM_TYPE.petshop_producto_roto_vencido) {
        completeOrderMessage = `Con esta opción el cliente eligirá el día y la hora para que el petshop que entregó la compra pueda retirar los productos en mal estado. Además en la misma fecha y hora se realizará la entrega de nuevos productos, para reemplazar aquellos en mal estado.`;
    }

    if (problem.type == PROBLEM_TYPE.petshop_producto_confundido) {
        completeOrderMessage = `Con esta opción el cliente eligirá el día y la hora para que el petshop que entregó la compra pueda retirar los productos confundidos. Además en la misma fecha y hora se realizará la entrega de los productos faltantes.`;
    }
    if (problem.type == PROBLEM_TYPE.petshop_producto_mal_estado) {
        completeOrderMessage = `Con esta opción el cliente eligirá el día y la hora para que el petshop que entregó la compra pueda retirar los productos en mal estado. Además en la misma fecha y hora se realizará la entrega de los productos faltantes.`;
    }

    if (problem.type == PROBLEM_TYPE.cliente_producto_confundido) {
        return (
            <div>
                <div className='d-flex flex-column align-items-center'>
                    <PageTitle title='Soluciones disponibles' />
                </div>
                <hr />

                <Row className="align-items-center">
                    <Col md={4}  >
                        <FormRadio
                            name="refund_type"
                            checked={true}
                            value='picho-pesos'
                            onChange={() => {
                                setRefundType('picho-pesos')
                            }}
                        >
                            Devolución PichoPesos
                        </FormRadio>
                    </Col>
                    <Col md={8}>
                        <p>Con esta opción el cliente recibirá el monto de los productos faltantes en pichos pesos
                            para poder ser utilizados en una próxima compra.
                            La acreditación es inmediatamente el pet confirme la recepción de los productos</p>
                    </Col>
                </Row>
                <hr />
                {
                    order.compra_medio_pago != 'efectivo' &&
                    <Row className="align-items-center">

                        <Col md={4} >
                            <FormRadio
                                name="refund_type"
                                value='card'
                                onChange={() => {
                                    setRefundType('card')
                                }}
                            >
                                Devolución Tarjeta
                            </FormRadio>
                        </Col>
                        <Col md={8} >
                            <p>El cliente recibirá el monto abonado del producto a devolver en el medio de pago que utilizó para realizar la compra.
                                El reembolso de realiza inmediatamente confirmemos la recepción pero el cliente lo podrá ver en su próximo resumen de tarjeta.</p>
                        </Col>
                    </Row>
                }
                {
                    order.compra_medio_pago == 'efectivo' &&
                    <Row className="align-items-center">

                        <Col md={4} >
                            <FormRadio
                                name="refund_type"
                                value='card'
                                onChange={() => {
                                    setRefundType('cash')
                                }}
                            >
                                Devolución Efectivo
                            </FormRadio>
                        </Col>
                        <Col md={8} >
                            <p>El cliente recibirá el monto abonado del producto a devolver en el medio de pago que utilizó para realizar la compra.
                                El reembolso de realiza inmediatamente confirmemos la recepción pero el cliente lo podrá ver en su próximo resumen de tarjeta.</p>
                        </Col>
                    </Row>
                }

                <Row className="mt-3">
                    <Col className='d-flex justify-content-start'>
                        <Button type={'primary'}
                            onClick={() => handleBackStep()}
                        >
                            <MdKeyboardArrowLeft size={20} />
                            Volver
                        </Button>
                    </Col>
                    <Col className='d-flex justify-content-end'>
                        <Button type={'default'} className="success" onClick={() => setStep(components.SOLUTION_COMPLETE_ORDER)}>{btnTitle}</Button>

                    </Col>
                </Row>

            </div>
        )
    }


    return (
        <div>
            <div className='d-flex flex-column align-items-center'>
                <PageTitle title='Soluciones disponibles' />
                <h5>Seleccionaste {missingProducts.length} {(missingProducts.length > 1) ? "productos faltantes" : "producto faltante"} </h5>
            </div>
            <Row>
                <Col md={5} className='d-flex justify-content-center  my-5'>
                    <Button type={'default'} className="success" onClick={() => setStep(components.SOLUTION_COMPLETE_ORDER)}>{btnTitle}</Button>

                </Col>
                <Col md={7}>
                    <InfoMessage text={completeOrderMessage} />
                </Col>
            </Row>

            {(problem.type == PROBLEM_TYPE.petshop_pedido_incompleto || problem.type == PROBLEM_TYPE.petshop_producto_no_llego) &&
                <Row>

                    <Col md={5} className='d-flex justify-content-center  my-5'>
                        <Button type={'default'} className="success" onClick={() => setStep(components.SOLUTION_PARTIAL_REFUND)}>Devolución parcial</Button>
                    </Col>
                    <Col md={7} >
                        <InfoMessage text='Con esta opción el cliente puede elegir un reembolso por el valor de los productos faltantes. Pudiendo elegir entre PichoPesos o en el medio de pago que utilizó para realizar la compra.' />

                    </Col>
                </Row>
            }

            <Row>
                <Col className='d-flex justify-content-start'>
                    <Button type={'primary'}
                        onClick={() => handleBackStep()}
                    >
                        <MdKeyboardArrowLeft size={20} />
                        Volver
                    </Button>
                </Col>
            </Row>

        </div>
    )
}

function OrderSolutionDeliveried({ response, setStep }) {

    useEffect(() => {
        setStep(components.SOLUTION_DELIVERIED)
    },[])

    return (
        <div>
            <div className='d-flex flex-column align-items-center'>
                <PageTitle title='Pedido solucionado!' />
            </div>
            <div>
                <ul className='p-4'>
                    {response.friendly_response != undefined && response.friendly_response.map(each => <li>{each}</li>)}
                </ul>

            </div>
        </div>
    )
}

function ClientMustReOrder({ setStep, handleBackStep }) {
    return (
        <div>
            <div className='d-flex flex-column align-items-center'>
                <PageTitle title='El cliente quiere un cambio o una devolucion (culpa cliente)' />
            </div>
            <InfoMessage
                text="Para solucionar este problema, el cliente debe realizar la compra de los productos en los que se confundió. Por otro lado, al finalizar este proceso se creará una orden de devolución para aquellos productos confundidos. El dinero al cliente
            será reembolsado en el momento que el petshop indique que retiró los productos."
            />
            <Row>
                <Col className='d-flex justify-content-start'>
                    <Button type={'primary'}
                        onClick={() => handleBackStep()}
                    >
                        <MdKeyboardArrowLeft size={20} />
                        Volver
                    </Button>
                </Col>
                <Col className='d-flex justify-content-end'>
                    <Button type={'primary'} onClick={() => setStep(components.MISSING_PRODUCTS)}>
                        Siguiente
                        <MdKeyboardArrowRight size={20} />
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

function ClientUploadPhoto({ setStep, setGlobalImages, handleBackStep, images, problem }) {
    const [image, setImages] = useState([...images]);
    const maxNumber = 69;

    const onChange = (imageList, addUpdateIndex) => {
        imageList.filter(item => {
            let reader = new FileReader();
            reader.readAsDataURL(item.file);

            reader.onloadend = async function () {
                var image = new Image();
                image.onload = function () {

                    var canvas = document.createElement('canvas'),
                        max_size = 800,
                        width = image.width,
                        height = image.height;

                    if (width > height) {
                        if (width > max_size) {
                            height *= max_size / width;
                            width = max_size;
                        }
                    } else {
                        if (height > max_size) {
                            width *= max_size / height;
                            height = max_size;
                        }
                    }

                    canvas.width = width;
                    canvas.height = height;
                    canvas.getContext('2d').drawImage(image, 0, 0, width, height);

                    var newImageData = canvas.toDataURL("image/jpeg", 80 / 100);
                    item.base64 = newImageData.split(',')[1]
                    item.dataURL = newImageData

                }
                image.src = reader.result;
            }
        })
        // imageList.map(i=>console.log('daleeee',imageList))
        setImages(imageList);
    };

    const saveImagesAndNextPage = () => {
        if (image.length == 0) {
            Modal.error({
                title: 'Importante!',
                content: 'Debe adjuntar un comprobante.'
            });
        } else {
            setGlobalImages(image);
            setStep(components.RECEIPT);
        }
    }
    const getTitle = () => {
        let title = 'El cliente recibió un producto vencido ó roto'
        if (problem.type == PROBLEM_TYPE.petshop_producto_confundido) {
            title = 'El cliente recibió un producto confundido';
        } else if (problem.type == PROBLEM_TYPE.petshop_producto_mal_estado) {
            title = 'El cliente recibió un producto en mal estado';
        }
        return title
    }
    const getInfo = () => {
        let title = "roto o vencido"
        if (problem.type == PROBLEM_TYPE.petshop_producto_confundido) {
            title = "confundido";
        } else if (problem.type == PROBLEM_TYPE.petshop_producto_mal_estado) {
            title = 'en mal estado';
        }
        return title
    }
    return (
        <div>
            <div className='d-flex justify-content-center'>
                <PageTitle title={getTitle()} />
            </div>
            <InfoMessage
                text={"Antes de continuar, es necesario que el cliente envíe las fotos que corroboren que el producto se encuentra " + getInfo() + ", con esa información se realizará una comprobación manual junto con los comprobantes de entrega adjuntos por el petshop. En caso de producto vencido verifica lote y fecha de vencimiento subida por el pet, en caso de que no haya adjuntos de fecha y lote el cliente tiene la razón"}
            />

            <ImageUploading
                multiple
                value={image}
                onChange={onChange}
                maxNumber={maxNumber}

            >
                {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                }) => (
                    <>
                        <div className="d-flex justify-content-center">
                            <Button
                                type={isDragging ? 'success' : 'primary'}
                                style={{ marginRight: 5 }}
                                onClick={onImageUpload}
                                {...dragProps}
                            >
                                <FaFileUpload size={14} style={{ marginRight: 5 }} />
                                Subir / arrastar imagen
                            </Button>

                            <Button onClick={onImageRemoveAll} type={'danger'} style={{ marginRight: 5 }}>
                                <FaTrash size={14} style={{ marginRight: 5 }} />
                                Eliminar imagen
                            </Button>
                        </div>
                        <Row className='m-2' style={{ border: '1px solid #eee' }}>
                            {imageList.map((item, index) => (
                                <Col md={3} className='m-2 ml-2'>
                                    <div key={index} className="d-flex justify-content-center mt-3 ">
                                        <img src={item['dataURL']} alt="" width="100" />
                                    </div>

                                    <div className="d-flex justify-content-center mt-3">
                                        <Tooltip title="Actualizar">
                                            <Button type={'warning'} onClick={() => onImageUpdate(index)} className='mx-2'>
                                                <GrUpdate size={14} />
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="Eliminar">
                                            <Button type={'danger'} onClick={() => onImageRemove(index)}>
                                                <FaTrash size={14} />
                                            </Button>
                                        </Tooltip>
                                    </div>
                                </Col>
                            ))}
                        </Row>

                        <Row>
                            <Col className='d-flex justify-content-start'>
                                <Button type={'primary'}
                                    onClick={() => handleBackStep()}
                                >
                                    <MdKeyboardArrowLeft size={20} />
                                    Volver
                                </Button>
                            </Col>
                            <Col className='d-flex justify-content-end'>
                                <Button type={'primary'} labelText='Ver comprobantes de entrega' onClick={() => { saveImagesAndNextPage() }}>
                                    Siguiente
                                    <MdKeyboardArrowRight size={20} />
                                </Button>
                            </Col>
                        </Row>
                    </>
                    // write your building UI
                )}
            </ImageUploading>

        </div>
    )
}

export default WizardOrderClaim;
