import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Mispichoshelper from '../../../utils/Mispichoshelper';

export default function Completeorder({ showCompleteOrder, compra, refrescarCompra, close }) {
    const [obs, setObs] = useState('');
    const [loading, setLoading] = useState(false);

    const sendObs = () => {
        if (obs !== '') {

            if (!loading) {

                setLoading(true)
                Mispichoshelper.postCompraCompletada({ compra_id: compra.compra_id, observaciones: obs }).then((response) => {
                    setLoading(false)
                    Modal.success({
                        title: response.payload.title,
                        content: response.payload.message,
                        okText: 'Entendido',
                        onOk: () => window.location.reload()
                    })
                    setLoading(false)
                })

            } else {
                Modal.info({
                    title: "Importante",
                    content: "Estamos completando el pedido aguarde unos segundos...",
                    okText: 'Espero paciente!',
                })
            }
        } else {
            Modal.error({
                title: 'Importante!',
                content: 'Debes incluir una observacion antes de marcar como entregado',
            });
        }
    }


    return (
        <Modal
            title="Marcar como completada esta compra"
            visible={showCompleteOrder}
            footer={[
                <Button key="back" onClick={() => close('showCompleteOrder', false)}>
                    Volver
                </Button>,
                <Button key="submit" type="primary" onClick={() => sendObs()}>
                    Si, completar compra
                </Button>,
            ]}
        >
            <TextArea
                className='campoTextarea'
                placeholder="Detalles del inconveniente"
                autosize={{ minRows: 6, maxRows: 6 }}
                value={obs}
                onChange={(evt) => setObs(evt.target.value)}
            />
        </Modal>
    )
}
