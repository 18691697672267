import React from "react";
import { Popover } from 'antd';
import { AiOutlineCalendar } from "react-icons/ai";
import { AiOutlineFileSync } from "react-icons/ai";
import { AiOutlineApartment } from "react-icons/ai";
import { AiOutlineDollar } from "react-icons/ai";
import { AiOutlineCreditCard } from "react-icons/ai";
import { AiOutlineForward } from "react-icons/ai";
import { MdAttachMoney } from "react-icons/md";
import { MdRedo } from "react-icons/md";
import { MdUndo } from "react-icons/md";


import Tag from '../../../components/componentsUI/TagList';

//const { TextArea } = Input;

function StickerReprogramado({ color }) {
    return <AiOutlineCalendar className={color} />
}

function StickerEditado({ color }) {
    const content = <div style={{ maxWidth: "15rem" }}>Se originaron cambios en el pedido</div>;
    return <Popover placement="top" content={content}> <AiOutlineFileSync className={color} /> </Popover>

}

function StickerSplit({ cantidad }) {
    const content = <div style={{ maxWidth: "15rem" }}>{"Esta compra se subdividio y requiere mayor atencion, son " + cantidad + " contando al original"}</div>;
    return <Popover placement="top" content={content}> <AiOutlineApartment /> </Popover>

}

function StickerEfectivo({ monto }) {
    const content = <div style={{ maxWidth: "15rem" }}>{"El monto original fue de " + monto + " Pesos."}</div>;
    return <Popover placement="top" content={content}> <AiOutlineDollar /> </Popover>
}

function StickerTC({ monto }) {
    const content = <div style={{ maxWidth: "15rem" }}>{"El monto original fue de " + monto + " Pesos."}</div>;
    return <Popover placement="top" content={content}> <AiOutlineCreditCard /> </Popover>
}


function StickerCapacidad() { return <Tag type='success' labelText={'Capacidad'} /> }


function Sticker({ backColor, nombre }) { return <Tag className={backColor} type='primary' labelText={nombre} /> }


function StickerRendimiento() { return <Tag type='success' labelText={'Rendimiento'} /> }

function StickerReembolso({ monto }) {
    const content = <div style={{ maxWidth: "15rem" }}>{"Se han reembosado al cliente $" + monto + " Pesos."}</div>;
    return (
        <Popover placement="top" content={content}>
            <div className="ReembolsoIcon">
                <MdAttachMoney />
                <MdRedo className='aux' />
            </div>
        </Popover>
    )
}

function StickerSolicitaDinero({ monto }) {
    const content = <div style={{ maxWidth: "15rem" }}>{"Por edicion del pedido, se le solicitó al comprador que abone $" + monto + " Pesos"}</div>;
    return (
        <Popover placement="top" content={content}>
            <div className="SolicitaDineroIcon">
                <MdAttachMoney />
                <MdUndo className='aux' />
            </div>
        </Popover>
    )
}

function StickerAutodel() {
    const content = <div style={{ maxWidth: "10rem" }}>{"Este pedido se autodelegará en 99h:99m:99s"}</div>;
    return (
        <Popover placement="top" content={content}>
            <AiOutlineForward />
        </Popover>
    )
}



export { StickerSplit, StickerReprogramado, StickerEditado, StickerEfectivo, StickerTC, StickerCapacidad, StickerRendimiento, StickerReembolso, StickerSolicitaDinero, StickerAutodel, Sticker };