import React, { useState, useEffect } from "react";
import { DatePicker, ConfigProvider, Table, Modal, Drawer, Input, Tag, Row, Col, Select, Popover } from 'antd';
import Mispichoshelper from '../utils/Mispichoshelper';
import moment from 'moment';
import { AiOutlineAudit, AiOutlineCreditCard, AiOutlineExclamation, AiOutlineFieldTime, AiOutlineIdcard, AiOutlineLoading3Quarters, AiOutlineMail, AiOutlineNumber, AiOutlineShop, AiOutlineUser } from "react-icons/ai";

// Excel
import { Download } from "../components/dashboard-elements/TablaPedidos";

// DatePicker
import es_ES from 'antd/es/locale-provider/es_ES';
import Loadin from "../components/componentsUI/Loading";
import Button from "../components/componentsUI/Button";
import { DrawerHeatMap } from "./DrawerHeatMap";
import { RankingPorductTable } from "./RankingPorductTable";

const { RangePicker } = DatePicker;
const dateFormat = 'DD-MM-YYYY';
const Option = Select.Option;

var formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'ARS',
});

function MisPichosOrdersSuspicious({ ...props }) {

    const [loading, setLoading] = useState(true);
    //filtros
    const [filterFrom, setFilterFrom] = useState(moment().startOf('month')); // 1ro de cada mes
    const [filterTo, setFilterTo] = useState(moment());
    const [duplicatedSelected, setDuplicatedSelected] = useState(null);
    const [emailSelected, setEmailSelected] = useState(null);
    const [addressSelected, setAddressSelected] = useState(null);
    const [greaterThan, setGreaterThan] = useState(18000);
    const [orderAttempt, setOrderAttempt] = useState(1);
    const [viewDetail, setViewDetail] = useState(false);
    // Tabla
    const [data, setData] = useState(null);
    const [cant_total_rows, setCant_total_rows] = useState(0);
    const [cant_total_orders, setCant_total_orders] = useState(0);
    const [page, setPage] = useState(0);
    const [pagination, setPagination] = useState(50);
    const [heatsDoms, setHeatDoms] = useState([]);
    const [drawer, setDrawer] = useState(false);
    const [prods, setCantProds] = useState([]);
    const [showPords, setShowProds] = useState(false);
    const [orderPaymentData, setOrderPaymentData] = useState({});
    const [loadingOrder, setLoadingOrder] = useState(false);
    const [check, setCheck] = useState(false);
    const [position, setPosition] = useState('bottom');

    useEffect(() => {
        setCant_total_rows(0);
        setCant_total_orders(0);
        getData()
        // eslint-disable-next-line
    }, [pagination, viewDetail])


    const getData = async (page) => {

        // Loading
        setLoading(true);

        const auxPage = page ? page - 1 : 0

        let params = {
            from: filterFrom.format('YYYY-MM-DD'),
            to: filterTo.format('YYYY-MM-DD'),
            limit: pagination,
            page: auxPage,
            'greater_than': greaterThan,
            'order_attempts': orderAttempt,
            'duplicate_items': duplicatedSelected,
            'email_selected': emailSelected,
            'address_selected': addressSelected
        }

        await Mispichoshelper.getMisPichosSuspicious(params).then((response) => {
            if (response.status) {
                setData(response.payload.data);
                setCant_total_rows(response.payload.cant_total_rows)
            } else {
                alert("Hubo un problema al conectar con el servidor")
            }
            setLoading(false);
        });

    }

    const onChangeDate = (date, dateString) => {
        if (date[0] && date[1]) {
            setFilterFrom(date[0]);
            setFilterTo(date[1]);
        }
    }

    const getAllFiles = () => {
        if (!check) {
            setCheck(true);
            setPagination(3000);
            setPosition('top');
        } else {
            setCheck(false);
            setPagination(50);
            setPosition('bottom');
        }
    }

    const downloadToExcel = (cols, orders) => {
        return (
            <div>
                <label>
                    <input type="checkbox" checked={check} onChange={() => { getAllFiles() }} /> Descargar todos los resultados (hasta 3.000 por página)
                </label>
                <Download cols={cols} orders={orders} />
            </div>)
    }

    const setDrawerToShow = (value) => {
        if (loading) {
            Modal.error({
                title: 'Por favor se paciente',
                content: 'Para ejecutar este boton tienen que cargar los resultados',
            });
        } else {
            if (value === "prod" || showPords) {
                setShowProds(!showPords)
            } else {
                setDrawer(!drawer)
            }
        }
    }

    const goToOrder = (order) => {
        console.log(props);
        window.location.href = '/compras?compra_id=' + order.com_id;
        // this.props.history.push('/compras?compra_id=' + order.com_id);
    }

    const markAsSuspicius = (order) => {
        Modal.confirm({
            title: 'Importante!',
            content: 'Realmente quiere marcar la compra como Estafa? Esta acción evitará que entren nuevas compras de este usuario',
            onOk: () => processSuspicious(order)
        });

    }

    const processSuspicious = (order) => {
        Mispichoshelper.Accountability.markOrderAsSuspicius({ com_id: order.com_id }).then((response) => {
            if (response.status) {
                Modal.success({
                    content: 'Compra marcada como estafa',
                    onOk: () => getData()
                });
            } else {
                Modal.error({
                    title: 'Hubo un problema no se actualizaron los datos',
                    content: null
                });
            }

        })
    }

    const showPaidData = (order) => {
        setLoadingOrder(true);
        Mispichoshelper.Accountability.getClientInfoPayment({ com_id: order.com_id }).then(response => {
            if (response.status && response.payload.data !== undefined) {
                setOrderPaymentData(response.payload.data);
            } else {
                alert('no encontramos informacion del pago');
            }
            setLoadingOrder(false);
        });
    }

    const closePaymentInfo = () => {
        setOrderPaymentData({});
    }

    const getDetailedData = (order) => {
        if (duplicatedSelected == '1') {
            setEmailSelected(order.email)
        } else if (duplicatedSelected == '2') {
            setAddressSelected(order.calle_completa)
        }
        setDuplicatedSelected(null);
        setViewDetail(!viewDetail);
    }

    const columns = [
        {
            title: '#Pedido',
            dataIndex: 'com_id',
            key: 'com_id',
            defaultSortOrder: 'descend',
            fixed: 'left',
            width: 100,
            render: (row, record) => {
                return (
                    <div>
                        {record.cant == 1 ?
                            <>
                                <Row>
                                    <Col>
                                        <span style={{ dislay: 'block' }}>#{record.com_id}</span>
                                    </Col>
                                    <Col>
                                        {record.com_suspicius ?
                                            <Tag backColor="withe" color="#000">Estafa</Tag>
                                            :
                                            (record.com_potentially_suspicious ?
                                                <Popover content={<div><strong>Motivo: </strong>{record.potentially_suspicious_reason}</div>}>
                                                    <Tag backColor="withe" color="#000">Posible Estafa</Tag>
                                                </Popover>
                                                : null
                                            )
                                        }
                                    </Col>
                                </Row>
                            </>
                            :
                            <>
                                <Row>
                                    <Col>
                                        <span style={{ dislay: 'block' }}>Múltiples pedidos</span>
                                    </Col>
                                </Row>
                            </>}
                    </div>)
            }
        },
        {
            title: 'Fecha pedida',
            dataIndex: 'fecha_pedida',
            width: 120,
            key: 'fecha_pedida',
            render: (row, record) => {
                return (
                    <div>
                        {record.cant == 1 ?
                            <span>{record.fecha_pedida}</span> : '-'}
                    </div>)
            }
        },
        {
            title: 'Cliente',
            dataIndex: 'email',
            key: 'email',
            render: (row, record) => {
                return (
                    <div>
                        {(record.cant == 1 || (record.cant >= 2 && duplicatedSelected == 1)) ?
                            <span>{record.email}</span> : '-'}
                    </div>
                )
            }
        },
        {
            title: 'Domicilio',
            dataIndex: 'calle_completa',
            key: 'calle_completa',
            render: (row, record) => {
                return (
                    <div>
                        {(record.cant == 1 || (record.cant >= 2 && duplicatedSelected == 2)) ?
                            <span>{record.calle_completa}</span> : '-'}
                    </div>)
            }
        },
        {
            title: 'Barrio',
            dataIndex: 'dom_barrio',
            key: 'dom_barrio',
            render: (row, record) => {
                return (
                    <div>
                        {(record.cant == 1 || (record.cant >= 2 && duplicatedSelected == 2)) ?
                            <span>{record.dom_barrio}</span> : '-'}
                    </div>)
            }
        },
        {
            title: 'Ciudad',
            dataIndex: 'dom_ciudad',
            key: 'dom_ciudad',
            render: (row, record) => {
                return (
                    <div>
                        {(record.cant == 1 || (record.cant >= 2 && duplicatedSelected == 2)) ?
                            <span>{record.dom_ciudad}</span> : '-'}
                    </div>)
            }
        },
        {
            title: 'Repeticiones',
            dataIndex: 'cant',
            key: 'cant',
        },

        {
            title: 'Monto',
            dataIndex: 'total',
            key: 'total',
            defaultSortOrder: 'descend',
            render: (text, record, index) => {
                return (
                    <div>
                        {record.cant == 1 ? <span>{formatter.format(text)}</span> : '-'}
                    </div>
                )
            },
        },
        {
            title: 'Acciones',
            key: 'operation',
            render: (acciones, record) => {
                var usuId = record.usuario_id;
                return (
                    <div>
                        {record.cant == 1 ?
                            <>
                                <Button type="primary" onPress={() => goToOrder(record)} value={usuId} labelText="Ver Compra" />
                                <Button type="primary" onPress={() => markAsSuspicius(record)} labelText="Estafa" />
                                <Button type="primary" loading={loadingOrder} onPress={() => showPaidData(record)} labelText="Ver datos de pago" />
                            </>
                            :
                            <Button type="primary" loading={loadingOrder} onPress={() => getDetailedData(record)} labelText="Ver detalle" />
                        }
                    </div>)
            }
        }

    ];


    return (
        <div className='GlobalStructure MisPichosOrders'>

            {loading ?
                <Loadin />
                :
                <section className='Card'>
                    <div className="GridCuatro">
                        <Modal
                            title="Información del pago"
                            className='p-0'
                            style={{ minWidth: '60vw' }}
                            visible={orderPaymentData.id != undefined ? true : false}
                            onOk={closePaymentInfo}
                            onCancel={closePaymentInfo}
                        >
                            <ul style={{ paddingLeft: '20px', listStyle: 'none' }}>
                                <li><AiOutlineShop /> Operador: {orderPaymentData.type}</li>
                                <li><AiOutlineNumber /> Nro Operación: {orderPaymentData.id != undefined ? orderPaymentData.id : null}</li>
                                <li><AiOutlineMail /> Email Cliente: {orderPaymentData.email != undefined ? orderPaymentData.email : null}</li>
                                <li><AiOutlineUser /> Nombre Cliente: {orderPaymentData.payer_name != undefined ? orderPaymentData.payer_name : null}</li>
                                <li><AiOutlineUser /> Apellido Cliente: {orderPaymentData.payer_last_name ? orderPaymentData.payer_last_name : null}</li>
                                <li><AiOutlineIdcard /> DNI Tarjeta: {orderPaymentData.card_holder_identification ? orderPaymentData.card_holder_identification : null}</li>
                                <li><AiOutlineAudit /> Nombre En Tarjeta: {orderPaymentData.card_holder_name ? orderPaymentData.card_holder_name : null}</li>
                                <li><AiOutlineCreditCard /> Nro Tarjeta: {orderPaymentData.card_digits ? orderPaymentData.card_digits : null}</li>
                                <li><AiOutlineExclamation /> Estado del pago: {orderPaymentData.status ? orderPaymentData.status : null}</li>
                                <li><AiOutlineLoading3Quarters /> Progreso del estado: {orderPaymentData.status_progress ? orderPaymentData.status_progress : null}</li>
                                <li><AiOutlineFieldTime /> Última modificación: {orderPaymentData.date_last_update ? orderPaymentData.date_last_update : null}</li>
                            </ul>
                        </Modal>

                        <div>
                            <div>
                                <h5>Periodo a Evaluar</h5>
                                <ConfigProvider locale={es_ES} >
                                    <RangePicker
                                        picker="month"
                                        onChange={(date, dateString) => onChangeDate(date, dateString)}
                                        format={dateFormat}
                                        defaultValue={[filterFrom, filterTo]}
                                    />
                                </ConfigProvider>
                            </div>
                        </div>
                        <div>
                            <h5>Total de compra mayores a</h5>
                            <Input value={greaterThan} onChange={(e) => { setGreaterThan(e.target.value) }} />
                        </div>
                        <div>
                            <h5>Intentos de compra mayores a</h5>
                            <Input value={orderAttempt} onChange={(e) => { setOrderAttempt(e.target.value) }} />
                        </div>
                    </div>
                    <div className="GridCuatro" style={{ marginTop: '15px' }}>
                        <div>
                            <h5>Agrupar repetidos</h5>
                            <Select
                                showSearch
                                filterOption={(input, option) =>
                                    option.props.children && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                value={duplicatedSelected}
                                style={{ width: '100%', alignItems: 'center' }}
                                placeholder="Selecciona una opción"
                                onChange={(evt) => setDuplicatedSelected(evt)}
                            >
                                <Option value='todos'>Sin email ni domicilio duplicado</Option>
                                <Option value='1'>Con email duplicado</Option>
                                <Option value='2'>Con domicilio duplicado</Option>
                            </Select>
                        </div>
                        <div>
                            <h5>Filtrar por email</h5>
                            <Input value={emailSelected} placeholder="Escribe un mail" onChange={(evt) => setEmailSelected(evt.target.value)} />
                        </div>
                        <div>
                            <h5>Filtrar por domicilio</h5>
                            <Input value={addressSelected} placeholder="Escribe un domicilio" onChange={(evt) => setAddressSelected(evt.target.value)} />
                        </div>
                        <div style={{ width: '100%', marginTop: "15px" }}>
                            <Button type="primary" style={{ marginTop: "24px", width: "100%" }} onPress={() => getData()} labelText='Filtrar' />
                        </div>
                    </div>

                    <hr />

                    <div className="TablaControlada" style={{ paddingBottom: '16px', paddingTop: '10px' }}>
                        <div className='RowCenter SpaceBetween'>
                            <span>{"Filas " + cant_total_rows}</span>
                            {downloadToExcel(columns, data)}
                        </div>
                        <Table
                            dataSource={data}
                            columns={columns}
                            pagination={{
                                position: position,
                                pageSize: pagination,
                                current: page,
                                total: cant_total_rows,
                                onChange: (page) => {
                                    setPage(page)
                                    getData(page)
                                }
                            }}
                        />
                    </div>
                </section>
            }


            <Drawer
                title={drawer ? "Mapa de calor" : "Tabla de productos"}
                width={500}
                onClose={() => setDrawerToShow()}
                visible={drawer ? drawer : showPords}
                style={{
                    overflow: 'auto',
                    height: '100vh',
                }}
            >

                {drawer ?
                    <DrawerHeatMap heat={heatsDoms} />
                    :
                    <RankingPorductTable products={prods} />
                }
            </Drawer>
        </div >
    )

};


export default MisPichosOrdersSuspicious;