import { Button } from 'antd'
import React from 'react'
import { Col, Row } from 'shards-react'
import './404.css'

// import bgImage from '../public/adorable-brown-white-basenji-dog-smiling-giving-high-five-isolated-white.jpg'


export const NotPage = () => {
    return (
        <div style={{
            backgroundImage: `url(/404-image.jpg?2)`,
            backgroundSize: 'cover'
        }}>
            <div style={{ height: '100vh' }}>
                <Row className='align-items-center' style={{ height: '100vh' }}>
                    <Col md={3}></Col>
                    <Col md={3}></Col>
                    <Col md={6} className='d-flex flex-column align-items-center'>
                        <h1 style={{ color: '#00bfff', fontWeight: 'bold', fontSize: '180px' }}>404</h1>
                        <div className='d-flex align-items-center'>
                            <div className='stop'>
                                <h2 style={{
                                    color: '#ffff',
                                    fontWeight: 'bold'
                                }}>STOP</h2>
                            </div>
                            <h2>No es por aquí!</h2>
                        </div>
                        <hr />
                        <div>
                            <a href="/default">
                                <Button type='primary'>
                                    Volver al Dashboard
                                </Button>
                            </a>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
