import React from 'react'
import { Col, Row } from 'shards-react'
import './PurchaseEditHistory.css'

export const PurchaseEditHistory = ({ purchaseEdit }) => {
   
    const dataProductsPrevious = purchaseEdit.previous.products
    const dataCombinedProductsPrevious = purchaseEdit.previous.combined_products
    const dataProductsNew = purchaseEdit.new.products
    const dataCombinedProductsNew = purchaseEdit.new.combined_products

    return (
        <Row>
            <Col className='border-cols'>
                <p className='title'>Previa</p>

                {dataProductsPrevious.map((product, index) => (
                    <Row key={index} className='align-items-center'>
                        <Col md={10} className='d-flex align-items-center'>
                            <div className='d-flex align-items-center my-2'>
                                <p className='mx-3 '>{product.cor_cantidad}</p>
                                <img src={product.prv_foto_link} alt="" width={30} className='mx-3' />
                                <p className='mx-3'>{product.prv_producto_nombre}</p>
                            </div>
                        </Col>
                        <Col md={2} className='d-flex justify-content-end'>
                            <p>${product.cor_precio}</p>
                        </Col>
                    </Row>
                ))}
                {dataCombinedProductsPrevious ?
                    dataCombinedProductsPrevious.map((combined_previos_product, index) => (
                        <Row key={index} className='align-items-center'>
                            <Col md={10} className='d-flex align-items-center'>
                                <div className='d-flex align-items-center my-2'>
                                    <p className='mx-3 '>{combined_previos_product.cor_cantidad}</p>
                                    <img src={combined_previos_product.prv_foto_link} alt="" width={30} className='mx-3' />
                                    <p className='mx-3'>{combined_previos_product.prv_producto_nombre}</p>
                                </div>
                            </Col>
                            <Col md={2} className='d-flex justify-content-end'>
                                <p>${combined_previos_product.cor_precio}</p>
                            </Col>
                        </Row>
                    ))
                    :
                    null
                }
            </Col>

            <Col className='border-cols'>
                <p className='title'>Nueva</p>

                {dataProductsNew.map((product, index) => (
                    <Row key={index} className='align-items-center'>
                        <Col md={10} className='d-flex align-items-center'>
                            <div className='d-flex align-items-center my-2'>
                                <p className='mx-3 '>{product.cor_cantidad}</p>
                                <img src={product.prv_foto_link} alt="" width={30} className='mx-3' />
                                <p className='mx-3'>{product.prv_producto_nombre}</p>
                            </div>
                        </Col>
                        <Col md={2} className='d-flex justify-content-end'>
                            <p>${product.cor_precio}</p>
                        </Col>
                    </Row>
                ))}
                {dataCombinedProductsNew ?
                    dataCombinedProductsNew.map((combined_new_product, index) => (
                        <Row key={index} className='align-items-center'>
                            <Col md={10} className='d-flex align-items-center'>
                                <div className='d-flex align-items-center my-2'>
                                    <p className='mx-3 '>{combined_new_product.cor_cantidad}</p>
                                    <img src={combined_new_product.prv_foto_link} alt="" width={30} className='mx-3' />
                                    <p className='mx-3'>{combined_new_product.prv_producto_nombre}</p>
                                </div>
                            </Col>
                            <Col md={2} className='d-flex justify-content-end'>
                                <p>${combined_new_product.cor_precio}</p>
                            </Col>
                        </Row>
                    ))
                    :
                    null
                }
            </Col>
        </Row>
    )
}
