import { DatePicker, Modal, Popover, Tag, Tooltip, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { AiTwotonePushpin } from 'react-icons/ai';
import { MovimientosPichoPesos, TelefonoPet, TelefonoTransporter, VerMovimientos, VerNotas, VerTickets } from "./Actions";
import {
	Sticker, StickerCapacidad, StickerEditado, StickerEfectivo, StickerReembolso,
	StickerRendimiento, StickerReprogramado, StickerSolicitaDinero, StickerSplit, StickerTC
} from "./Stickers";

import moment from 'moment';
import 'moment/locale/es';

import { Col, Row } from "shards-react";
import Button from "../../../components/componentsUI/Button";
import { Spinner } from "../../../components/componentsUI/Spinner";
import SocketPinnedOrders from "../../../socket/modules/PinnedOrders";
import OrdersPined from '../../../storage/OrdersPined';
import AuthService from "../../../utils/AuthService";
import Modals from "../Actions/Modals";

const Auth = new AuthService();

function InfoLeftTop({ compra, infoPaymentClient, domicilio, toggleInfoClientPayment, isLoading }) {

	const [showModalPinedOrder, setShowModalPinedOrder] = useState(false);
	const [orderPinedNotes, setOrderPinedNotes] = useState(null);
	const [datePinnedOrder, setDatePinnedOrder] = useState(new Date().toISOString());
	const [orders, setOrders] = useState({});
	const isMounted = useRef(true);

	const [showModals, setShowModals] = useState({
		showProblemOrder: false,
		splitCompra: false,
		intervencion: false,
		delegarCompra: false,
		reprogramarCompra: false,
		editarCompra: false,
		buscarProducto: false,
		reembolso: false,
		altaDomi: false,
		cambiaDomi: false,
		manualPaid: false,
		showFollowOrder: false,
		solucionarPedidosRojos: false,
		adjuntaModal: false,
		referModal: false,
		adjuntaLoading: false,
		showNotes: false,
		showClientDataPayment: false,
		showCompleteOrder: false,
		showToContactByCs: false,
		showWizard: false,
	})

	useEffect(() => {
		// Se ejecuta al montar el componente
		const handleOrdersUpdate = (updatedOrders) => {
			// Verificar si el componente está montado antes de actualizar el estado
			if (isMounted.current) {
				setOrders(updatedOrders);
			}
		};
		SocketPinnedOrders.all_pinned_orders_updated(handleOrdersUpdate);
		return () => {
			isMounted.current = false;
		};
	}, [])

	const pinnedOrderOK = () => {
		OrdersPined.addItem({
			order_id: compra.compra_id,
			message: orderPinedNotes,
			comments: [],
			date: datePinnedOrder
		})
		setShowModalPinedOrder(false)
	}

	const pinnedOrderInSocketOK = () => {

		const orderPined = orders.find(order => order.order_id === compra.compra_id)
		if (orderPined) {
			return message.error('Ya existe una orden fijada con este ID')
		}

		// Usuario
		const user_logged = Auth.getProfile();

		let order = {
			user: user_logged,
			order_id: compra.compra_id,
			message: orderPinedNotes,
			comments: [],
			// date: moment(datePinnedOrder).format('DD-MM-YYYY HH:mm'),
			date: datePinnedOrder,
			created_at: moment().format('DD-MM-YYYY HH:mm')
		};
		setShowModalPinedOrder(false)
		SocketPinnedOrders.create_pinned_order(order)
	}


	const toggleModal = (index, value) => {
		setShowModals({ ...showModals, [index]: value })
	}

	const onChange = (date, dateString) => {
		setDatePinnedOrder(date);
	}

	const currentDate = new Date();

	const pinnedOrderCancel = () => {
		setShowModalPinedOrder(false)
	}


	return (
		<article className={'compraResumenAlert Columna ' + compra.compra_color}>
			<div className="RowCenter SpaceBetween AlignStart">
				<div className='d-flex'>
					<h4>Pedido{' #' + compra.compra_id}</h4>
					<Tooltip title="fijar pedido">
						<AiTwotonePushpin style={{ marginLeft: '10px' }} onClick={() => setShowModalPinedOrder(true)} />
					</Tooltip>
				</div>
				<div className='RowCenter'>
					{process.env.REACT_APP_ZOHO_API_URI && <VerTickets data={compra} />}
					{compra.comments.length > 0 ? <VerNotas data={compra.comments} /> : null}
					<VerMovimientos compra={compra} />
				</div>
			</div>

			<div className="RowCenter stickersTagBox">
				{compra.tag_oportunidad && compra.compra_estado !== 'completado' && compra.se_estanca_en > 0 && <Sticker nombre={`Oportunidad (restan ${compra.se_estanca_en} min)`} backColor='enCamino' />}
				{compra.tag_capacidad > 0 ? <StickerCapacidad /> : null}
				{compra.tag_rendimiento > 0 ? <StickerRendimiento /> : null}
				{compra.tag_editada > 0 ? <Sticker backColor="entregado" nombre="Editada" /> : null}
				{compra.tag_split > 0 ? <Sticker backColor="split" nombre="Spliteada" /> : null}
				{compra.tag_auto_aceptada ? <Tag backColor="withe" color='orange'>Autoaceptada</Tag> : null}
				{compra.tag_solution_order > 0 ? <Tag backColor="withe" color='orange'>Es orden de solución</Tag> : null}
				{compra.tag_has_solution_order > 0 ? <Tag backColor="withe" color='orange'>Tiene orden de solución</Tag> : null}
				{compra.tag_order_not_arrive > 0 ? <Tag backColor="yellow" color='orange'>Pedido no entregado</Tag> : null}
				{compra.origen === 'web' ? <Tag backColor="white" color='green'>Web</Tag> : <Tag backColor="white" color='green'>App</Tag>}
				{compra.treggo_id ? <Tag backColor="withe" color='green'>Oliver envíos "{compra.treggo_id == 'treggo' || compra.treggo_id == 'colecta' ? 'colecta' : compra.treggo_id}"</Tag> : null}
				{compra.tag_suspicious ?
					<Tag backColor="withe" color="#000">Estafa</Tag>
					:
					(compra.tag_potentially_suspicious ?
						<Popover content={<div><strong>Motivo: </strong>{compra.potentially_suspicious_reason}</div>}>
							<Tag backColor="withe" color="#000">Posible Estafa</Tag>
						</Popover>
						:
						null
					)
				}
				{compra.compra_area_cobertura ? <Tag backColor="white" color='green'>{compra.compra_area_cobertura.name}</Tag> : null}
				{compra.tag_warehouse ? <Popover content={compra.tag_content_warehouse} title={`Entrega #${compra.com_warehouse_delivery_id}`}> <Tag color={compra.tag_warehouse_fail ? 'red' : 'blue'}>WH: {compra.tag_warehouse_status}</Tag></Popover> : null}

			</div>

			<div className="RowCenter SpaceBetween">
				<span>
					{compra.compra_petshop} {compra.liquidado ? `(liquidado a petshop: ${compra.liquidado})` : ''}
					<TelefonoPet petshop_email={compra.petshop_email} telefono={compra.compra_petshop_telefono} />
				</span>

				{compra.mov_pichoPesos.length > 0 ?
					<div>
						<span>
							<MovimientosPichoPesos compra={compra} />
						</span>
					</div>
					: null}

				<div className="">

					<div className=" RowCenter stickersBox">
						{compra.compras_split && compra.compras_split.length > 0 ? <StickerSplit cantidad={compra.compras_split.length} /> : null}
						{compra.tag_reprogramado ? <StickerReprogramado /> : null}
						{compra.compra_saldo_edicion != null ? <StickerEditado /> : null}
						{compra.compra_medio_pago === "efectivo" ?
							<StickerEfectivo monto={compra.compra_total_productos} />
							:
							!isLoading ?
								<div style={{ display: "flex" }} onClick={() => toggleInfoClientPayment()}>
									<StickerTC monto={compra.compra_total_productos - compra.compra_saldo_edicion} />
									{compra.compra_saldo_edicion > 0 ? <StickerSolicitaDinero monto={compra.compra_saldo_edicion} /> : null}
									{compra.compra_saldo_edicion < 0 ? <StickerReembolso monto={compra.compra_saldo_edicion} /> : null}
								</div>
								: <Spinner />
						}
					</div>

				</div>
			</div>
			{compra.transporter_phone ?
				<div className="RowCenter SpaceBetween">
					<span>
						{compra.transporter_phone.name_complete}
						<TelefonoTransporter name_transporter={compra.transporter_phone.name_complete} telefono={compra.transporter_phone.phone} />
					</span>
				</div>
				: null}

			{/* Modal Pined Order */}
			<Modal
				title="Confirmar fijar pedido"
				visible={showModalPinedOrder}
				footer={null}
				width={700}
			>
				<textarea className='campoTextarea'
					maxlength="90"
					style={{ width: '100%' }}
					value={orderPinedNotes}
					onChange={(evt) => setOrderPinedNotes(evt.target.value)}
					placeholder="Puedes dejar una nota"
				/>
				<p>Fecha de vencimiento</p>
				<DatePicker
					onChange={onChange}
					showTime
					placeholder="fecha de vencimiento"
					defaultValue={moment(currentDate)}
					format="DD-MM-YYYY HH:mm"
				/>
				<hr />
				<Row>

					<Col>
						<Button
							labelText={'Cancelar'}
							type={'outline-primary'}
							onPress={pinnedOrderCancel}
						/>
					</Col>
					<Col>
						<Button
							labelText={'Guardar orden'}
							type={'primary'}
							onPress={pinnedOrderOK}
						/>
					</Col>
					<Col>
						<Button
							labelText={'Guardar orden compartida'}
							type={'primary'}
							onPress={pinnedOrderInSocketOK}
						/>
					</Col>
				</Row>
			</Modal>

			<Modals
				showModals={showModals}
				compra={compra.com_id}
				domicilios={domicilio}
				problemMessage={'problemMessage'}
				infoPaymentClient={infoPaymentClient}
				functions={{
					toggleModal: toggleModal,
				}}
			/>

		</article>
	)
}

export default InfoLeftTop;
