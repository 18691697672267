import React, { useState } from "react";
import { Modal, Button } from 'antd';
import Mispichoshelper from "../../../utils/Mispichoshelper";


export default function Follow({ compra, hideFollowOrder, refrescarCompra, showFollowOrder }) {
    const [comments, setComents] = useState("")

    const sendData = () => {
        Mispichoshelper.postFollowOrder({ observaciones: comments, com_id: compra.compra_id }).then(informaResponse);
    }

    const cancelFollow = () => {
        Mispichoshelper.postFollowOrderCancel({ com_id: compra.compra_id }).then(informaResponse);
    }

    const informaResponse = (response) => {
        if (response && response.payload !== undefined) {
            if (response.payload.status) {
                success(response);
            } else {
                error(response);
            }
        } else {
            console.log('fallamos sin respuesta compra medio de pago');
        }
    }

    const success = (response) => {
        refrescarCompra(compra.compra_id);
        Modal.success({
            title: response.payload.title,
            content: response.payload.message,
            onOk: () => hideFollowOrder('showFollowOrder', false),
            okText: 'Entendido'
        });
    }

    const error = (response) => {
        Modal.error({
            title: response.payload.title,
            content: response.payload.message,
        });
    }

    return (
        <Modal
            title={(comments && `🗣 Las notas son visibles para todos los usuarios. ${comments ? '' : ''}`) || "🤔 ¿Qué sucede con este pedido?"}
            visible={showFollowOrder}
            onOk={sendData}
            onCancel={() => hideFollowOrder('showFollowOrder', false)}
            centered
        >
            <textarea className='campoTextarea' rows="5" style={{ width: '100%' }} onChange={(e) => setComents(e.target.value)} placeholder="¿Qué esta pasando?" />
            <br /><br />
            {compra.tag_seguimiento ? <Button onClick={() => cancelFollow()} >Cerrar seguimiento</Button> : null}

        </Modal>
    )

}



