import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Loadin from "../componentsUI/Loading";



function ReturnOrderBox({ compra }) {
    const [loading, setLoading] = useState(true);
    const [hasTypeRetire, setHasRetire] = useState(false);
    const [hasTypeReturn, setHasReturn] = useState(false);


    useEffect(() => {
        setHasRetire(compra.return_orders.map(returnOrder => returnOrder.products_custom ? true : false));
        setHasReturn(compra.return_orders.map(returnOrder => returnOrder.products.length ? true : false));
        setLoading(false);

    }, []);


    if (loading) {
        return <Loadin />
    }

    return (
        <>
            {hasTypeRetire[0] ? <TypeRetire order={compra} /> : null}
            {hasTypeReturn[0] ? <TypeReturn order={compra} /> : null}
        </>
    )
}

function TypeReturn({ order }) {
    return (
        <>
            <div style={{ marginBottom: 20 }}>
                <h2>
                    Ordenes de devolución asociadas
                </h2>
                <span>El dinero al cliente será reembolsado cuando el petshop confirma que recibió el producto en las mismas condiciones que fue entregado.</span>
            </div>
            <section className='splitBox'>

                {order.return_orders && order.return_orders.map((returnOrder, indexSplit) =>
                    <article key={indexSplit} className={'Sombra'} >
                        <div className={'blanco'}>
                            <h3>
                                {'#' + returnOrder.id + ' | ' + returnOrder.petshop_name}
                            </h3>
                            <p>
                                {"(" + returnOrder.status_message + ") " + returnOrder.askedDate + ' de ' + returnOrder.tim_start + "hs a " + returnOrder.tim_end + "hs"}
                            </p>
                        </div>



                        {returnOrder.products.map((product, indexDelIndexSplit) =>
                            <b key={indexDelIndexSplit} className='compraResumenListaProductos'>
                                <img src={product.foto} alt="Img Prod Split" />
                                <span>
                                    {product.nombre + " x" + product.ror_cant}
                                </span>
                            </b>


                        )}

                    </article>
                )}
            </section>
        </>
    );
}

function TypeRetire({ order }) {
    return (
        <section className='splitBox'>

            <h2>
                Ordenes de retiro asociadas
            </h2>
            {order.return_orders && order.return_orders.map((returnOrder, indexSplit) =>
                <article key={indexSplit} className={'Sombra'} >
                    <div className={'blanco'}>
                        <h3>
                            {'#' + returnOrder.id + ' | ' + returnOrder.type + ' | ' + returnOrder.petshop_name}
                        </h3>
                        <span>
                            {"(" + returnOrder.status_message + ") " + returnOrder.askedDate + ' de ' + returnOrder.tim_start + "hs a " + returnOrder.tim_end + "hs"}
                        </span>
                    </div>

                    <div className='px-4'>
                        <ul>
                            {returnOrder.products_custom && returnOrder.products_custom.map((product, indexDelIndexSplit) =>
                                <li>
                                    <span>
                                        {product.product_name}
                                    </span>
                                </li>
                            )}
                        </ul>
                    </div>
                </article>
            )}
        </section>
    );
}
export default withRouter(ReturnOrderBox);