import { io } from "socket.io-client";

var available = true;
var socket = null;

if (available) {

    socket = io(process.env.REACT_APP_API_SOCKET);
    socket.on("connect", () => {
        console.log('conectado al servidor de socket');
    });
}
else {

    socket = {
        emit: function (data) { return null },
        on: function (data, callback) { return null },
    }
}

export default socket;