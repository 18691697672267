import React, { useEffect, useState } from "react";
import { Modal } from 'antd';
import Mispichoshelper from "../../../utils/Mispichoshelper";

const confirm = Modal.confirm;

export default function Intervention({ compra, refrescarCompra, hideIntervencion, showIntervencion }) {
    const [mot_intervention, setMotInt] = useState(null)
    const [observaciones, setObservaciones] = useState(null)
    const [motivos, setMotivos] = useState([])

    useEffect(() => {
        Mispichoshelper.getMotivosCompraIntervenciones().then(response => setMotivos(response.payload));
    }, [])


    const guardaIntervencion = async () => {
        if (!mot_intervention) {
            Modal.error({
                title: 'Informacion!',
                content: 'Tenes que elegir una responsabilidad',
            });
            return
        }
        if (!observaciones) {
            Modal.error({
                title: 'Informacion!',
                content: 'El campo de observaciones es obligatorio',
            });
            return
        }
        await Mispichoshelper.postCompraIntervencion({ compra_id: compra.compra_id, 'motivo_intervencion': mot_intervention, 'observaciones': observaciones }).then((response) => {
            refrescarCompra(compra.compra_id);
            confirm({
                title: response.payload.message,
                content: 'Datos guardados satisfactoriamente',
                onOk: () => hideIntervencion('intervencion', false)
            });
        });
    }


    return (
        <Modal
            title="Intervenir Petshop"
            visible={showIntervencion}
            onOk={() => guardaIntervencion()}
            onCancel={() => hideIntervencion('intervencion', false)}
        >
            <select
                style={{ width: '100%', marginBottom: 10 }}
                onChange={(evt) => setMotInt(parseInt(evt.target.value))}
                placeholder="Elige un motivo"
                required
            >
                <option value=''>Seleccionar</option>
                {motivos.map((item, idx) => (
                    <option key={idx} value={item.id}>{item.nombre}</option>
                ))}
            </select>
            <textarea rows="4" maxLength="140" onChange={(evt) => setObservaciones(evt.target.value)} className="campoTextarea"></textarea>
        </Modal>
    )

}

