import socket from "../Connect";

const SocketPinnedOrders = {

  // EMIT
  create_pinned_order: (order) => { socket.emit("create_pinned_order", order) },
  delete_pinned_order: (order) => { socket.emit("delete_pinned_order", order) },
  update_pinned_order: (order) => { socket.emit("update_pinned_order", order) },
  search_all_pinned_orders: () => { socket.emit('search_all_pinned_orders') },
  create_comment_pinned_order: (data) => { socket.emit("create_comment_pinned_order", data) },
  // ON
  all_pinned_orders_updated: (callback) => { socket.on('all_pinned_orders_updated', (orders) => callback(orders)) },
  notification_pinned_orders: (callback) => { socket.on('notification_pinned_orders', (order) => callback(order)) },

};


export default SocketPinnedOrders;